const { connect } = require('react-redux');
const { context } = require('app-context');
const AdminDashboard = require('../components/AdminDashboard');

const selectors = context.selectors.all;
const actions = context.actions;

module.exports = connect(
    (state) => {
        const user = selectors.getCurrentUser(state).user();
        const schools = selectors.getSchools(state);
        return {
            schools: schools.map((school) => ({ id: school.id, name: school.name })),
            userStats: selectors.getUserStats(state),
            messagesStats: selectors.getMessagesStats(state),
            currentUser: user ? { email: user.email, schoolId: user.schoolId } : null
        };
    },
    (dispatch) => ({
        fetchUserStats: (filters) => {

            return dispatch(actions.dashboard.getUserStats(filters));
        },
        fetchMessagesStats: (filters) => dispatch(actions.dashboard.getMessagesStats(filters))
    })
)(AdminDashboard);
