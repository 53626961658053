const {
    getIsAuthenticated,
    currentFirebaseTokenKey,
    backgroundFirebaseTokenKey,
    updateFirebaseToken,
    isCordovaRunning,
    onDeviceReady,
    onPause,
    onResume,
    persistSet,
    persistGet,
    persistRemove
} = require('utils/cordova');

module.exports = (store) => {

    // polyfill for scroll and scrollTo functions.  Needed for Android < 7.1
    // put it before web browsers getting kicked out of here in case old browsers want it too
    if (!Element.prototype.scroll) {
        Element.prototype.scroll = Element.prototype.scrollTo = function (x, y) {

            this.scrollTop = y;
        };
    }

    if (!isCordovaRunning()) {
        // Bail
        return;
    }

    let isBound = false;
    let previousIsAuthenticated = false;

    // Handles cases where resuming the app should not reload and rerender the application
    // So far, the only case this handles is selecting an image from Android's built-in image gallery
    // on the edit profile and profile details pages. Opening that image dialog pauses the application.
    // Selecting an image then causes our onResume handler to rerender the application,
    // losing our selected image and any other non-persisted component state.
    // (on iOS, the native image selection dialog does not pause the application)

    // Will get picked up by the cordova
    window.handleOpenURL = function (url) {

        const path = url.split('://')[1];

        if (!window.location.pathname.includes(path)) {
            window.location = path;
        }
    };

    document.addEventListener('deviceready', (args) => {

        store.subscribe(() => {

            const isAuthenticated = getIsAuthenticated();

            if (!isBound && isAuthenticated) {
                isBound = true;

                const bgFirebaseKey = persistGet(backgroundFirebaseTokenKey);

                if (bgFirebaseKey) {
                    updateFirebaseToken(bgFirebaseKey);
                }
            }

            if (previousIsAuthenticated && !isAuthenticated) {
                const registrationId = persistGet(currentFirebaseTokenKey);
                persistRemove(currentFirebaseTokenKey);
                persistSet(backgroundFirebaseTokenKey, registrationId);
                persistRemove('twilioEndpoint');
                isBound = false;
            }

            previousIsAuthenticated = isAuthenticated;
        });

        onDeviceReady(args);
    });

    document.addEventListener('pause', onPause);
    document.addEventListener('resume', onResume);
};
