const { connect } = require('react-redux');
const { context } = require('app-context');
const BugReportPage = require('../components/BugReportPage');

const { push: Push } = require('connected-react-router');
const { safeWarning } = require('assertions-simplified');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        categories: selectors.getSortedCategories(state),
        currentUser: selectors.getCurrentUser(state).user() || null
    }), {
        onSubmitBugReport: ({ bugType, bugDetails, suggestedInterestCategory },redirectUrl) => (dispatch) => { // eslint-disable-line
            return dispatch(actions.appFeedback.submitBugReport({ bugType, bugDetails, suggestedInterestCategory })).then((success) => {

                if (!success) {
                    throw new Error('Support report failed');
                }

                dispatch(Push(redirectUrl));

                dispatch(actions.alerts.notification.push({
                    message: 'Thanks for your feedback – we\'re on it!'
                }));
            }).catch(safeWarning);
        },
        onClickCloseAccount: actions.auth.closeAccount,/*({ closeAccountReason }) => (dispatch) => { // eslint-disable-line
            return dispatch(actions.auth.closeAccount({ closeAccountReason }));.then((success) => {

                if (!success) {
                    throw new Error('Support report failed');
                }

                dispatch(Push('/'));

                dispatch(actions.alerts.notification.push({
                    message: 'Thanks for your feedback – we\'re on it!'
                }));
            }).catch(safeWarning);
        },*/
        onSubmitBugReportNoRedirect: ({ bugType, bugDetails, suggestedInterestCategory }) => (dispatch) => { // eslint-disable-line
            return dispatch(actions.appFeedback.submitBugReport({ bugType, bugDetails, suggestedInterestCategory })).then((success) => {

                if (!success) {
                    throw new Error('Support report failed');
                }



                dispatch(actions.alerts.notification.push({
                    message: 'Thanks for your feedback – we\'re on it!'
                }));
            }).catch(safeWarning);
        },
        onInvalidSubmit: () => actions.alerts.notification.push({ message: 'Please fix invalid fields' }),
        openAlertDialog: actions.app.openAlertDialog
    }
)(BugReportPage);
