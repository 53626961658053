const React = require('react');
const { connect } = require('react-redux');
const { context } = require('app-context');
const Entry = require('../components/Entry');

const selectors = context.selectors.all;
const actions   = context.actions;

const internals = {};

module.exports = connect(
    (state, { match: { params: { slug } } }) => ({
        schools: null,
        signupDetails: {
            ...selectors.getSignupDetailsForStep1(state),
            schoolId: selectors.getSchoolIdbySlug(state, slug)
        },
        showSchoolList: false,
        text: internals.text[slug] || null,
        secondaryText: internals.secondaryText[slug] || null,
        tertiaryText: internals.tertiaryText[slug] || null,
        isParentView: true,
        logo: require('../../../static/logos/' + internals.logo[slug]) || null,
        customGraphic: require('../../../static/colleges/' + internals.customGraphic[slug]) || null,
        customError: internals.customError[slug]
    }), {
        onSubmit: actions.signup.step1.complete,
        onInvalidSubmit: actions.signup.step1.failure
    }
)(Entry);

internals.text = {
    xula: 'Join Nearpeer to meet and connect with other XULA parents and families!'
};

internals.secondaryText = {
    xula: 'Sign up by using the email XULA has on file for you:'
};

internals.tertiaryText = {
    xula: (
        <div>
            <p>Want to learn more about how to use Nearpeer for XULA Parents & Families? Visit <a style={{ color: 'white' }} href='https://info.nearpeer.com/xula-family' target='_blank' rel='nofollow noreferrer'>https://info.nearpeer.com/xula-family</a> for more information.</p>
            <h1>We’re innovating to help Xavier students — and your family is important.</h1>
            <p>Together with XULA leadership, we’re building our first parent app version. This means we’re driving innovation with you and the XULA community, and we’ll continue to improve it with your feedback. You’ll create your profile via web browser, and then sign in to the Nearpeer app on your mobile device.</p>
            <p>Need help? Email us at <a style={{ color: 'white' }} href='mailto:xulaparents@nearpeer.net'>xulaparents@nearpeer.net</a></p>
        </div>
    )
};

internals.customError = {
    xula: (
        <p>Email not working? <a style={{ color: 'white' }} href='https://info.nearpeer.com/xula-family-registration?hsLang=en' target='_blank' rel='nofollow noreferrer'>Add your email to XULA system to join</a>!</p>
    )
};

internals.customGraphic = {
    xula: 'nearpeer-mobile-xula.png'
};

internals.logo = {
    xula: 'xula.png'
};
