// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___dGKP3{padding:0}.styles__title___TTkKb{display:inline-block;text-align:center;width:100%}.styles__joinButton___xTwqt{top:calc(50% - 18px)!important}.styles__joinButton___xTwqt:hover{opacity:.9}.styles__joinButton___xTwqt:hover div>div{background-color:initial!important;opacity:1}.styles__fullScreenDialogRoot___xvJwY{overflow-y:hidden}.styles__searchWrapper___Ik1If{height:72px;text-align:center}.styles__fixedSearch___sHlAv{border-radius:0!important;text-align:center;width:100%;z-index:2}.styles__fixedSearch___sHlAv.contentWrapper{display:block}.styles__center___z7o5G{text-align:center!important}.styles__subtitleClass___fyynu,.styles__titleClass___y45w_{width:75%}`, "",{"version":3,"sources":["webpack://./src/routes/app/profile/components/ManageGroupsDialog/styles.scss"],"names":[],"mappings":"AAAA,yBACE,SAAA,CAGF,uBACE,oBAAA,CAEA,iBAAA,CADA,UACA,CAGF,4BACE,8BAAA,CACA,kCACE,UAAA,CACA,0CACE,kCAAA,CACA,SAAA,CAKN,sCACE,iBAAA,CAGF,+BACE,WAAA,CACA,iBAAA,CAGF,6BAGE,yBAAA,CACA,iBAAA,CAHA,UAAA,CACA,SAEA,CAEA,4CACE,aAAA,CAIJ,wBACE,2BAAA,CAOF,2DACE,SAAA","sourcesContent":[".wrapper {\n  padding: 0;\n}\n\n.title {\n  display: inline-block;\n  width: 100%;\n  text-align: center;\n}\n\n.joinButton {\n  top: calc((100% - 36px) / 2) !important;\n  &:hover {\n    opacity: 0.9;\n    & div > div {\n      background-color: initial !important;\n      opacity: 1;\n    }\n  }\n}\n\n.fullScreenDialogRoot {\n  overflow-y: hidden;\n}\n\n.searchWrapper {\n  height: 72px; // TextField height\n  text-align: center;\n}\n\n.fixedSearch {\n  width: 100%;\n  z-index: 2;\n  border-radius: 0 !important;\n  text-align: center;\n\n  &:global(.contentWrapper) {\n    display: block;\n  }\n}\n\n.center {\n  text-align: center!important;\n}\n\n.titleClass {\n  width: 75%;\n}\n\n.subtitleClass {\n  width: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___dGKP3`,
	"title": `styles__title___TTkKb`,
	"joinButton": `styles__joinButton___xTwqt`,
	"fullScreenDialogRoot": `styles__fullScreenDialogRoot___xvJwY`,
	"searchWrapper": `styles__searchWrapper___Ik1If`,
	"fixedSearch": `styles__fixedSearch___sHlAv`,
	"center": `styles__center___z7o5G`,
	"subtitleClass": `styles__subtitleClass___fyynu`,
	"titleClass": `styles__titleClass___y45w_`
};
export default ___CSS_LOADER_EXPORT___;
