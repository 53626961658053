const UniqueId = require('lodash/uniqueId');
const SortBy = require('lodash/sortBy');
const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const { default: TextField } = require('@mui/material/TextField');
const { NavLink: Link } = require('react-router-dom');

const { default: SelectField } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: ListItemIcon } = require('@mui/material/ListItemIcon');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: MuiIconButton } = require('@mui/material/IconButton');
const { default: ClearIcon } = require('@mui/icons-material/HighlightOff');
const { default: CameraIcon } = require('@mui/icons-material/CameraAlt');

const FuzzyFilter = require('utils/fuzzyFilter');
const { default: FAB } = require('@mui/material/Fab');
const {
    USER_ROLE_IDS,
    INTERESTS,
    DEFAULT_PROFILE_IMG,
    EMPTY_PROFILE_IMG
} = require('utils/constants');

const { default: UploadIcon } = require('@mui/icons-material/Publish');
const { default: Crop } = require('@mui/icons-material/Crop');
const { context } = require('app-context');

const { default: Classes } = require('./styles.scss');
const RadioButtonGroup = require('components/RadioButtonGroup');
const RadioButton = require('components/RadioButton');
const InterestsInputField = require('containers/InterestsInputField');
const { default: Divider } = require('@mui/material/Divider');

const { default: Backdrop } = require('@mui/material/Backdrop');
const Moment = require('moment');
const CropAvatarDialog = require('components/CropAvatarDialog');
const { castBool } = require('utils/typecast');
const MaterialReactAutocomplete = require('components/material-react-autocomplete');
const UserPreferences = require('../../components/UserPreferences');
const HousingPicker = require('components/HousingPicker');
const QuestionSwitch = require('components/QuestionSwitch');
const GooglePlaceAutocomplete = require('components/GooglePlaceAutocomplete');
// NOTE this must stay a dumb component —
// there seem to be issues with redux Provider piping when
// we extend this class.
const UserBadge = require('components/UserBadge');
const Debounce = require('lodash/debounce');
const PrefsDomain = require('domain/preferences');
const { default: InputLabel } = require('@mui/material/InputLabel');

const FixMuiTextAreaAriaLabel = require('utils/fixMui4TextareaAutosizeAriaLabel');
const { getOriginalImageUrl } = require('utils/image');

const { matchSorter } = require('match-sorter');

const Fonts = require('styles/fonts.json');
const { default: Button } = require('@mui/material/Button');
const { runProfanityCheck } = require('utils/profanityFilter');
const Loader = require('../Loader');
const Map = require('lodash/map');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const Heic2any = require('heic2any');
const { default: BrowserImageCompression } = require('browser-image-compression');
const { getDevice, isDesktop } = require('../../utils/device');
const { Typography } = require('@mui/material');

const { createRef } = React;

const internals = {};
const MAX_BIO = 160;
const MAX_PRONOUNS_LENGTH = 30;
const MAX_ETHNICITY_LENGTH = 150;
const MAX_FIRST_AND_LAST_NAME_LENGTH = 30;

const MB_IN_BYTES = 1000000;
const SAVE_PROGRESS_DEBOUNCE = 2000;

const CameraButton = Styled.button`
  margin: 10px;
  padding: 8px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &.primary {
    border: none;
    background: ${(props) => props.theme.palette.primary.main};
    color: white;
  }

  &.secondary {
    border: 1px solid #ccc;
    background: white;
  }
`;

const StyledButton = Styled(Button)`
  && {
    background-color: #9B0034;
    margin-top: 1.5em;
    height: 2.5em;

    font-family: ${Fonts.headerFont}; // replace with your actual font
    font-weight: bold;
    text-transform: none;
    font-size: 1em;

  }
`;

const StyledBackdrop = Styled(Backdrop)`
  && {
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

module.exports = class UserInputs extends React.PureComponent {

    static propTypes = {
        defaultInterestId: T.number,
        showNotification: T.func,
        onSubmit: T.func.isRequired,
        onSubmitNoRedirect: T.func.isRequired,
        onInvalidSubmit: T.func.isRequired,
        interestsLoading: T.bool.isRequired,
        officesLoading: T.bool.isRequired,
        yearsHiredLoading: T.bool.isRequired,
        preferencesLoading: T.bool,
        saveProfileProgress: T.func,
        saveProfilePicture: T.func,
        savePreferencesProgress: T.func,
        badges: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            svg: T.string,
            icon: T.any,
            label: T.string,
            userPermissions: T.string
        })),
        transfers: T.array,
        offCampusHousingList: T.array,
        onCampusHousingList: T.array,
        majors: T.array,
        departments: T.arrayOf(
            T.shape({
                id: T.number,
                name: T.string
            })
        ),
        officesList: T.arrayOf(
            T.shape({
                id: T.number,
                name: T.string
            })
        ),
        yearsHiredList: T.arrayOf(
            T.shape({
                id: T.number,
                year: T.number
            })
        ),
        incomingClassList: T.array,
        graduatingClassList: T.array,
        signupDetails: T.shape({
            type: T.string,
            picFile: T.object,
            cropPosition: T.shape({ x: T.number, y: T.number }),
            cropRotation: T.number,
            cropScale: T.number,
            picPreviewURL: T.string,
            croppedPicture: T.string,
            firstName: T.string,
            lastName: T.string,
            birthdate: T.date,
            majorId: T.number,
            departmentId: T.number,
            officeId: T.number,
            yearHiredId: T.number,
            workRemote: T.bool,
            title: T.string,
            hometown: T.shape({
                id: T.number,
                placeId: T.string,
                name: T.string,
                onCampus: T.bool
            }),
            incomingClass: T.string,
            graduatingClass: T.string,
            career: T.string,
            profession: T.string,
            interests: T.array,
            passionInterests: T.array,
            bio: T.string,
            studentBio: T.string,
            studentName: T.string,
            housing: T.shape({
                id: T.number,
                placeId: T.string,
                name: T.string,
                onCampus: T.bool
            }),
            role: T.shape({
                id: T.number,
                name: T.string,
                label: T.string
            }),
            transfer: T.object,
            veteran: T.any, // Might be string or bool
            parent: T.bool,
            onlineStudent: T.bool,
            openToSocial: T.any, // Might be string or bool
            fullTime: T.any, // Might be string or bool
            gender: T.string,
            ethnicity: T.string,

            // Here for the linter, these do not get passed in
            genderText: T.string,
            ethnicityText: T.string,
            preferences: T.object
        }),
        isParent: T.bool,
        // isStudentRoleGroup: T.bool,
        rolePermissions: T.shape({
            id: T.number,
            roleId: T.number,
            schoolId: T.number,
            homeText: T.string,
            canEditAge: T.bool,
            canEditMajor: T.bool,
            canEditHomeTown: T.bool,
            canEditCareerAspiration: T.bool,
            canEditProfession: T.bool,
            canEditDepartment: T.bool,
            canEditBadge: T.bool,
            canEditTitle: T.bool,
            canEditIncomingClass: T.bool,
            canEditGraduatingClass: T.bool,
            canEditBio: T.bool,
            canEditLiveOnCampus: T.bool,
            canEditTransfer: T.bool,
            canEditVeteran: T.bool,
            canEditOnline: T.bool,
            canEditParent: T.bool,
            canEditOpenSocial: T.bool,
            canEditTimeStatus: T.bool,
            canEditStudentName: T.bool,
            canEditStudentBio: T.bool,
            canEditYearHired: T.bool,
            canEditOffice: T.bool,
            canEditWorkRemote: T.bool,
            includeEthnicity: T.bool,
            profilePicRequired: T.bool
        }),
        role: T.shape({
            id: T.number,
            name: T.string,
            label: T.string
        }),
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool,
        location: T.object,
        fieldsPlaceholders: T.shape({
            bio: T.string
        }),
        useProfanityFilter: T.bool
    };

    constructor(props) {

        super(props);
        this.id = UniqueId('user-inputs-');

        this.fields = [
            'picFile',
            'picPreviewURL',
            'cropPosition',
            'cropRotation',
            'cropScale',
            'firstName',
            'lastName',
            'birthdate',
            'hometown',
            'housing',
            'majorId',
            'departmentId',
            'officeId',
            'yearHiredId',
            'workRemote',
            'title',
            'incomingClass',
            'graduatingClass',
            'career',
            'profession',
            'type',
            'interests',
            'passionInterests',
            'bio',
            'transfer',
            'veteran',
            'parent',
            'onlineStudent',
            'openToSocial',
            'fullTime',
            'gender',
            'ethnicity',
            'studentName',
            'studentBio'
        ];

        this.handleKeyPress = this._handleKeyPress.bind(this);

        this.picFile = createRef();
        this.firstName = createRef();
        this.birthdate = createRef();
        this.lastName = createRef();
        this.interests = createRef();
        this.incomingClass = createRef();
        this.housing = createRef();
        this.specifyTransferTextfield = createRef();
        this.majorsTextfield = createRef();
        this.gender = createRef();
        this.specifyGenderTextfield = createRef();
        this.ethnicity = createRef();
        this.specifyEthnicityTextfield = createRef();
        this.userPreferences = createRef();
        this.bio = createRef();
        this.hometown = createRef();

        // Setup debounce funcs for each field for saving profile progress

        // departmentId gets set to department before being sent up
        const extraFields = ['major', 'department', 'sms'];

        const allFields = this.fields.concat(PrefsDomain.profileFields).concat(extraFields);

        if (typeof props.saveProfileProgress === 'function') {

            allFields.filter((field) => {

                return ![
                    'picFile',
                    'picPreviewURL',
                    'cropPosition',
                    'cropRotation',
                    'cropScale'
                ]
                    .includes(field);
            }).forEach((field) => {

                this[`saveProgress${field}`] = Debounce(props.saveProfileProgress, SAVE_PROGRESS_DEBOUNCE);
            });
        }

        if (typeof props.savePreferencesProgress === 'function') {
            PrefsDomain.prefFields.forEach((field) => {

                this[`saveProgressPreferences${field}`] = Debounce(props.savePreferencesProgress, SAVE_PROGRESS_DEBOUNCE);
            });
        }

        this.genderRadioOptions = [
            'male',
            'female',
            'nonbinary',
            'decline'
        ];

        this.ethnicityRadioOptions = [
            'american-indian-or-alaska-native',
            'asian',
            'black',
            'canadian-aboriginal',
            'hispanic-or-latinx',
            'middle-eastern',
            'pacific-islander',
            'white',
            'decline'
        ];

        // Needed in getStateDefaultsWithSignupDetails
        this.isInGenderRadioList = this._isInGenderRadioList.bind(this);

        this.state = this.getStateDefaultsWithSignupDetails(props.signupDetails);

        this.includePreferences = false; // Default, can be overridden by extending class
        this.getSignupDetails = this._getSignupDetails.bind(this);
        this.setPicFile = this._setPicFile.bind(this);
        this.preparePicPreview = this._preparePicPreview.bind(this);
        this.startCropping = this._startCropping.bind(this);
        this.closeCropping = this._closeCropping.bind(this);
        this.doneCropping = this._doneCropping.bind(this);
        this.choosePic = this._choosePic.bind(this);
        this.error = this._error.bind(this);
        this.validate = this._validate.bind(this);
        this.setFieldValue = this._setFieldValue.bind(this);
        this.setSelectValue = this._setSelectValue.bind(this);
        this.setRadioValAsBool = this._setRadioValAsBool.bind(this);
        this.setToggleFieldValue = this._setToggleFieldValue.bind(this);
        this.blurBio = this._blurBio.bind(this);
        this.focusBio = this._focusBio.bind(this);
        this.blurStudentBio = this._blurStudentBio.bind(this);
        this.focusStudentBio = this._focusStudentBio.bind(this);
        this.major = this._major.bind(this);
        this.setHometown = this._setHometown.bind(this);
        this.onToggleTransfer = this._onToggleTransfer.bind(this);
        this.transferText = this._transferText.bind(this);
        this.setTransferId = this._setTransferId.bind(this);
        this.setTransferName = this._setTransferName.bind(this);
        this.onGenderChange = this._onGenderChange.bind(this);
        this.validate13YearsOld = this._validate13YearsOld.bind(this);
        this.userPreferencesSubmit = this._userPreferencesSubmit.bind(this);
        this.maybeSavePreferencesProgress = this._maybeSavePreferencesProgress.bind(this);
        this.uploadPhoto = this._uploadPhoto.bind(this);
        this.hasError = this._hasError.bind(this);
        this.getDescribedById = this._getDescribedById.bind(this);
        this.setBirthdate = this._setBirthdate.bind(this);
        this.focusGenderText = this._focusGenderText.bind(this);
        this.blurGenderText = this._blurGenderText.bind(this);
        this.resetSelectedPicFile = this._resetSelectedPicFile.bind(this);
        this.editPhoto = this._editPhoto.bind(this);
        this.capturePhoto = this._capturePhoto.bind(this);


        // Prevent a warning if we try to set state on a component that has unmounted
        this.stillExists = true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (!this.isSignupPage) {
            if ((!!nextProps.preferencesLoading !== !!this.props.preferencesLoading) && !nextProps.preferencesLoading) {

                this.setState(this.getStateDefaultsWithSignupDetails(nextProps.signupDetails));

            }

            return;
        }

        // We're fetching signup progress from the api
        // So if signupDetails comes in later we'll want to
        // populate it here
        if (nextProps.signupDetails && nextProps.signupDetails !== this.props.signupDetails) {
            this.setState(this.getStateDefaultsWithSignupDetails(nextProps.signupDetails));
        }
    }

    componentDidMount() {

        FixMuiTextAreaAriaLabel(this.bio.current, 'biography input');

        if (this.props.location && this.props.location.state) {

            if (this.props.location.state.scrollToInterests) {

                const fieldEl = this.interests && this.interests.current;

                setTimeout(() => {

                    if (typeof fieldEl.scrollIntoView === 'function') {
                        fieldEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                    else if (typeof fieldEl.focus === 'function') {
                        fieldEl.focus();
                    }
                }, 500);
            }

            if (this.props.location.state.returnTo) {
                this.setState({
                    successRedirectPath: this.props.location.state.returnTo
                });
            }
        }
    }

    componentDidUpdate() {

        if (this.bio?.current) {
            FixMuiTextAreaAriaLabel(this.bio.current, 'biography input');
        }
    }

    componentWillUnmount() {

        this.stillExists = false;
    }

    getStateDefaultsWithSignupDetails(signupDetails) {

        const gender = signupDetails.gender || null;
        const ethnicity = signupDetails.ethnicity || null;

        const transferWithDefault = signupDetails.transfer ? {
            id: signupDetails.transfer.id,
            name: signupDetails.transfer.id ? null : signupDetails.transfer.name
        } : {
            id: null,
            name: null
        };

        const veteranWithDefault = internals.applyPropDefault(
            signupDetails.veteran,
            false
        );

        const workRemoteWithDefault = internals.applyPropDefault(
            signupDetails.workRemote,
            false
        );

        const parentWithDefault = internals.applyPropDefault(
            signupDetails.parent,
            false
        );

        const onlineStudentWithDefault = internals.applyPropDefault(
            signupDetails.onlineStudent,
            false
        );

        const openToSocialWithDefault = internals.applyPropDefault(
            signupDetails.openToSocial,
            true
        );

        const fullTimeWithDefault = internals.applyPropDefault(
            signupDetails.fullTime,
            true
        );

        const hometownTextWithDefault = signupDetails.hometown
            ? signupDetails.hometown.name
            : null;

        const hometownWithDefault = signupDetails.hometown
            ? signupDetails.hometown
            : {
                id: null,
                placeId: null,
                name: null,
                onCampus: null
            };

        const housingWithDefault = signupDetails.housing || {
            id: null,
            placeId: null,
            name: null,
            onCampus: false
        };

        const majorText = !signupDetails.majorId ? '' : this.props.majors.find((m) => m.id === signupDetails.majorId)?.name;
        const { defaultInterestId } = this.props;

        return {
            picFile: signupDetails.picFile || null,
            picFileErrorShow: null,
            picPreviewURL: signupDetails.picPreviewURL || '',
            croppedPicture: signupDetails.croppedPicture,
            originalPicture: signupDetails.picture,
            cropping: null,
            cropPosition: signupDetails.cropPosition || { x: .5, y: .5 },
            cropRotation: signupDetails.cropRotation || 0,
            cropScale: signupDetails.cropScale || 1,
            firstName: signupDetails.firstName || '',
            firstNameErrorShow: false,
            lastName: signupDetails.lastName || '',
            lastNameErrorShow: false,
            birthdate: signupDetails.birthdate || null,
            birthdateErrorShow: false,
            hometown: hometownWithDefault,
            hometownText: hometownTextWithDefault,
            housing: housingWithDefault,
            housingText: housingWithDefault.name || '',
            hometownTextErrorShow: false,
            majorId: signupDetails.majorId || null,
            majorText,
            departmentId: signupDetails.departmentId || '',
            officeId: signupDetails.officeId || '',
            yearHiredId: signupDetails.yearHiredId || '',
            workRemote: workRemoteWithDefault,
            title: signupDetails.title || '',
            majorIdErrorShow: false,
            incomingClass: signupDetails.incomingClass || '',
            incomingClassErrorShow: false,
            graduatingClass: signupDetails.graduatingClass || '',
            career: signupDetails.career || '',
            careerErrorShow: false,
            profession: signupDetails.profession || '',
            professionErrorShow: false,
            type: signupDetails.type || '',
            interests: (signupDetails.interests && signupDetails.interests.length > 0)
                ? signupDetails.interests.slice()
                : (defaultInterestId ? [defaultInterestId] : []),
            interestsErrorShow: false,
            passionInterests: (signupDetails.passionInterests || []).slice(),
            bio: signupDetails.bio || '',
            studentBio: signupDetails.studentBio || '',
            studentName: signupDetails.studentName || '',
            transfer: transferWithDefault,  // { id: null, name: null } -> off, { id: null, name: '' } -> on, empty name
            veteran: veteranWithDefault,
            parent: parentWithDefault,
            onlineStudent: onlineStudentWithDefault,
            openToSocial: openToSocialWithDefault,
            fullTime: fullTimeWithDefault,
            gender,
            genderText: !this.isInGenderRadioList(gender) ? gender : '',
            genderTextErrorShow: false,
            ethnicity: ethnicity || 'decline',
            ethnicityText: !this.isInEthnicityRadioList(ethnicity) ? ethnicity : '',
            ethnicityTextErrorShow: false,
            preferences: signupDetails.preferences || {},
            pageIsBlocking: false,
            pageHasErrors: false,
            successRedirectPath: '/app/welcome',
            picSpeedDialOpen: false,
            submitting: false
        };
    }

    maybeRenameField = (field) => {

        switch (field) {
            case 'departmentId':
                return 'department';
            default:
                return field;
        }
    }

    maybeSaveProgress(field, obj) {

        if (this.state.submitting) {
            return;
        }

        if (typeof this[`saveProgress${field}`] === 'function') {

            const finalObj = Object.keys(obj)
                .reduce((collector, key) => {

                    const val = obj[key];
                    const finalVal = (val === '' ? null : val);
                    collector[this.maybeRenameField(key)] = finalVal;
                    return collector;
                }, {});

            this[`saveProgress${field}`](finalObj);
        }
    }

    _maybeSavePreferencesProgress(field, value) {

        if (PrefsDomain.profileFields.indexOf(field) !== -1) {
            this.maybeSaveProgress(field, { [field]: value });
        }
        else if (Array.isArray(field) && field.some((_field) => PrefsDomain.profileFields.includes(_field))) {

            if (Array.isArray(field) && Array.isArray(value) && field !== null && value !== null) {

                this.setState({ pageIsBlocking: true });
                this.maybeSaveProgress('phone', { 'phone': value[0], 'phoneCountry': value[1], 'sms': value[2] ? 'on' : 'off' });
                //this[`saveProgressphone`]({ 'phone': value[0], 'phoneCountry': value[1] });
            }
        }
        else if (field === 'sms') {
            this.setState({ pageIsBlocking: true });
            this.maybeSaveProgress('sms', { 'sms': value ? 'on' : 'off' });
        }
        else if (typeof this[`saveProgressPreferences${field}`] === 'function') {
            this[`saveProgressPreferences${field}`]({ [field]: value });
        }
    }

    _editPhoto() {

        const {
            originalPicture,
            picFile
        } = this.state;

        if (picFile) {
            this.startCropping(picFile);
        }
        else if (originalPicture) {
            const http = context.http.nearpeer;

            const url = getOriginalImageUrl(originalPicture);

            const config = { responseType: 'blob' };
            http.get(url, config).then((response) => {

                const _picFile = new File([response.data], 'tmp.jpeg');

                this.setPicFile(_picFile);
            });
        }
    }

    _setPicFile(picFile) {

        this.setState({ picFile }, () => {

            this.maybeHideErrorIfShown('picFile')();

            if (this.validate('picFile') !== null) {
                return this.preparePicPreview(null);
            }

            return this.startCropping(picFile);
        });
    }
    _resetSelectedPicFile() {

        this.setState({ picFile: null });
    }

    _preparePicPreview(canvas) {

        return this.setState({ picPreviewURL: canvas && canvas.toDataURL() });
    }

    _startCropping(picFile) {

        return this.setState({ cropping: picFile });
    }

    async _doneCropping({ canvas, position, rotation, scale }) {

        this.setState({
            cropping: null,
            cropPosition: position,
            cropRotation: rotation,
            cropScale: scale
        });

        this.preparePicPreview(canvas);

        if (typeof this.props.saveProfilePicture === 'function') {
            this.setState({ uploadingProfilePic: true });
            const res = await this.props.saveProfilePicture({
                picture: this.state.picFile,
                cropPosition: position,
                cropRotation: rotation,
                cropScale: scale
            });

            this.setState({ uploadingProfilePic: false });

            if (!res) {
                this.setState({ picPreviewURL: '' });
            }
        }
    }

    _closeCropping() {

        this.setState({
            cropping: null,
            cropPosition: { x: .5, y: .5 },
            cropRotation: 0,
            cropScale: 1,
            picFile: null
        });
    }

    setMajorText = (value) => {

        const extraState = {};

        if (value === '') {
            extraState.majorId = null;
        }

        this.setState({ majorText: value, ...extraState });
    }

    clearMajorId = () => {

        this.setState({ majorId: null });
    }

    setMajorId = ({ id, name }) => {

        this.setState(
            {
                majorId: id,
                majorText: name,
                pageIsBlocking: true
            },
            () => this.maybeSaveProgress('major', { major: [id] })
        );
    }

    _major() {

        const { majorId } = this.state;

        if (!majorId) {
            return null;
        }

        const major = this.props.majors.find((m) => m.id === majorId);

        return major || null;
    }

    _setHometown(data) {

        const hometown = {
            id: null,
            placeId: data.place_id,
            name: data.description,
            onCampus: false
        };

        this.setState(
            { hometown },
            () => this.maybeSaveProgress('hometown', { hometown })
        );
    }

    _onToggleTransfer(ev, isChecked) {

        const transfer = isChecked ? { id: null, name: '' } : { id: null, name: null };

        this.setState(() => ({ transfer, pageIsBlocking: true }), () => {

            this.specifyTransferTextfield.current && this.specifyTransferTextfield.current.focus();
        });
    }

    _setTransferId(event, { suggestion: { id } }) {

        this.setState({ transfer: { id, name: null }, pageIsBlocking: true },
            () => this.maybeSaveProgress('transfer', { transfer: { id } }));
    }

    _setTransferName(ev, info) {

        // Nullish operator accepts ''
        const value = info?.newValue ?? ev?.target?.value ?? ev;

        this.setState({
            transfer: {
                id: null,
                name: value
            },
            pageIsBlocking: true
        },
        () => this.maybeSaveProgress('transfer', { transfer: { name: value } }));
    }

    _transferText() {

        const { id, name } = this.state.transfer;

        if (id) {
            const transfer = this.props.transfers.find((t) => t.id === id);
            return transfer?.name || '';
        }

        return name || '';
    }

    transferId = () => {

        const { id } = this.state.transfer;

        return id;
    }

    _onGenderChange(ev) {

        const value = ev.target.value;

        const genderObj = {};

        const { genderText } = this.state;

        if (value === 'specify') {
            genderObj.gender = genderText || '';
        }
        else if (this.isInGenderRadioList(value)) {
            genderObj.gender = value;
        }
        else {
            genderObj.genderText = value;
            genderObj.gender = value;
        }

        this.setState(() => ({ ...genderObj, pageIsBlocking: true }), () => {

            this.specifyGenderTextfield.current?.focus();
            this.maybeSaveProgress('gender', { gender: genderObj.gender });
        });
    }

    onEthnicityChange = (evt) => {

        const value = evt.target.value;

        const ethnicityObj = {};

        const { ethnicityText } = this.state;

        if (value === 'specify') {
            ethnicityObj.ethnicity = ethnicityText || '';
        }
        else if (this.isInEthnicityRadioList(value)) {
            ethnicityObj.ethnicity = value;
        }
        else {
            ethnicityObj.ethnicityText = value;
            ethnicityObj.ethnicity = value;
        }

        this.setState(() => ({ ...ethnicityObj, pageIsBlocking: true }), () => {

            this.specifyEthnicityTextfield.current?.focus();
            this.maybeSaveProgress('ethnicity', { ethnicity: ethnicityObj.ethnicity });
        });
    }

    _isInGenderRadioList(genderSelection) {

        if (!genderSelection) {
            return false;
        }

        return this.genderRadioOptions.indexOf(genderSelection) > -1;
    }

    isInEthnicityRadioList(ethnicitySelection) {

        if (!ethnicitySelection) {
            return false;
        }

        return this.ethnicityRadioOptions.indexOf(ethnicitySelection) > -1;
    }

    async _capturePhoto() {

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'user',
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                }
            });

            const video = document.createElement('video');
            video.srcObject = stream;
            video.autoplay = true;

            video.style.transform = 'scaleX(-1)';

            const dialog = document.createElement('dialog');
            dialog.style.padding = '20px';

            const takePhotoBtn = document.createElement('button');
            takePhotoBtn.textContent = 'Take Photo';
            takePhotoBtn.className = 'primary';
            takePhotoBtn.style.cssText = CameraButton.componentStyle.rules.join('');

            const cancelBtn = document.createElement('button');
            cancelBtn.textContent = 'Cancel';
            cancelBtn.className = 'secondary';
            cancelBtn.style.cssText = CameraButton.componentStyle.rules.join('');

            dialog.appendChild(video);
            dialog.appendChild(document.createElement('br'));
            dialog.appendChild(takePhotoBtn);
            dialog.appendChild(cancelBtn);

            document.body.appendChild(dialog);
            dialog.showModal();

            takePhotoBtn.onclick = () => {

                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');

                ctx.scale(-1, 1);
                ctx.translate(-canvas.width, 0);

                ctx.drawImage(video, 0, 0);

                canvas.toBlob((blob) => {

                    const file = new File([blob], 'webcam-photo.jpg', { type: 'image/jpeg' });
                    this.setPicFile(file);

                    stream.getTracks().forEach((track) => track.stop());
                    dialog.remove();
                }, 'image/jpeg');
            };

            cancelBtn.onclick = () => {

                stream.getTracks().forEach((track) => track.stop());
                dialog.remove();
            };

            dialog.addEventListener('close', () => {

                stream.getTracks().forEach((track) => track.stop());
                dialog.remove();
            });

        }
        catch (err) {
            console.error('Error accessing webcam:', err);
            this.props.showNotification('Unable to access camera. Please click the icon to the immediate left of the URL in the address bar.');
        }
    }

    async _choosePic(e) {

        let file = e.target.files[0];

        if (file === null) {
            return;
        }

        const { name } = file;

        // Attempt to convert heic, if the img is already browser readable ignore the err.
        try {
            this.setState({ convertingProfilePic: true });

            file = await Heic2any({
                blob: file,
                toType: 'image/jpeg'
            });

            // Update extension to jpeg
            const convertedName = name.split('.')[0] + '.jpeg';

            file = new File([file], convertedName, {
                type: 'image/jpeg',
                lastModified: new Date()
            });
        }
        catch (err) {
            if (err.message?.toLowerCase().includes('image is already browser readable')) {
                file = e.target.files[0];
            }
            else {
                console.log('HEIC err', err);
                throw err;
            }
        }

        try {
            // BrowserImageCompression
            file = await BrowserImageCompression(file, {
                maxSizeMB: 5,
                fileType: 'image/jpeg'
            });

            // Update extension to jpeg
            const convertedName = name.split('.')[0] + '.jpeg';

            file = new File([file], convertedName, {
                type: 'image/jpeg',
                lastModified: new Date()
            });
        }
        catch (err) {
            console.log('BrowserImageCompression err', err);
            file = e.target.files[0];
        }

        this.setState({ convertingProfilePic: false });

        this.setPicFile(file);
        e.target.value = null; // Reset so we can select the same image and still trigger an onChange
    }

    onInterestsChange = (ev, value) => {

        if (value === null) {
            value = [];
        }

        this.setState(
            {
                interests: value,
                pageIsBlocking: true
            },
            () => {

                return this.maybeSaveProgress(
                    'interests', {
                        interests: JSON.stringify(Map(value, 'id')),
                        passionInterests: JSON.stringify(Map(this.state.passionInterests, 'id'))
                    }
                );
            }
        );
    }

    onPassionInterestsChange = (value) => {

        if (value === null) {
            value = [];
        }

        this.setState(
            {
                passionInterests: value,
                pageIsBlocking: true
            },
            () => {

                return this.maybeSaveProgress(
                    'interests', {
                        interests: JSON.stringify(Map(this.state.interests, 'id')),
                        passionInterests: JSON.stringify(Map(value, 'id'))
                    }
                );
            }
        );
    }

    _focusBio() {

        this.setState({ bioFocused: true });
        this.maybeHideErrorIfShown('bio', false)();
    }

    _blurBio() {

        this.setState({ bioFocused: false });
        this.maybeHideErrorIfShown('bio')();
    }

    _focusStudentBio() {

        this.setState({ studentBioFocused: true });
        this.maybeHideErrorIfShown('studentBio', false)();
    }

    _blurStudentBio() {

        this.setState({ studentBioFocused: false });
        this.maybeHideErrorIfShown('studentBio')();
    }

    _focusGenderText() {

        this.setState({ genderTextFocused: true });
        this.maybeHideErrorIfShown('gender', false)();
    }

    _blurGenderText() {

        this.setState({ genderTextFocused: false });
        this.maybeHideErrorIfShown('gender')();
    }

    focusEthnicityText = () => {

        this.setState({ ethnicityTextFocused: true });
        this.maybeHideErrorIfShown('ethnicity', false)();
    }

    blurEthnicityText = () => {

        this.setState({ ethnicityTextFocused: false });
        this.maybeHideErrorIfShown('ethnicity')();
    }

    _userPreferencesSubmit(preferences, setStateCb) {

        return this.setState({
            preferences
        }, setStateCb);
    }

    submit = (options) => {

        const { shouldRedirect = true } = options || {};

        const prefs = this.userPreferences.current ? this.userPreferences.current : null;

        const userPreferencesHasErrors = prefs && prefs.fields.some(prefs.validate);

        if (userPreferencesHasErrors) {
            this.userPreferences.current.submit(); // Will return early because of errors
        }

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            this.setState({ pageHasErrors: true });
            // On submit attempt, all errors are fair game to display

            this.housing?.current?.validateAndMaybeShowErr();

            this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                if (this.validate(field)) {
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));
        }

        if (hasErrors || userPreferencesHasErrors) {

            this.setState({ pageHasErrors: true });
            // scrolling to first field with error
            // each field has slightly different structure, so we need to detect how to handle each of them
            const firstFieldWithError = this.fields.filter(this.validate).shift();

            let fieldEl = this[firstFieldWithError] && this[firstFieldWithError].current;
            if (firstFieldWithError === 'gender') {
                fieldEl = this.specifyGenderTextfield && this.specifyGenderTextfield.current;
            }

            if (fieldEl) {
                if (typeof fieldEl.scrollIntoView === 'function') {
                    fieldEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                else if (typeof fieldEl.focus === 'function') {
                    fieldEl.focus();
                }
                else {
                    if (fieldEl instanceof HTMLElement) {
                        const inputElem = fieldEl.querySelectorAll('input[aria-invalid="true"]');
                        if (inputElem.length) {
                            inputElem[0].focus();
                        }
                        else {
                            fieldEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    }
                }
            }

            return this.props.onInvalidSubmit();
        }

        // No errors?  Submit the field/values
        this.setState(
            {
                submitting: true,
                pageIsBlocking: false,
                pageHasErrors: false
            },
            async () => {

                try {
                    const { picFile, croppedPicture, picPreviewURL } = this.state;

                    if (!picFile && !croppedPicture && !picPreviewURL) {
                        await this.props.saveProfilePicture({
                            picture: null,
                            cropPosition: { x: .5, y: .5 },
                            cropRotation: 0,
                            cropScale: 1
                        });
                    }

                    if (shouldRedirect) {
                        await this.props.onSubmit(
                            this.getSignupDetails(),
                            this.state.successRedirectPath
                        );

                        if (this.stillExists) {
                            this.setState({
                                pageIsBlocking: false,
                                pageHasErrors: false,
                                submitting: false
                            });
                        }
                    }
                    else {
                        await this.props.onSubmitNoRedirect(
                            this.getSignupDetails()
                        );

                        if (this.stillExists) {
                            this.setState({
                                pageIsBlocking: false,
                                pageHasErrors: false,
                                submitting: false
                            });
                        }
                    }
                }
                catch (err) {

                    this.setState({
                        pageIsBlocking: false,
                        pageHasErrors: true,
                        submitting: false
                    });
                }
            });
    }

    _getSignupDetails() {

        const details = this.fields.reduce((collector, field) => {

            let fieldValue = this.state[field];

            // Force the values to be null instead of emptystring
            if (fieldValue === '') {
                fieldValue = null;
            }

            collector[field] = fieldValue;

            return collector;
        }, {});

        const preferences = this.userPreferences.current && this.userPreferences.current.getUserPreferences();

        return {
            ...details,
            preferences
        };
    }

    _setToggleFieldValue(field) {

        return (ev, value) => {

            this.setState({ [field]: value, pageIsBlocking: true },
                () => this.maybeSaveProgress(field, { [field]: value }));
        };
    }

    handleClearField = (field) => {

        const value = '';
        let extras = {};

        if (field === 'hometownText' && value === '') {
            extras = {
                hometown: {
                    id: null,
                    placeId: null,
                    name: null,
                    onCampus: null
                },
                hometownText: null
            };
        }

        this.setState({
            [field]: value,
            pageIsBlocking: true,
            ...extras
        }, () => this.maybeSaveProgress(field, { [field]: value }));
    }

    _setFieldValue(field) {

        return (evtOrValue) => {

            // Nullish operator accepts ''
            const value = evtOrValue?.target?.value ?? evtOrValue;

            let extras = {};

            if (field === 'hometownText' && value === '') {
                extras = {
                    hometown: {
                        id: null,
                        placeId: null,
                        name: null,
                        onCampus: null
                    },
                    hometownText: null
                };
            }
            else if (field === 'housing' && value?.name === null) {
                extras = {
                    housingText: ''
                };
            }

            this.setState({
                [field]: value,
                pageIsBlocking: true,
                ...extras
            }, () => this.maybeSaveProgress(field, { [field]: value }));
        };
    }

    _setSelectValue(field) {

        return (event) => {

            if (field === 'incomingClass') {
                this.maybeHideErrorIfShown('incomingClass');
            }

            this.setState({
                [field]: event.target.value, pageIsBlocking: true
            },
            () => this.maybeSaveProgress(field, { [field]: event.target.value }));
        };
    }

    _setRadioValAsBool(field) {

        return (ev, ...a) => {

            const value = castBool(ev.target.value);
            this.setState({ [field]: value, pageIsBlocking: true },
                () => this.maybeSaveProgress(field, { [field]: value }));
        };
    }

    maybeHideErrorIfShown = (field, show) => {

        return () => {

            show = (typeof show === 'undefined') ? this.hasError(field) : show;

            if (!show) {
                return this.setState({ [`${field}ErrorShow`]: show });
            }
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            const hasErrors = this.fields.some(this.validate);
            if (!hasErrors) {
                this.setState({ pageHasErrors: false });
            }

            return null;
        }

        const error = this.validate(field);

        if (error !== null) {
            this.setState({ pageHasErrors: true });
            return <div id={this.id + '-' + field + '-error-message'} role='alert' aria-live='assertive'>{error}</div>;
        }

        const hasErrors = this.fields.some(this.validate);
        if (!hasErrors) {
            this.setState({ pageHasErrors: false });
        }

        return null;
    }

    _hasError(field) {

        if (this.validate(field) !== null) {
            this.setState({ pageHasErrors: true });
            return true;
        }

        return false;
    }

    _getDescribedById(field) {

        if (this.hasError(field)) {
            return (this.id + '-' + field + '-error-message');
        }

        return null;
    }

    _handleKeyPress(event) {

        if (event.nativeEvent.keyCode === 13 || event.nativeEvent.keyCode === 32) {
            event.preventDefault();
        }
    }

    _validate(field) {

        const value = this.state[field];

        const { useProfanityFilter, rolePermissions } = this.props;

        const profanityCheckFields = [
            'firstName',
            'bio',
            'title',
            'career',
            'profession',
            'studentBio',
            'studentName',
            'gender',
            'ethnicity'
        ];

        if (profanityCheckFields.includes(field)) {

            if (value) {
                const { passesCheck, badPhrases } = runProfanityCheck(value);
                if (useProfanityFilter && (!passesCheck || badPhrases.length > 0)) {
                    return `We detected inappropriate language in this field which violates community standards. The following word(s) are not allowed: ${badPhrases.join(', ')}.`;
                }
            }
        }

        switch (field) {
            case 'picFile': {
                if (value) {
                    const acceptedFiletypes = ['bmp', 'tif', 'tiff', 'gif', 'jpeg', 'jpg', 'jif', 'jfif', 'jp2', 'jpx', 'j2k', 'j2c', 'fpx', 'pcd', 'png'];
                    const extensionRejectionMsg = 'File must have one of these extensions: ' + acceptedFiletypes.join(', ');
                    const match = value.name.split('.').pop();
                    if (!match) {
                        return extensionRejectionMsg;
                    }

                    const ext = match.toLowerCase();
                    if (!acceptedFiletypes.includes(ext)) {
                        return extensionRejectionMsg;
                    }

                    if (value.size > (9.9 * MB_IN_BYTES)) {
                        return 'File must be under 10mb';
                    }
                }

                const userHasDefaultProfilePic = this.state.croppedPicture === DEFAULT_PROFILE_IMG;

                if (this.props.rolePermissions?.profilePicRequired && !value && (userHasDefaultProfilePic || (!this.state.croppedPicture && !this.state.picPreviewURL))) {
                    return 'Profile pic is required';
                }

                break;
            }

            case 'firstName':
                if (!value && value === '') {
                    return 'First name is required';
                }
                else if (value.length > MAX_FIRST_AND_LAST_NAME_LENGTH) {
                    return `First name may only be ${MAX_FIRST_AND_LAST_NAME_LENGTH} characters`;
                }

                break;
            case 'lastName':
                if (!value) {
                    return 'Last name is required';
                }
                else if (value.length > MAX_FIRST_AND_LAST_NAME_LENGTH) {
                    return `Last name may only be ${MAX_FIRST_AND_LAST_NAME_LENGTH} characters`;
                }

                break;
            case 'incomingClass': {

                if (!this.state.incomingClass && (value === null || value === '') && rolePermissions?.canEditIncomingClass) {
                    return 'Incoming class is required';
                }

                break;
            }

            // Only validate on signup page
            case 'birthdate':
                if (this.isSignupPage && this.birthdate.current) {
                    if (this.state.birthdate === null && rolePermissions?.canEditAge) {
                        return 'Age is required';
                    }

                    return this.validate13YearsOld(this.state.birthdate);
                }

                break;
            case 'majorId':
                if (this.state.majorText && value === null && rolePermissions?.canEditMajor) {
                    return 'Please choose a major in the list';
                }

                break;
                // This error was removed by client request.
                // The desire is to help prevent errors on signup
                // The result is the user will not be notified that
                // the department field won't be saved unless selected
                // from the autocomplete menu
                // case 'departmentId':

                //     if (this.state.departmentText && value === null) {
                //         return 'Please choose a department from the list';
                //     }

            //     break;
            case 'career':
                // pass through
                break;
            case 'profession':
                // pass through
                break;
            case 'interests':

                if (this.isSignupPage) {
                    if (!value.length) {
                        return 'Start typing to find and select at least one Interest';
                    }

                    if (value.length > INTERESTS.LIMIT) {

                        return `Sorry, you have exceeded the maximum number of ${INTERESTS.LIMIT} Interests. Please remove some before adding new Interests`;
                    }
                }

                break;
            case 'bio':
                if (value.length > MAX_BIO && rolePermissions?.canEditBio) {
                    return `Bio may only be ${MAX_BIO} characters long`;
                }

                break;
            case 'studentBio':
                if (value && value.length > MAX_BIO && rolePermissions?.canEditStudentBio) {
                    return `Bio may only be ${MAX_BIO} characters long`;
                }

                break;
            case 'gender':
                if (!value) {
                    return 'Pronouns field is required';
                }

                // 'genderText' is not included in 'fields' above
                // because we don't want to submit that as a separate
                // value.
                const { genderText } = this.state; // eslint-disable-line

                if (genderText && genderText.length > MAX_PRONOUNS_LENGTH) {
                    return `Gender may only be ${MAX_PRONOUNS_LENGTH} characters`;
                }

                break;
            case 'ethnicity':
                if (!value) {
                    return 'Ethnicity field is required';
                }

                // 'ethnicityText' is not included in 'fields' above
                // because we don't want to submit that as a separate
                // value.
                const { ethnicityText } = this.state; // eslint-disable-line

                if (ethnicityText && ethnicityText.length > MAX_ETHNICITY_LENGTH) {
                    return `Ethnicity may only be ${MAX_ETHNICITY_LENGTH} characters`;
                }

                break;
            // hometownText should go through to hometown validation
            /* falls through */
            case 'hometownText':
            case 'hometown': {

                const {
                    name
                } = this.state.hometown;

                // If something is typed but doesn't match
                if ((this.state.hometownText && !name)
                    && rolePermissions?.canEditHomeTown
                ) {
                    return 'Please choose an item in the list';
                }

                break;
            }

            case 'housing': {

                const {
                    name
                } = this.state.housing;

                if ((this.state.housingText && !name)
                    && rolePermissions?.canEditLiveOnCampus
                ) {
                    return 'Please choose an item in the list';
                }

                return rolePermissions?.canEditLiveOnCampus
                    && this.housing.current?.validate();
            }
        }

        return null;
    }

    _validate13YearsOld(date) {

        const age = internals.getAge(date);

        if (age < 13) {
            return 'Must be at least 13';
        }

        if (age > 150) {
            return 'Must be at most 150';
        }

        return null;
    }

    _uploadPhoto(ev) {

        ev.preventDefault();
        document.getElementById('upload-profile-photo').click();
    }

    _setBirthdate(ev) {

        const { value } = ev.target;
        const derivedBirthdate = Moment().subtract('years', value).toDate();
        this.setState({
            birthdate: derivedBirthdate,
            pageIsBlocking: true
        }, () => this.maybeSaveProgress('birthdate', { birthdate: derivedBirthdate }));
    }

    filterOptions = (options, { inputValue }, advanced = {}) => {

        return matchSorter(options, inputValue, advanced);
    };

    renderAgeField = () => {

        const { styles } = internals;

        const { birthdate } = this.state;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditAge) {
            return <TextField
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                required
                fullWidth
                max={150}
                min={13}
                id={'age-input'}
                errorStyle={styles.error}
                label='Age (not shown in your profile)'
                error={this.state.birthdateErrorShow}
                helperText={this.error('birthdate')}
                value={internals.getAge(birthdate) || ''}
                onChange={this.setBirthdate}
                ref={this.birthdate}
                aria-invalid={this.hasError('birthdate')}
                onBlur={this.maybeHideErrorIfShown('birthdate')}
                onFocus={this.maybeHideErrorIfShown('birthdate', false)}
            />;
        }

        return null;
    }

    renderMajorField = () => {

        const {
            rolePermissions
        } = this.props;

        const { styles } = internals;

        // Could use a useMemo based on checking length of majors
        const sortedMajors = SortBy(this.props.majors, 'name');

        if (rolePermissions?.canEditMajor) {
            return <div style={{ position: 'relative' }}>
                <MaterialReactAutocomplete
                    // MaterialReactAutocomplete uses style prop and doesn't support className
                    openOnFocus
                    label='Major or Program'
                    placeholder='Type to search...'
                    classes={{
                        root: Classes.textFieldRoot
                    }}
                    InputLabelProps={{
                        classes: {
                            root: Classes.textFieldInputLabelRoot
                        }
                    }}
                    style={{
                        width: '100%',
                        position: 'relative',
                        margin: 'auto 0'
                    }}
                    inputRef={this.majorsTextfield}
                    menuProps={{ className: Classes.autocompleteMenu }}
                    errorStyle={styles.error}
                    handleClearField={() => {

                        this.setMajorText('');
                        this.clearMajorId();
                        this.maybeHideErrorIfShown('majorId', false)();
                    }}
                    fullWidth
                    onChange={this.setMajorId}
                    onTextChange={this.setMajorText}
                    searchText={this.state.majorText}
                    value={this.state.majorId}
                    onBlur={this.maybeHideErrorIfShown('majorId')}
                    onFocus={this.maybeHideErrorIfShown('majorId', false)}
                    aria-invalid={this.hasError('majorId')}
                    error={this.state.majorIdErrorShow}
                    helperText={this.error('majorId')}
                    filter={FuzzyFilter}
                    MenuStyle={styles.autocompleteMenu}
                    renderSuggestionsOnFocus
                    dataSource={sortedMajors}
                    dataSourceConfig={{
                        label: 'name',
                        id: 'id'
                    }}
                />
            </div>;
        }

        return null;
    }

    renderCareerField = () => {

        const { styles, IconButton } = internals;

        const {
            career
        } = this.state;

        const {
            rolePermissions
            // isStudentRoleGroup
        } = this.props;


        if (rolePermissions?.canEditCareerAspiration) {
            return <TextField
                errorStyle={styles.error}
                fullWidth
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                id={'career-input'}
                label={'Career Aspiration'}
                error={this.state.careerErrorShow}
                helperText={this.error('career')}
                value={career}
                onChange={this.setFieldValue('career')}
                onBlur={this.maybeHideErrorIfShown('career')}
                onFocus={this.maybeHideErrorIfShown('career', false)}
                style={{ alignSelf: 'center' }}
                InputProps={{
                    endAdornment: (
                        <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                            <IconButton
                                onClick={() => this.handleClearField('career')}
                                data-focus-outline='radius:20,zIndex:1'
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }}
            />;
        }

        return null;
    }

    renderProfessionField = () => {

        const {
            styles
        } = internals;

        const {
            profession
        } = this.state;

        const {
            rolePermissions
        } = this.props;


        if (rolePermissions?.canEditProfession) {
            return <TextField
                errorStyle={styles.error}
                fullWidth
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                id={'profession-input'}
                label={'Profession'}
                error={this.state.professionErrorShow}
                helperText={this.error('profession')}
                value={profession}
                onChange={this.setFieldValue('profession')}
                onBlur={this.maybeHideErrorIfShown('profession')}
                onFocus={this.maybeHideErrorIfShown('profession', false)}
                style={{ alignSelf: 'center' }}
            />;
        }

        return null;
    }

    renderDepartmentField = () => {

        const {
            rolePermissions,
            departments
        } = this.props;

        const {
            departmentId
        } = this.state;

        const sortedDepartments = SortBy(departments, 'name');

        if (rolePermissions?.canEditDepartment && departments.length > 0) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} >
                <InputLabel
                    id='department-select-input-label'
                    className={Classes.textFieldInputLabelRoot}
                >
                    Department
                </InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    labelId='department-select-input-label'
                    id={'department-select-input'}
                    inputProps={{
                        'aria-labelledby': 'department-select-input-label'
                    }}
                    fullWidth
                    value={departmentId}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    MenuProps={{
                        className: 'role-dropdown'
                    }}
                    onChange={this.setSelectValue('departmentId')}
                    maxHeight={220}
                >
                    <MenuItem value=''>Select…</MenuItem>
                    {sortedDepartments.map((department) => {

                        return <MenuItem
                            key={department.id}
                            value={department.id}
                        >
                            <ListItemText primary={department.name} />
                        </MenuItem>;
                    })}
                </SelectField>
            </FormControl>;
        }

        return null;
    }

    renderOfficeField = () => {

        const {
            rolePermissions,
            officesList,
            isCompany
        } = this.props;

        const {
            officeId
        } = this.state;

        const sortedOffices = SortBy(officesList, 'name');

        if (rolePermissions?.canEditOffice && officesList.length > 0) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} >
                <InputLabel id='office-select-input-label' className={Classes.textFieldInputLabelRoot}>{`${isCompany ? 'Affiliated Office' : 'Campus'}`}</InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    labelId='office-select-input-label'
                    id={'office-select-input'}
                    inputProps={{
                        'aria-labelledby': 'office-select-input-label'
                    }}
                    fullWidth
                    value={officeId}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    MenuProps={{
                        className: 'role-dropdown'
                    }}
                    onChange={this.setSelectValue('officeId')}
                    maxHeight={220}
                >
                    <MenuItem value=''>Select…</MenuItem>
                    {sortedOffices.map((office) => {

                        return <MenuItem
                            key={office.id}
                            value={office.id}
                        >
                            <ListItemText primary={office.name} />
                        </MenuItem>;
                    })}
                </SelectField>
            </FormControl>;
        }

        return null;
    }

    renderYearHiredField = () => {

        const {
            rolePermissions,
            yearsHiredList
        } = this.props;

        const {
            yearHiredId
        } = this.state;

        if (rolePermissions?.canEditYearHired && yearsHiredList.length > 0) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} >
                <InputLabel id='year-hired-select-input-label' className={Classes.textFieldInputLabelRoot}>Year Hired</InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    labelId='year-hired-select-input-label'
                    id={'year-hired-select-input'}
                    inputProps={{
                        'aria-labelledby': 'year-hired-select-input-label'
                    }}
                    fullWidth
                    value={yearHiredId}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    MenuProps={{
                        className: 'role-dropdown'
                    }}
                    onChange={this.setSelectValue('yearHiredId')}
                    maxHeight={220}
                >
                    <MenuItem value=''>Select…</MenuItem>
                    {yearsHiredList.map((yearItem) => {

                        return <MenuItem
                            key={yearItem.id}
                            value={yearItem.id}


                        >
                            <ListItemText primary={yearItem.year} />
                        </MenuItem>;
                    })}
                </SelectField>
            </FormControl>;
        }

        return null;
    }

    renderRoleField = () => {

        const {
            type
        } = this.state;

        const {
            rolePermissions,
            badges
        } = this.props;

        if (rolePermissions?.canEditBadge && Array.isArray(badges) && !!badges.length) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} >
                <InputLabel id='role-select-input-label' className={Classes.textFieldInputLabelRoot}>Badge</InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    style={{ width: '100%' }}
                    labelId='role-select-input-label'
                    id={'role-select-input'}
                    inputProps={{
                        'aria-labelledby': 'role-select-input-label'
                    }}
                    fullWidth
                    value={type !== '' && badges.some((badge) => badge.name === type) ? type : ''}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    MenuProps={{
                        className: 'role-dropdown'
                    }}
                    onChange={this.setSelectValue('type')}
                    maxHeight={220}
                >
                    <MenuItem value=''>Select…</MenuItem>
                    {badges.map((badge) => {

                        return <MenuItem
                            key={badge.name}
                            value={badge.name}
                        >
                            <ListItemIcon classes={{ root: Classes.menuIcon }}>
                                <UserBadge
                                    badges={badges}
                                    userType={badge.name}
                                />
                            </ListItemIcon>
                            <ListItemText primary={badge.label} />
                        </MenuItem>;
                    })}
                </SelectField>
            </FormControl>;
        }

        return null;
    }

    renderTitleField = () => {

        const {
            styles,
            IconButton
        } = internals;

        const {
            title
        } = this.state;

        const {
            rolePermissions
        } = this.props;

        if (rolePermissions?.canEditTitle) {
            return <TextField
                errorStyle={styles.error}
                fullWidth
                label='Title'
                id={'title-input'}
                error={this.state.titleErrorShow}
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                helperText={this.error('title')}
                value={title}
                onChange={this.setFieldValue('title')}
                onBlur={this.maybeHideErrorIfShown('title')}
                style={{ alignSelf: 'center' }}
                InputProps={{
                    endAdornment: (
                        <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                            <IconButton
                                onClick={() => this.handleClearField('title')}
                                data-focus-outline='radius:20,zIndex:1'
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }}
            />;
        }

        return null;
    }

    renderHomeTownField = () => {

        const {
            rolePermissions,
            isCompany,
            isCommunity,
            isSchoolOnline
        } = this.props;

        if (rolePermissions?.canEditHomeTown) {
            return <GooglePlaceAutocomplete
                inputRef={this.hometown}
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                style={{ width: '100%', position: 'relative', margin: 'auto 0' }}
                label={(isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : 'Hometown'}
                placeholder='Type to find your city/town...'
                onChange={this.setHometown}
                onTextChange={this.setFieldValue('hometownText')}
                searchText={this.state.hometownText || ''}
                value={this.state.hometown.name}
                onBlur={this.maybeHideErrorIfShown('hometown')}
                onFocus={this.maybeHideErrorIfShown('hometown', false)}
                error={this.state.hometownErrorShow}
                helperText={this.error('hometown')}
                openOnFocus={false}
                maxHeight={220}
                id='hometown-autocomplete-dropdown'
                types={[
                    'locality',
                    'sublocality',
                    'neighborhood',
                    'administrative_area_level_3',
                    'colloquial_area'
                ]}
                handleClearField={() => {

                    this.handleClearField('hometownText');
                    this.maybeHideErrorIfShown('hometown', false)();
                }}
            />;
        }

        return null;
    }

    renderIncomingClassField = () => {

        const {
            incomingClass
        } = this.state;

        const {
            rolePermissions,
            incomingClassList
        } = this.props;

        if (rolePermissions?.canEditIncomingClass && incomingClassList?.length) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} required>
                <InputLabel
                    id='incoming-class-select-input-label'
                    className={Classes.textFieldInputLabelRoot}
                    error={this.state.incomingClassErrorShow}
                >
                    Incoming Class
                </InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    // SelectField has width: 256px set as inline style
                    style={{ width: '100%' }}
                    id={'incoming-class-select-input'}
                    inputProps={{
                        'aria-labelledby': 'incoming-class-select-input-label'
                    }}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    labelId={'incoming-class-select-input-label'}
                    value={incomingClass}
                    defaultValue={incomingClass}
                    error={this.state.incomingClassErrorShow}
                    onBlur={this.maybeHideErrorIfShown('incomingClass')}
                    onChange={this.setSelectValue('incomingClass')}
                    ref={this.incomingClass}
                    maxHeight={220}
                >
                    <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                    {incomingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                </SelectField>
                {this.state.incomingClassErrorShow && <FormHelperText error={this.state.incomingClassErrorShow}>{this.error('incomingClass')}</FormHelperText>}
            </FormControl>;
        }

        return null;
    }

    renderGraduatingClassField = () => {

        const {
            graduatingClass
        } = this.state;

        const {
            rolePermissions,
            graduatingClassList
        } = this.props;

        if (rolePermissions?.canEditGraduatingClass && graduatingClassList?.length) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} >
                <InputLabel id='graduating-class-select-input-label' className={Classes.textFieldInputLabelRoot}>Graduating Class</InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    // SelectField has width: 256px set as inline style
                    style={{ width: '100%' }}
                    id={'graduating-class-select-input'}
                    inputProps={{
                        'aria-labelledby': 'graduating-class-select-input-label'
                    }}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    labelId={'graduating-class-select-input-label'}
                    value={graduatingClass}
                    defaultValue={''}
                    onChange={this.setSelectValue('graduatingClass')}
                    maxHeight={220}
                >
                    <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                    {graduatingClassList.map((x) => <MenuItem key={x.name} value={x.name}  >{x.name}</MenuItem>)}
                </SelectField>
            </FormControl>;
        }

        return null;
    }

    renderBioField = () => {

        const {
            rolePermissions,
            fieldsPlaceholders
        } = this.props;

        const {
            bio,
            bioFocused
        } = this.state;

        const {
            styles,
            IconButton,
            BioTextField
        } = internals;

        const {
            bio: bioPlaceholder
        } = fieldsPlaceholders;

        if (rolePermissions?.canEditBio) {
            return <BioTextField
                fullWidth
                multiline
                maxRows={3}
                onFocus={this.focusBio}
                onBlur={this.blurBio}
                value={bio}
                id={'bio-input'}
                aria-invalid={this.hasError('bio')}
                aria-describedby={this.getDescribedById('bio')}
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.bioTextFieldInputLabelRoot
                    }
                }}
                inputProps={{ maxLength: MAX_BIO }}
                InputProps={{
                    endAdornment: (
                        <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                            <IconButton
                                onClick={() => this.handleClearField('bio')}
                                data-focus-outline='radius:20,zIndex:1'
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }}
                ref={this.bio}
                label={
                    <label className={Classes.bioLabel}>
                        <span>Bio (something others might not know)&nbsp;</span>
                        <span className={`${Classes.bioChars} ${(bioFocused && bio.length >= MAX_BIO - 20) ? Classes.show : ''}`}>
                            ({MAX_BIO - bio.length} chars remaining)
                        </span>
                    </label>
                }
                error={this.state.bioErrorShow}
                helperText={this.error('bio')}
                onChange={this.setFieldValue('bio')}
                errorStyle={styles.error}
                placeholder={bioPlaceholder ? bioPlaceholder : 'Family? Pet Peeves? Brushes with Fame? Karaoke Anthem? Worst Dance Move?'}
            />;
        }

        return null;
    }

    sortInterests = (a, b) => {

        const aSpecial = a && a.special;
        const bSpecial = b && b.special;

        return (Number(aSpecial) - Number(bSpecial)) || a.categoryName.localeCompare(b.categoryName) || a.name.localeCompare(b.name);

    };

    renderInterestsField = () => {

        const {
            interests,
            passionInterests
        } = this.state;

        return <InterestsInputField
            values={interests.sort(this.sortInterests)}
            passionValues={passionInterests.sort(this.sortInterests)}
            inputRef={this.interests}
            onChange={this.onInterestsChange}
            onChangePassion={this.onPassionInterestsChange}
            onBlur={this.maybeHideErrorIfShown('interests')}
            onFocus={this.maybeHideErrorIfShown('interests', false)}
            showError={!!this.error('interests')}
            errorText={this.error('interests')}
            isSignUpPage={this.isSignupPage}
            isSearch={false}
            disableClearable
        />;
    }

    renderHousingField = () => {

        const {
            rolePermissions,
            isCompany,
            isCommunity,
            isSchoolOnline
        } = this.props;

        if (rolePermissions?.canEditLiveOnCampus) {

            return <React.Fragment>
                <HousingPicker
                    offCampusHousingList={this.props.offCampusHousingList}
                    onCampusHousingList={this.props.onCampusHousingList}
                    onChange={this.setFieldValue('housing')}
                    onTextChange={this.setFieldValue('housingText')}
                    searchText={this.state.housingText || ''}
                    value={this.state.housing}
                    offCampusLabel={(isCompany || isCommunity || isSchoolOnline) ? 'Where I Live' : 'Off-campus location:'}
                    showSwitch={!isCompany && !isCommunity && !isSchoolOnline}
                    ref={this.housing}
                />
            </React.Fragment>;
        }

        return null;
    }

    renderTransferField = () => {

        const {
            transfer
        } = this.state;

        const {
            styles
        } = internals;

        const {
            rolePermissions,
            role
        } = this.props;

        // To show/hide the textfield for transfer
        const transferToggled = (transfer.id !== null || transfer.name !== null);

        if (rolePermissions?.canEditTransfer) {
            return (
                <React.Fragment>
                    <QuestionSwitch
                        questionLabel={`${USER_ROLE_IDS.PARENT.includes(role.id) ? 'Is your student' : 'Are you'} a transfer student?`}
                        uncheckedLabel={'No/Skip'}
                        checkedLabel={'Yes'}
                        checked={transferToggled}
                        onChange={this.onToggleTransfer}
                        switchId={'transfer-toggle'}
                    />
                    {transferToggled && <div className={Classes.toggleTextGroup}>
                        <div />
                        <MaterialReactAutocomplete
                            // MaterialReactAutocomplete uses style prop and doesn't support className
                            style={{
                                width: 263,
                                position: 'relative',
                                marginTop: -10,
                                paddingBottom: 10
                            }}
                            inputRef={this.specifyTransferTextfield}
                            menuProps={{ className: Classes.autocompleteMenu }}
                            errorStyle={styles.error}
                            placeholder='Type to search...'
                            label='Transferred from:'
                            handleClearField={() => this.setTransferName('')}
                            fullWidth
                            onChange={this.setTransferId}
                            onTextChange={this.setTransferName}
                            searchText={this.transferText()}
                            value={this.transferId()}
                            helperText={this.error('transfer')}
                            onBlur={this.maybeHideErrorIfShown('transfer')}
                            onFocus={this.maybeHideErrorIfShown('transfer', false)}
                            filter={FuzzyFilter}
                            openOnFocus={false}
                            MenuStyle={styles.autocompleteMenu}
                            dataSource={this.props.transfers}
                            dataSourceConfig={{
                                label: 'name',
                                id: 'id'
                            }}
                        />
                    </div>}
                </React.Fragment>
            );
        }

        return null;
    }

    renderVeteranField = () => {

        const {
            veteran
        } = this.state;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditVeteran) {
            return <QuestionSwitch
                questionLabel={'Are you a veteran?'}
                uncheckedLabel={'No/Skip'}
                checkedLabel={'Yes'}
                checked={veteran}
                onChange={this.setToggleFieldValue('veteran')}
                switchId={'veteran-toggle'}
            />;
        }

        return null;
    }

    renderWorkRemoteField = () => {

        const {
            workRemote
        } = this.state;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditWorkRemote) {
            return <QuestionSwitch
                questionLabel={'Do you work remotely?'}
                uncheckedLabel={'No/Skip'}
                checkedLabel={'Yes'}
                checked={workRemote}
                onChange={this.setToggleFieldValue('workRemote')}
                switchId={'workRemote-toggle'}
            />;
        }

        return null;
    }

    renderParentField = () => {

        const {
            parent
        } = this.state;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditParent) {
            return <QuestionSwitch
                questionLabel={'Are you a parent?'}
                uncheckedLabel={'No/Skip'}
                checkedLabel={'Yes'}
                checked={parent}
                onChange={this.setToggleFieldValue('parent')}
                switchId={'parent-toggle'}
            />;
        }

        return null;
    }

    renderOnlineField = () => {

        const {
            onlineStudent
        } = this.state;

        const {
            rolePermissions
        } = this.props;

        if (rolePermissions?.canEditOnline) {
            return <QuestionSwitch
                questionLabel={`Are you an online student?`}
                uncheckedLabel={'No'}
                checkedLabel={'Yes'}
                checked={onlineStudent}
                onChange={this.setToggleFieldValue('onlineStudent')}
                switchId={'online-student-toggle'}
            />;
        }

        return null;
    }

    renderOpenToSocialField = () => {

        const {
            openToSocial
        } = this.state;

        const {
            rolePermissions
        } = this.props;

        if (rolePermissions?.canEditOpenSocial) {
            return <QuestionSwitch
                questionLabel={`Open to social contact on Nearpeer?`}
                uncheckedLabel={'No'}
                checkedLabel={'Yes'}
                checked={openToSocial}
                onChange={this.setToggleFieldValue('openToSocial')}
                switchId={'open-to-social-toggle'}
            />;
        }

        return null;
    }

    renderTimeStatusField = () => {

        const {
            fullTime
        } = this.state;

        const {
            styles
        } = internals;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditTimeStatus) {
            return <React.Fragment>
                <Divider style={styles.divider} />
                <div>
                    {/****** Full / Part time student ******/}
                    <RadioButtonGroup
                        name='fullTime'
                        label='Full / Part-time Status'
                        labelId='full-part-time-label'
                        labelProps={{
                            style: {
                                ...styles.formSubheader,
                                display: 'block'
                            }
                        }}
                        valueSelected={String(fullTime)}
                        onChange={this.setRadioValAsBool('fullTime')}
                    >
                        <RadioButton
                            value='false'
                            label='Part-time'
                            aria-label='Part time'
                            style={styles.radioBtn}
                        />
                        <RadioButton
                            value='true'
                            label='Full-time'
                            aria-label='Full time'
                            style={styles.radioBtn}
                        />
                    </RadioButtonGroup>
                </div>
            </React.Fragment>;
        }

        return null;
    }

    renderGenderField = () => {

        const {
            gender,
            genderText,
            genderTextFocused
        } = this.state;

        const {
            styles
        } = internals;

        let genderValueSelected = gender;
        if (gender !== null) {
            genderValueSelected = this._isInGenderRadioList(gender) ? gender : 'specify';
        }

        // To show/hide the textfield for gender
        let showSpecifyGenderTextfield = false;
        if (genderValueSelected === 'specify') {
            showSpecifyGenderTextfield = true;
        }

        let genderTextVal = genderText;
        if (!genderTextVal) {
            genderTextVal = '';
        }

        return <React.Fragment>
            <Divider style={styles.divider} />
            <div ref={this.gender} className={Classes.scrollMargin} />
            <div style={styles.genderContainer}>

                <RadioButtonGroup
                    name='gender'
                    label='Pronouns'
                    labelId='gender-label'
                    labelProps={{
                        style: {
                            ...styles.formSubheader,
                            display: 'block'
                        },
                        'aria-label': 'Select pronouns'
                    }}
                    valueSelected={genderValueSelected}
                    onChange={this.onGenderChange}
                    required
                    error={this.state.genderErrorShow}
                >
                    <RadioButton
                        value='male'
                        label='He/him'
                        style={styles.radioBtn}
                        aria-label='Pronouns he/him'
                    />
                    <RadioButton
                        value='female'
                        label='She/her'
                        style={styles.radioBtn}
                        aria-label='Pronouns she/her'
                    />
                    <RadioButton
                        value='nonbinary'
                        label='They/them'
                        style={styles.radioBtn}
                        aria-label='Pronouns they/them'
                    />
                    <RadioButton
                        value='decline'
                        label='Skip'
                        style={styles.radioBtn}
                        aria-label='Pronouns Skip'
                    />
                    <RadioButton
                        value='specify'
                        label='Let me specify'
                        style={styles.radioBtn}
                        aria-label='Pronouns Let me specify'
                    />
                </RadioButtonGroup>
                {showSpecifyGenderTextfield &&
                    <TextField
                        id='specifyGenderTextfield'
                        fullWidth
                        classes={{
                            root: Classes.textFieldRoot
                        }}
                        InputLabelProps={{
                            classes: {
                                root: Classes.textFieldInputLabelRoot
                            }
                        }}
                        value={genderValueSelected === 'specify' ? genderTextVal : ''}
                        label={<span className={`${Classes.bioChars} ${genderTextFocused ? Classes.show : ''}`}>
                            ({MAX_PRONOUNS_LENGTH - genderTextVal.length} chars)
                        </span>}
                        error={this.state.genderErrorShow}
                        helperText={this.error('gender')}
                        aria-label='Specify gender'
                        aria-invalid={this.hasError('gender')}
                        aria-describedby={this.getDescribedById('gender')}
                        onFocus={this.focusGenderText}
                        // onBlur={this.blurGenderText}
                        onChange={this.onGenderChange}
                        style={styles.specifyGenderTextfield}
                        ref={this.specifyGenderTextfield}
                    />
                }
            </div>
            {genderValueSelected !== 'specify' && this.error('gender') &&
                <div style={styles.radioError}>
                    {this.error('gender')}
                </div>
            }
        </React.Fragment>;
    }

    renderEthnicityField = () => {

        const {
            ethnicity,
            ethnicityText,
            ethnicityTextFocused
        } = this.state;

        const { styles } = internals;

        // To show/hide the textfield for gender

        let ethnicityValueSelected = ethnicity;
        if (ethnicity !== null) {
            ethnicityValueSelected = this.isInEthnicityRadioList(ethnicity) ? ethnicity : 'specify';
        }

        let showSpecifyEthnicityTextfield = false;
        if (ethnicityValueSelected === 'specify') {
            showSpecifyEthnicityTextfield = true;
        }

        let ethnicityTextVal = ethnicityText;
        if (!ethnicityTextVal) {
            ethnicityTextVal = '';
        }

        return <React.Fragment>
            <Divider style={styles.divider} />
            <div ref={this.ethnicity} className={Classes.scrollMargin} />
            <div style={styles.ethnicityContainer}>
                {ethnicityValueSelected !== 'specify' && this.error('ethnicity') &&
                    <div style={styles.radioError}>
                        {this.error('ethnicity')}
                    </div>
                }
                <RadioButtonGroup
                    name='ethnicity'
                    label='Background (Not displayed in your profile)'
                    labelId='ethnicity-label'
                    labelProps={{
                        style: {
                            ...styles.formSubheader,
                            display: 'block'
                        },
                        'aria-label': 'Select background'
                    }}
                    valueSelected={ethnicityValueSelected}
                    onChange={this.onEthnicityChange}
                >
                    <RadioButton
                        value='american-indian-or-alaska-native'
                        label='American Indian or Alaska Native'
                        style={styles.radioBtn}
                        aria-label='Background American Indian or Alaska Native'
                    />
                    <RadioButton
                        value='asian'
                        label='Asian'
                        style={styles.radioBtn}
                        aria-label='Background Asian'
                    />
                    <RadioButton
                        value='black'
                        label='Black'
                        style={styles.radioBtn}
                        aria-label='Background Black'
                    />
                    <RadioButton
                        value='canadian-aboriginal'
                        label='Canadian Aboriginal'
                        style={styles.radioBtn}
                        aria-label='Background Canadian Aboriginal'
                    />
                    <RadioButton
                        value='hispanic-or-latinx'
                        label='Hispanic or Latinx'
                        style={styles.radioBtn}
                        aria-label='Background Hispanic or Latinx'
                    />
                    <RadioButton
                        value='middle-eastern'
                        label='Middle Eastern'
                        style={styles.radioBtn}
                        aria-label='Background Middle Eastern'
                    />
                    <RadioButton
                        value='pacific-islander'
                        label='Pacific Islander'
                        style={styles.radioBtn}
                        aria-label='Background Pacific Islander'
                    />
                    <RadioButton
                        value='white'
                        label='White'
                        style={styles.radioBtn}
                        aria-label='Background White'
                    />
                    <RadioButton
                        value='decline'
                        label='Skip'
                        style={styles.radioBtn}
                        aria-label='Background Skip'
                    />
                    <RadioButton
                        value='specify'
                        label='Let me specify'
                        style={styles.radioBtn}
                        aria-label='Background Let me specify'
                    />
                </RadioButtonGroup>
                {showSpecifyEthnicityTextfield &&
                    <TextField
                        id='specifyEthnicityTextfield'
                        fullWidth
                        classes={{
                            root: Classes.textFieldRoot
                        }}
                        InputLabelProps={{
                            classes: {
                                root: Classes.textFieldInputLabelRoot
                            }
                        }}
                        value={ethnicityValueSelected === 'specify' ? ethnicityTextVal : ''}
                        label={<span className={`${Classes.bioChars} ${ethnicityTextFocused ? Classes.show : ''}`}>
                            ({MAX_ETHNICITY_LENGTH - ethnicityTextVal.length} chars)
                        </span>}
                        error={this.state.genderErrorShow}
                        helperText={this.error('ethnicity')}
                        aria-label='Specify ethnicity'
                        aria-invalid={this.hasError('ethnicity')}
                        aria-describedby={this.getDescribedById('ethnicity')}
                        onFocus={this.focusEthnicityText}
                        // onBlur={this.blurEthnicityText}
                        onChange={this.onEthnicityChange}
                        style={styles.specifyEthnicityTextfield}
                        ref={this.specifyEthnicityTextfield}
                    />
                }
            </div>
        </React.Fragment>;
    }

    renderParentsHeader = () => {

        const {
            rolePermissions
        } = this.props;

        const {
            styles
        } = internals;

        if (rolePermissions) {
            if (rolePermissions.canEditStudentName
                || rolePermissions.canEditStudentBio
                || rolePermissions.canEditMajor
                || rolePermissions.canEditTransfer
                || rolePermissions.canEditIncomingClass
                || rolePermissions.canEditGraduatingClass
                || rolePermissions.canEditOnline
            ) {
                return <h2 style={styles.sectionHeader}>About your student (optional)</h2>;
            }

            return null;
        }
    }

    renderParentsStudentNameField = () => {

        const {
            styles
        } = internals;

        const {
            studentName
        } = this.state;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditStudentName) {
            return <TextField
                errorStyle={styles.error}
                fullWidth
                classes={{
                    root: Classes.textFieldRoot
                }}
                InputLabelProps={{
                    classes: {
                        root: Classes.textFieldInputLabelRoot
                    }
                }}
                id={'student-name-input'}
                label='Student Name'
                error={this.state.studentNameErrorShow}
                helperText={this.error('studentName')}
                value={studentName}
                onChange={this.setFieldValue('studentName')}
                onBlur={this.maybeHideErrorIfShown('studentName')}
            />;
        }

        return null;
    }

    renderParentsStudentMajorField = () => {

        const {
            rolePermissions
        } = this.props;

        const { styles } = internals;
        const sortedMajors = [{ id: '', name: 'Select…' }]
            .concat(SortBy(this.props.majors, 'name'));

        if (rolePermissions?.canEditMajor) {
            return <div style={{ position: 'relative' }}>
                <MaterialReactAutocomplete
                    // MaterialReactAutocomplete uses style prop and doesn't support className
                    openOnFocus
                    label='Major or Program'
                    classes={{
                        root: Classes.textFieldRoot
                    }}
                    InputLabelProps={{
                        classes: {
                            root: Classes.textFieldInputLabelRoot
                        }
                    }}
                    style={{
                        width: '100%',
                        position: 'relative',
                        margin: 'auto 0'
                    }}
                    inputRef={this.majorsTextfield}
                    menuProps={{ className: Classes.autocompleteMenu }}
                    errorStyle={styles.error}
                    placeholder='Type to search...'
                    handleClearField={() => {

                        this.setMajorText('');
                        this.clearMajorId();
                    }}
                    fullWidth
                    onChange={this.setMajorId}
                    onTextChange={this.setMajorText}
                    searchText={this.state.majorText}
                    value={this.state.majorId}
                    onBlur={this.maybeHideErrorIfShown('majorId')}
                    onFocus={this.maybeHideErrorIfShown('majorId', false)}
                    aria-invalid={this.hasError('majorId')}
                    error={this.state.majorIdErrorShow}
                    helperText={this.error('majorId')}
                    filter={FuzzyFilter}
                    MenuStyle={styles.autocompleteMenu}
                    dataSource={sortedMajors}
                    dataSourceConfig={{
                        label: 'name',
                        id: 'id'
                    }}
                />
            </div>;
        }

        return null;
    }

    renderParentsStudentIncomingClassField = () => {

        const {
            incomingClass
        } = this.state;

        const {
            rolePermissions,
            incomingClassList
        } = this.props;


        if (rolePermissions?.canEditIncomingClass && incomingClassList?.length) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }}>
                <InputLabel id='incoming-class-select-input-label' error={this.state.incomingClassErrorShow} className={Classes.textFieldInputLabelRoot}>Incoming Class</InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    // SelectField has width: 256px set as inline style
                    style={{ width: '100%' }}
                    id={'incoming-class-select-input'}
                    inputProps={{
                        'aria-labelledby': 'incoming-class-select-input-label'
                    }}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    labelId={'incoming-class-select-input-label'}
                    value={incomingClass}
                    defaultValue={''}
                    error={this.state.incomingClassErrorShow}
                    onBlur={this.maybeHideErrorIfShown('incomingClass')}
                    onChange={this.setSelectValue('incomingClass')}
                    ref={this.incomingClass}
                    maxHeight={220}
                >
                    <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                    {incomingClassList.map((x) => <MenuItem key={x.name} value={x.name}  >{x.name}</MenuItem>)}
                </SelectField>
                {this.state.incomingClassErrorShow && <FormHelperText error={this.state.incomingClassErrorShow}>{this.error('incomingClass')}</FormHelperText>}
            </FormControl>;
        }

        return null;
    }

    renderParentsStudentGraduatingClassField = () => {

        const {
            graduatingClass
        } = this.state;

        const {
            rolePermissions,
            graduatingClassList
        } = this.props;

        if (rolePermissions?.canEditGraduatingClass && graduatingClassList?.length) {
            return <FormControl variant={'standard'} classes={{ root: Classes.selectFormControl }} >
                <InputLabel id='graduating-class-select-input-label' className={Classes.textFieldInputLabelRoot}>Graduating Class</InputLabel>
                {/* TODO update to use components/SelectField */}
                <SelectField
                    // SelectField has width: 256px set as inline style
                    style={{ width: '100%' }}
                    id={'graduating-class-select-input'}
                    inputProps={{
                        'aria-labelledby': 'graduating-class-select-input-label'
                    }}
                    classes={{
                        root: Classes.selectRoot,
                        select: Classes.selectInput
                    }}
                    labelId={'graduating-class-select-input-label'}
                    value={graduatingClass}
                    onChange={this.setSelectValue('graduatingClass')}
                    defaultValue={''}
                    maxHeight={220}
                >
                    <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                    {graduatingClassList.map((x) => <MenuItem key={x.name} value={x.name}  >{x.name}</MenuItem>)}
                </SelectField>
            </FormControl>;
        }

        return null;
    }

    renderParentsStudentBioField = () => {

        const {
            styles,
            IconButton
        } = internals;

        const {
            studentBio,
            bioFocused
        } = this.state;

        const { rolePermissions } = this.props;

        if (rolePermissions?.canEditStudentBio) {
            return <TextField
                fullWidth
                multiline
                onFocus={this.focusStudentBio}
                // onBlur={this.blurStudentBio}
                value={studentBio}
                id={'student-bio-input'}
                aria-invalid={this.hasError('studentBio')}
                aria-describedby={this.getDescribedById('studentBio')}
                label={<span>
                    A few words about your Student&nbsp;
                    <span className={`${Classes.bioChars} ${(bioFocused && studentBio.length >= MAX_BIO - 20) ? Classes.show : ''}`}>
                        ({MAX_BIO - studentBio.length} chars remaining)
                    </span>
                </span>}
                InputProps={{
                    endAdornment: (
                        <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                            <IconButton
                                onClick={() => this.handleClearField('studentBio')}
                                data-focus-outline='radius:20,zIndex:1'
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }}
                error={this.state.studentBioErrorShow}
                helperText={this.error('studentBio')}
                onChange={this.setFieldValue('studentBio')}
                errorStyle={styles.error}
                placeholder='Such as Interests, a fun fact, or career aspiration'
            />;
        }

        return null;
    }

    renderParentsStudentTransferField = () => {

        const {
            transfer
        } = this.state;

        const {
            styles
        } = internals;

        const {
            rolePermissions
        } = this.props;

        // To show/hide the textfield for transfer
        const transferToggled = (transfer.id !== null || transfer.name !== null);

        if (rolePermissions?.canEditTransfer) {
            return (
                <React.Fragment>
                    <QuestionSwitch
                        questionLabel={`Is your student a transfer student?`}
                        uncheckedLabel={'No/Skip'}
                        checkedLabel={'Yes'}
                        checked={transferToggled}
                        onChange={this.onToggleTransfer}
                        switchId={'transfer-toggle'}
                    />
                    {transferToggled &&
                        <div className={Classes.toggleTextGroup}>
                            <div />
                            <MaterialReactAutocomplete
                                // MaterialReactAutocomplete uses style prop and doesn't support className
                                style={{
                                    width: 263,
                                    position: 'relative',
                                    margin: 'auto 0',
                                    padding: '1em 0'
                                }}
                                inputRef={this.specifyTransferTextfield}
                                menuProps={{ className: Classes.autocompleteMenu }}
                                errorStyle={styles.error}
                                handleClearField={() => this.setTransferName('')}
                                label='Transferred from:'
                                placeholder='Type to search...'
                                fullWidth
                                helperText={this.error('transfer')}
                                onChange={this.setTransferId}
                                onTextChange={this.setTransferName}
                                searchText={this.transferText()}
                                value={this.transferId()}
                                onBlur={this.maybeHideErrorIfShown('transfer')}
                                onFocus={this.maybeHideErrorIfShown('transfer', false)}
                                filter={FuzzyFilter}
                                openOnFocus={false}
                                MenuStyle={styles.autocompleteMenu}
                                dataSource={this.props.transfers}
                                dataSourceConfig={{
                                    label: 'name',
                                    id: 'id'
                                }}
                            />
                        </div>}
                </React.Fragment>
            );
        }

        return null;
    }

    renderParentsStudentOnlineField = () => {

        const {
            onlineStudent
        } = this.state;

        const {
            rolePermissions
        } = this.props;

        if (rolePermissions?.canEditOnline) {
            return <QuestionSwitch
                questionLabel={`Is your student an online student?`}
                uncheckedLabel={'No'}
                checkedLabel={'Yes'}
                checked={onlineStudent}
                onChange={this.setToggleFieldValue('onlineStudent')}
                switchId={'online-student-toggle'}
            />;
        }

        return null;
    }

    handleClosePicSpeedDial = () => {

        this.setState({
            picSpeedDialOpen: false
        });
    };

    handleOpenPicSpeedDial = () => {

        this.setState({
            picSpeedDialOpen: true
        });

        setTimeout(() => {

            AnimatedFocusIndicator.wrapFocusIndicatorForElement();
        }, 200);
    };

    renderFields({ fieldsWrapper: FieldsWrapper }) {

        const {
            styles,
            SpinnerContainer,
            ProfileFAB
        } = internals;

        const {
            picPreviewURL,
            croppedPicture,
            firstName,
            lastName,
            preferences
        } = this.state;

        // The list of conditionally displayed fields and whether they should be displayed for this college.
        // Note that we're renaming them slightly to avoid conflicts with existing consts with the same name in State
        const {
            isParent,
            interestsLoading,
            officesLoading,
            yearsHiredLoading,
            preferencesLoading,
            isCompany,
            isCommunity,
            isSchoolOnline,
            rolePermissions
        } = this.props;

        const shouldRenderEthnicityField = this.isSignupPage
            && !isCompany
            && !isCommunity
            && rolePermissions?.includeEthnicity;

        const showCameraBtn = isDesktop(getDevice());

        const picUrl = (picPreviewURL || croppedPicture) ? `url(${picPreviewURL || (croppedPicture ? getOriginalImageUrl(croppedPicture) : '')})` : '';
        const hasNonDefaultProfilePic = (picPreviewURL && picPreviewURL !== DEFAULT_PROFILE_IMG) || (croppedPicture && croppedPicture !== DEFAULT_PROFILE_IMG);
        const hasDefaultProfilePic = picPreviewURL === DEFAULT_PROFILE_IMG && croppedPicture === DEFAULT_PROFILE_IMG;
        const hasProfilePic = hasNonDefaultProfilePic || hasDefaultProfilePic;

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!interestsLoading || !!officesLoading || !!yearsHiredLoading || !!preferencesLoading) {
            return <div style={{ marginTop: 100 }}><Loader /></div>;
        }

        return <FieldsWrapper>
            {!this.isSignupPage && (
                <FormHelperText>
                    {hasNonDefaultProfilePic && (
                        <div>
                            Photo:
                        </div>
                    )}
                    {hasDefaultProfilePic && (
                        <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                            Photo - Please upload a personal image to increase your profile views!
                        </div>
                    )}
                </FormHelperText>
            )}
            {/* Users with roles that don't require a profile picture will have croppedPicture set to default. */}
            {hasDefaultProfilePic && this.isSignupPage && (
                <div style={{ margin: '0 auto', fontSize: '0.7em', textAlign: 'center', color: 'gray', lineHeight: '0.5em' }}>
                    <p>Please upload a personal image.</p>
                    <p>This will increase your profile views and connection requests!</p>
                    <p>Don’t worry if it’s not perfect - you can change it later</p>
                </div>
            )}
            {this.error('picFile') && rolePermissions?.profilePicRequired && (
                <div style={{ width: '12em', margin: '0 auto', marginTop: 10, textAlign: 'center', zIndex: '2' }}>
                    <Typography color='error'>
                        {this.error('picFile')}
                    </Typography>
                    {!this.isSignupPage && <StyledButton
                        color={'secondary'}
                        variant={'contained'}
                        size={'small'}
                        onClick={this.resetSelectedPicFile}
                    >
                        Restore valid picture
                    </StyledButton>}
                </div>
            )}
            <div
                className={Classes.uploadPicWrapper}
                style={{ backgroundImage: picUrl, marginBottom: 20, zIndex: '0' }}
            >
                {rolePermissions?.profilePicRequired && !croppedPicture && !picPreviewURL && (
                    <img
                        src={getOriginalImageUrl(EMPTY_PROFILE_IMG)}
                        alt='Empty profile picture'
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                )}
                {!rolePermissions?.profilePicRequired && !hasProfilePic && (
                    <img
                        src={getOriginalImageUrl(DEFAULT_PROFILE_IMG)}
                        alt='Default profile picture'
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                )}
                {croppedPicture || picPreviewURL ? <div className={Classes.editPicButtonsContainer}>
                    {(this.state.picFile || hasNonDefaultProfilePic) && (
                        <Tooltip arrow={true} title='Resize Current' placement='bottom'>
                            <ProfileFAB
                                color='primary'
                                size='small'
                                aria-label='Resize photo'
                                onClick={() => {

                                    this.editPhoto();
                                    AnimatedFocusIndicator.onBlurHandler();
                                }}
                                data-focus-outline='radius:40,borderSize:3'
                                style={{ zIndex: 1 }}
                            >
                                <Crop />
                            </ProfileFAB>
                        </Tooltip>
                    )}
                    <Tooltip arrow={true} title='Upload New' placement='bottom'>
                        <ProfileFAB
                            color='primary'
                            size='small'
                            aria-label='Upload new photo'
                            onClick={(event) => {

                                this.uploadPhoto(event);
                                AnimatedFocusIndicator.onBlurHandler();
                            }}
                            data-focus-outline='radius:40,borderSize:3'
                            style={{ zIndex: 1 }}
                        >
                            <UploadIcon />
                        </ProfileFAB>
                    </Tooltip>
                    {showCameraBtn && (
                        <Tooltip arrow={true} title='Take Photo' placement='bottom'>
                            <ProfileFAB
                                color='primary'
                                size='small'
                                aria-label='Take photo with camera'
                                onClick={(event) => {

                                    this._capturePhoto();
                                    AnimatedFocusIndicator.onBlurHandler();
                                }}
                                data-focus-outline='radius:40,borderSize:3,zIndex:1'
                            >
                                <CameraIcon />
                            </ProfileFAB>
                        </Tooltip>
                    )}
                </div> : <div className={Classes.editPicButtonsContainer}>
                    <Tooltip arrow={true} title='Upload New' placement='bottom'>
                        <ProfileFAB
                            color='primary'
                            size='small'
                            aria-label='Upload new photo'
                            onClick={(event) => {

                                this.uploadPhoto(event);
                                AnimatedFocusIndicator.onBlurHandler();
                            }}
                            data-focus-outline='radius:40,borderSize:3'
                            style={{ zIndex: 1 }}
                        >
                            <UploadIcon />
                        </ProfileFAB>
                    </Tooltip>
                    {showCameraBtn && (
                        <Tooltip arrow={true} title='Take Photo' placement='bottom'>
                            <ProfileFAB
                                color='primary'
                                size='small'
                                aria-label='Take photo with camera'
                                onClick={(event) => {

                                    this._capturePhoto();
                                    AnimatedFocusIndicator.onBlurHandler();
                                }}
                                data-focus-outline='radius:40,borderSize:3,zIndex:1'
                            >
                                <CameraIcon />
                            </ProfileFAB>
                        </Tooltip>
                    )}
                </div>}

                <input
                    id='upload-profile-photo'
                    type='file'
                    accept='image/*,.heic'
                    aria-label='Upload photo'
                    onChange={this.choosePic}
                    style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                    ref={this.picFile}
                />
            </div>

            <CropAvatarDialog
                open={!!this.state.cropping}
                onClose={this.closeCropping}
                file={this.state.cropping}
                onDone={this.doneCropping}
                contentClassName={Classes.cropAvatarDialog}
            />

            {isParent && <h2 style={styles.sectionHeader}>About you</h2>}
            <div className={Classes.gridFieldset}>
                <TextField
                    errorStyle={styles.error}
                    fullWidth
                    required
                    label='First Name'
                    id={'first-name-input'}
                    classes={{
                        root: Classes.textFieldRoot
                    }}
                    InputLabelProps={{
                        classes: {
                            root: Classes.textFieldInputLabelRoot
                        }
                    }}
                    value={firstName}
                    inputProps={{ maxLength: MAX_FIRST_AND_LAST_NAME_LENGTH }}
                    error={this.state.firstNameErrorShow}
                    aria-invalid={this.hasError('firstName')}
                    helperText={this.error('firstName')}
                    onChange={this.setFieldValue('firstName')}
                    onBlur={this.maybeHideErrorIfShown('firstName')}
                    ref={this.firstName}
                />
                <TextField
                    errorStyle={styles.error}
                    fullWidth
                    classes={{
                        root: Classes.textFieldRoot
                    }}
                    InputLabelProps={{
                        classes: {
                            root: Classes.textFieldInputLabelRoot
                        }
                    }}
                    required
                    label='Last Name'
                    id={'last-name-input'}
                    inputProps={{ maxLength: MAX_FIRST_AND_LAST_NAME_LENGTH }}
                    error={this.state.lastNameErrorShow}
                    helperText={this.error('lastName')}
                    value={lastName}
                    aria-invalid={this.hasError('lastName')}
                    onChange={this.setFieldValue('lastName')}
                    onBlur={this.maybeHideErrorIfShown('lastName')}
                    ref={this.lastName}
                />
                {/* Only show on signup page */}
                {this.isSignupPage && this.renderAgeField()}
                {/* Move this field for parents */}
                {!isParent && this.renderMajorField()}
                {(isCompany || isCommunity || isSchoolOnline) && this.renderHousingField()}
                {this.renderHomeTownField()}
                {this.renderDepartmentField()}
                {this.renderOfficeField()}
                {this.renderYearHiredField()}
                {this.renderCareerField()}
                {this.renderProfessionField()}
                {this.renderRoleField()}
                {this.renderTitleField()}
                {/* Move these fields for parents */}
                {!isParent &&
                    <>
                        {this.renderIncomingClassField()}
                        {this.renderGraduatingClassField()}
                    </>
                }
            </div>
            <div>
                {this.renderBioField()}
            </div>

            {!isCompany && !isCommunity && !isSchoolOnline && this.renderHousingField()}

            {/* Move these fields for parents */}
            {!isParent && this.renderTransferField()}

            {this.renderVeteranField()}
            {this.renderParentField()}
            {this.renderWorkRemoteField()}
            {!isParent && !isSchoolOnline && this.renderOnlineField()}
            {!isParent && this.renderTimeStatusField()}
            {this.renderGenderField()}
            {shouldRenderEthnicityField && this.renderEthnicityField()}

            <UserPreferences
                onSubmit={this.userPreferencesSubmit}
                onInvalidSubmit={this.props.onInvalidSubmit}
                onChange={this.maybeSavePreferencesProgress}
                preferences={preferences}
                ref={this.userPreferences}
                isSignupPage={this.isSignupPage}
                isEditPage={!this.isSignupPage}
                headersToShow={[]}
                fieldsToShow={['phone', 'sms']}
                useWrapper={false}
            />

            {!this.isSignupPage && <div style={{ marginTop: '1.2rem' }}>
                <Link
                    to='/app/profile/edit/interests'
                    data-focus-outline='radius:5'
                    style={{ display: 'block', marginBottom: '1rem' }}
                >
                    Edit My Interests
                </Link>

                <Link
                    to='/app/preferences'
                    data-focus-outline='radius:5'
                    style={{ display: 'block' }}
                >
                    Edit User Preferences
                </Link>
            </div>}


            {/* Add back all the fields we want to rearrange */}
            {isParent && <>
                {this.renderParentsHeader()}
                <div className={Classes.gridFieldset}>
                    {this.renderParentsStudentNameField()}

                    {/* SEPERATE THESE FIELDS FOR PARENTS major.incoming,gradutaing,online,transfer */}
                    {this.renderParentsStudentMajorField()}
                    {this.renderParentsStudentIncomingClassField()}
                    {this.renderParentsStudentGraduatingClassField()}
                </div>

                {this.renderParentsStudentBioField()}
                {this.renderParentsStudentTransferField()}
                {this.renderParentsStudentOnlineField()}
            </>}

            {this.isSignupPage ? <div style={{ marginTop: '2rem' }}>
                <Divider style={styles.divider} />
                <h2
                    className={Classes.subheading}
                    aria-label='Add Interests & Passions. At least 1 interest is required.'
                >
                    Add Interests & Passions
                </h2>
                {this.renderInterestsField()}
            </div> : null}

            <StyledBackdrop open={this.state.submitting || this.state.uploadingProfilePic || this.state.convertingProfilePic}>
                <SpinnerContainer >
                    <Loader />
                </SpinnerContainer>
            </StyledBackdrop>
        </FieldsWrapper>;
    }
};

internals.getAge = (birthdate) => {

    return Moment().diff(birthdate, 'years');
};

internals.SpinnerContainer = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-contents: center;
    border-radius:16px;
    background-color:rgba(255,255,255,0.75);
    height: 10rem;
    width: 10rem;
`;

internals.IconButton = Styled(MuiIconButton)`
    padding: 4px !important;
`;

internals.styles = {
    progressBar: {
        marginTop: 20
    },
    radioBtn: {
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    fullwidth: {
        position: 'relative',
        width: '100%'
    },
    error: {
        position: 'absolute',
        bottom: '-.75em'
    },
    errorHalf: {
        position: 'absolute',
        bottom: '-.75em',
        right: 0,
        whiteSpace: 'nowrap'
    },
    radioError: {
        fontSize: '12px',
        color: '#A7160C',
        margin: '12px 0'
    },
    formSubheader: {
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.65)',
        marginTop: '17px',
        marginBottom: '10px'
    },
    genderContainer: {
        position: 'relative'
    },
    ethnicityContainer: {
        position: 'relative'
    },
    radioGroupError: {
        paddingBottom: '10px',
        color: '#A7160C',
        position: 'relative',
        fontSize: '12px',
        lineHeight: '12px'
    },
    divider: {
        marginTop: '8px',
        marginBottom: '0'
    },
    userPreferencesDivider: {
        marginTop: '8px',
        marginBottom: '25px'
    },
    userPreferencesDividerWithError: {
        marginTop: '22px',
        marginBottom: '25px'
    },
    dividerError: {
        backgroundColor: '#A7160C'
    },
    specifyGenderTextfield: {
        width: 'calc(100% - 150px)',
        maxWidth: '200px',
        position: 'absolute',
        bottom: '-6px',
        left: '150px',
        zIndex: '2'
    },
    specifyEthnicityTextfield: {
        width: 'calc(100% - 150px)',
        maxWidth: '200px',
        position: 'absolute',
        bottom: '-6px',
        left: '150px',
        zIndex: '2'
    },
    autocompleteMenu: {
        maxHeight: 220
    },
    sectionHeader: {
        fontSize: 24,
        fontWeight: 'bold'
    }
};

internals.applyPropDefault = (prop, defaultVal) => {

    if (typeof prop === 'undefined' || prop === null) {
        return defaultVal;
    }

    return prop;
};

internals.ProfileFAB = Styled(FAB)`
    width: 36px;
    height: 36px;

    svg {
        fill: #ffffff;
        width: 19px;
        height: 19px;
    }
`;

internals.BioTextField = Styled(TextField)`
    .MuiFormLabel-root {
        line-height: 35px;

        &.MuiInputLabel-shrink {
            line-height: 23px;
        }
    }

    margin-bottom: 12px;
`;
