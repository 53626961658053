const { DEBUG_PUSH_NOTIFICATIONS } = require('utils/constants');

const { replace: Replace } = require('connected-react-router');

exports.pushNotifPermsLastAskedKey = 'pushNotifPermissionLastAsked';

exports.currentFirebaseTokenKey = 'currentRegistrationIdFirebase';

exports.backgroundFirebaseTokenKey = 'newRegistrationId';

exports.PUSH_NOTIFICATION_CHECK_DAYS_INTERVAL = 5;

exports.isCordovaRunning = () => !!window.cordova;

exports.persistSet = (key, value) => localStorage.setItem(key, value);

exports.persistGet = (key) => localStorage.getItem(key);

exports.persistRemove = (key) => localStorage.removeItem(key);

exports.persistValIsSet = (val) => typeof val !== 'undefined' && val !== null && val !== 'null';

exports.isAndroid = () => window.device.platform?.toLowerCase() === 'android';

exports.onDeviceReady = async () => {

    console.log('CORDOVA DEVICE READY');

    const { context } = require('app-context');

    const token = exports.persistGet(exports.currentFirebaseTokenKey);

    await exports.requestPushNotifPermissions();

    // if we have a twilio endpoint from a past session, get it into app state
    if (exports.persistValIsSet(token)) {
        const redux = context.redux.hooks;
        const actions = context.actions;

        redux.dispatch(actions.app.setFirebaseToken({ token }));
    }

    exports.registerPushNotifications();
};

exports.getBindingType = () => {

    return exports.isAndroid() ? 'fcm' : 'apn';
};

// The app runs this when the user either backgrounds OR closes the app.
exports.onPause = () => {

    console.log('CORDOVA PAUSE');

    const { context } = require('app-context');

    const twilio = context.api.twilio;
    const handlers = context.handlers;
    const redux = context.redux.hooks;
    const actions = context.actions;

    redux.dispatch(actions.app.setAppInBackground({ inBackground: true }));

    twilio.shutdown();
    handlers.communication.stopGlobalListening();
};

exports.getIsAuthenticated = () => {

    const { context } = require('app-context');

    const selectors = context.selectors.all;
    const redux = context.redux.hooks;

    return selectors.getIsAuthenticated(redux.getState());
};

exports.resumeApp = async () => {

    const { context } = require('app-context');

    const twilio = context.api.twilio;
    const handlers = context.handlers;
    const selectors = context.selectors.all;
    const redux = context.redux.hooks;
    const actions = context.actions;

    // Avoids running this twice.
    const isInBackground = selectors.getIsAppInBackground(redux.getState());
    // console.log('zlog isInBackground', isInBackground);

    if (isInBackground)  {
        redux.dispatch(actions.app.setAppInBackground({ inBackground: false }));
    }

    if (exports.getIsAuthenticated() && isInBackground) {
        // Will check at most every 5 days if user has notif permissions granted, and ask if not.
        // NOTE: onlyRegisterIfPermsNewlyGranted is not used right now.
        // TODO make use of that.
        exports.fetchAndSavePushNotifToken({ onlyRegisterIfPermsNewlyGranted: true });

        // Refetch entities. Do not await before resuming app for the user.
        redux.dispatch(actions.dataFetching.fetchAppEntities({
            entities: [
                'schools',
                'categories',
                'interests',
                'roleGroups',
                'classes'
            ]
        }));

        console.log('REFRESHING TOKEN, INIT TWILIO');
        await twilio.initialize();

        await handlers.communication.startGlobalListening();
    }
};

exports.onResume = async () => {

    console.log('CORDOVA ON_RESUME');

    return await exports.resumeApp();
};

exports.isResumeException = (pathname) => {

    if (exports.isAndroid()) {
        // We added ability to change your profile pic when viewing your own profile.
        // This regex is to cover, e.g. /app/profile/123456
        const userProfile = /^\/app\/profile\/\d+$/;
        if (pathname === '/app/profile/edit' || userProfile.test(pathname)) {
            return true;
        }
    }

    return false;
};

exports.onPushRegistration = (token, { force, wasRefreshed } = {}) => {

    if (!token) {
        if (DEBUG_PUSH_NOTIFICATIONS) {
            console.log('No token, user has turned off notification permissions.');
        }

        return;
    }

    if (exports.getIsAuthenticated()) {
        // if the user is already logged in, update twilio binding right away
        exports.updateFirebaseToken(token, { force, wasRefreshed });
    }
    else {
        // if the user is not logged in, store the registration id for use after the user logs in
        exports.persistSet(exports.backgroundFirebaseTokenKey, token);
    }
};

exports.registerPushNotifications = () => {

    const { firebasePlugin } = exports;
    const { analyticsTemplates } = require('utils/analytics');

    try {
        if (!firebasePlugin.supported()) {
            if (DEBUG_PUSH_NOTIFICATIONS) {
                console.error('Firebase Plugin is not available.');
            }

            return;
        }

        // Will check at most every 5 days if user has notif permissions granted, and ask if not.
        exports.fetchAndSavePushNotifToken();

        // Listen for token refresh
        firebasePlugin.onTokenRefresh(async (newToken) => {

            if (DEBUG_PUSH_NOTIFICATIONS) {
                console.log('FCM Token Refreshed:', newToken);
            }

            await exports.onPushRegistration(newToken, { wasRefreshed: true });
        });

        // Handle push notifications received
        firebasePlugin.onMessageReceived(exports.onPushReceive);
    }
    catch (error) {

        if (DEBUG_PUSH_NOTIFICATIONS) {
            console.error('Error in registerPushNotifications:', error);
        }

        analyticsTemplates.pushNotifs({
            action: 'register',
            label: 'error',
            value: error.message
        });
    }
};

exports.requestPushNotifPermissions = async () => {

    const { firebasePlugin } = exports;
    const { analyticsTemplates } = require('utils/analytics');

    let permsWereGranted = false;

    // Will check at most every 5 days if user has notif permissions granted, and ask if not.
    const hasPermission = await firebasePlugin.hasPermission();

    if (!hasPermission) {
        // Ask for permission to allow notifications.
        const granted = await firebasePlugin.requestGrantPermission();

        if (granted) {
            permsWereGranted = true;

            analyticsTemplates.pushNotifs({
                action: 'permission_request',
                label: 'success',
                value: 'newly_granted'
            });
        }
        else {
            analyticsTemplates.pushNotifs({
                action: 'permission_request',
                label: 'fail',
                value: 'newly_denied'
            });
        }
    }
    else {
        analyticsTemplates.pushNotifs({
            action: 'permission_request',
            label: 'success',
            value: 'maintained_granted'
        });
    }

    return { permsWereGranted };
};

exports.fetchAndSavePushNotifToken = async ({ force } = {}) => {

    // Get FCM token
    const token = await exports.firebasePlugin.getToken();

    if (DEBUG_PUSH_NOTIFICATIONS) {
        console.log('FCM Token:', token);
    }

    exports.onPushRegistration(token, { force });

    return token;
};

exports.onPushReceive = (data) => {

    const { context } = require('app-context');
    const { analyticsTemplates } = require('utils/analytics');

    if (DEBUG_PUSH_NOTIFICATIONS) {
        console.log('onPushReceive: ' + JSON.stringify(data, null, 4));

        // onPushReceive: {
        //     "google.delivered_priority": "high",
        //     "google.sent_time": 1741116180335,
        //     "google.ttl": 2419200,
        //     "google.original_priority": "high",
        //     "messageType": "notification",
        //     "google.product_id": 111881503,
        //     "id": "14033",
        //     "tap": "background",
        //     "body": "asdfasdf",
        //     "from": "9061917975",
        //     "type": "announcement",
        //     "title": "New Announcement",
        //     "google.message_id": "0:1741116180342312%f5566d6af5566d6a",
        //     "gcm.n.analytics_data": "Bundle[mParcelledData.dataSize=96]",
        //     "collapse_key": "net.nearpeer.app_staging"
        // }
    }

    // firebasex cordova plugin will set 'tap' to 'background'
    // if the user tapped a notification and the app was backgrounded.
    if (data.tap !== 'background') {
        analyticsTemplates.pushNotifs({
            action: 'received_notification',
            label: 'foreground',
            value: `type: ${data.type}, body: ${data.body}`
        });

        return;
    }

    const redux = context.redux.hooks;
    const actions = context.actions;
    let redirectTo = null;

    switch (data.type) {
        case 'link':
            redirectTo = data.link;
            // console.log(`zlog link set success redirect path: ${redirectTo}`);
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: redirectTo }));
            redux.dispatch(Replace(redirectTo));
            break;

        case 'dm':
            redirectTo = `/app/messaging/${data.channelSid}`;
            // console.log(`zlog dm set success redirect path: ${redirectTo}`);
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: redirectTo }));
            redux.dispatch(Replace(redirectTo));
            break;

        case 'mention':
        case 'group-msg':
            redirectTo = `/app/classes/chat/${data.channelSid}`;
            // console.log(`zlog mention, group-msg set success redirect path: ${redirectTo}`);
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: redirectTo }));
            redux.dispatch(Replace(redirectTo));
            break;

        case 'survey':
            redirectTo = '/app/surveys/my-surveys';
            // console.log(`zlog survey set success redirect path: ${redirectTo}`);
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: redirectTo }));
            redux.dispatch(Replace(redirectTo));
            break;

        case 'peer':
            if (data.peerId) {
                redirectTo = `/app/profile/${data.peerId}`;
            }
            else {
                redirectTo = '/app/messaging';
            }

            // console.log(`zlog peer set success redirect path: ${redirectTo}`);
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: redirectTo }));
            redux.dispatch(Replace(redirectTo));

            break;

        case 'announcement':
            redirectTo = '/app/welcome';
            // console.log(`zlog announcement set success redirect path: ${redirectTo}`);
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: redirectTo }));
            redux.dispatch(Replace(redirectTo));

            break;
    }

    if (redirectTo) {
        analyticsTemplates.pushNotifs({
            action: 'received_notification',
            label: 'tapped_notif',
            value: `type: ${data.type}, redirectedTo: ${redirectTo}, body: ${data.body}`
        });
    }
    else {
        analyticsTemplates.pushNotifs({
            action: 'received_notification',
            label: 'tapped_notif',
            value: `type: ${data.type}, body: ${data.body}`
        });
    }
};

exports.updateFirebaseToken = async (registrationId, { force, wasRefreshed } = {}) => {

    const { context } = require('app-context');
    const { analyticsTemplates } = require('utils/analytics');

    const redux = context.redux.hooks;
    const actions = context.actions;
    const api = context.api.nearpeer;

    const bindingType = exports.getBindingType();
    const currentRegistrationId = exports.persistGet(exports.currentFirebaseTokenKey);
    const newRegistrationId = registrationId || exports.persistGet(exports.backgroundFirebaseTokenKey);

    if (newRegistrationId && (newRegistrationId === currentRegistrationId && !force)) {
        exports.persistRemove(exports.backgroundFirebaseTokenKey);
        return;
    }

    // Problem is iOS keeps reporting newRegistrationId as null
    if (!newRegistrationId) {
        if (DEBUG_PUSH_NOTIFICATIONS) {
            // This was inspo for utils/remote-debug.js
            console.log('newRegistrationId was falsy', newRegistrationId);
        }

        return;
    }

    if (DEBUG_PUSH_NOTIFICATIONS) {
        console.log(`updateFirebaseToken: bindingType: ${bindingType} newRegistrationId: ${newRegistrationId} currentRegistrationId: ${currentRegistrationId} registrationId: ${registrationId}`);
    }

    await api.authentication.storeFirebaseToken({ bindingType, token: newRegistrationId });

    redux.dispatch(actions.app.setFirebaseToken({ token: newRegistrationId }));

    exports.persistRemove(exports.backgroundFirebaseTokenKey);
    exports.persistSet(exports.currentFirebaseTokenKey, newRegistrationId);

    analyticsTemplates.pushNotifs({
        action: wasRefreshed ? 'token_refreshed' : 'token_updated',
        label: 'success',
        value: newRegistrationId
    });
};

// firebasex plugin
exports.firebasePlugin = {
    supported: () => !!window.FirebasePlugin,
    setUserId: (userId) => window.FirebasePlugin?.setUserId(userId),
    logEvent: (type, data) => window.FirebasePlugin?.logEvent(type, data),
    getToken: () => {

        return new Promise((resolve, reject) => {

            window.FirebasePlugin?.getToken(resolve, reject);
        });
    },
    onTokenRefresh: (callback) => {

        window.FirebasePlugin?.onTokenRefresh(callback);
    },
    hasPermission: () => {

        return new Promise((resolve) => {

            // console.log('zlog firebasePlugin hasPermission');
            window.FirebasePlugin?.hasPermission(resolve);
        });
    },
    requestGrantPermission: () => {

        return new Promise((resolve) => {

            // console.log('zlog firebasePlugin requestGrantPermission');
            window.FirebasePlugin?.grantPermission(resolve);
        });
    },
    onMessageReceived: (callback) => {

        window.FirebasePlugin?.onMessageReceived(callback);
    }
};

// TODO figure out if we want to use this.
// exports.shouldRequestPushNotificationPermissions = () => {

//     const savedDateStr = exports.persistGet(exports.pushNotifPermsLastAskedKey);

//     if (!savedDateStr) {
//         return true; // No date saved, assume time has passed
//     }

//     const savedDate = new Date(savedDateStr);
//     const currentDate = new Date();

//     const differenceInMs = currentDate - savedDate;
//     const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24); // Convert ms to days

//     const shouldCheckNotifPermissions = differenceInDays >= exports.PUSH_NOTIFICATION_CHECK_DAYS_INTERVAL;

//     if (shouldCheckNotifPermissions) {
//         exports.persistSet(exports.pushNotifPermsLastAskedKey, new Date());
//         console.log('Set push notif perms last asked date to: ' + exports.persistGet(exports.pushNotifPermsLastAskedKey));
//     }
//     else {
//         console.log('No-op on asking for push notif perms');
//     }

//     return shouldCheckNotifPermissions;
// };
