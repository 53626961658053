const { ASYNC, SIMPLE } = require('../utils/redux-helpers');

module.exports = {
    FETCH_APP_ENTITIES: ASYNC,
    FETCH_SCHOOL: ASYNC,
    FETCH_SCHOOL_BY_TOKEN: ASYNC,
    FETCH_SCHOOLS: ASYNC,
    FETCH_INTERESTS: ASYNC,
    FETCH_BADGE_TYPES: ASYNC,
    FETCH_CONVERSATION_STARTERS: ASYNC,
    FETCH_YEARS_HIRED: ASYNC,
    FETCH_OFFICES: ASYNC,
    FETCH_ROLE_GROUPS: ASYNC,
    FETCH_ROLES: ASYNC,
    FETCH_CATEGORIES: ASYNC,
    FETCH_USER_PREFERENCES: ASYNC,
    FETCH_USERS: ASYNC,
    FETCH_USER: ASYNC,
    FETCH_USER_SEARCH_NOTIFY_RESULTS: ASYNC,
    FETCH_USER_SEARCH_SURVEY_RESULTS: ASYNC,
    FETCH_USER_SEARCH_RESULTS: {
        FIRST_PAGE: ASYNC,
        NEXT_PAGE: ASYNC
    },
    FETCH_CLASS_USER_SEARCH_RESULTS: {
        FIRST_PAGE: ASYNC,
        NEXT_PAGE: ASYNC,
        MENTIONS: ASYNC
    },
    FETCH_CURRENT_USER: ASYNC,
    FETCH_CHANNEL: ASYNC,
    FETCH_GROUP_CONVERSATION: ASYNC,
    FETCH_LOCAL_CONVERSATIONS: ASYNC,
    FETCH_GROUP_CONVERSATION_MESSAGES: ASYNC,
    FETCH_CHANNELS: ASYNC,
    FETCH_UNREAD_MESSAGE_COUNTS: ASYNC,
    FETCH_ALL_MESSAGE_COUNTS: ASYNC,
    FETCH_UNREAD_LOCAL_MESSAGE_COUNTS: ASYNC,
    FETCH_ALL_LOCAL_MESSAGE_COUNTS: ASYNC,
    FETCH_LOCAL_CONVERSATIONS_UPDATE_DATES: ASYNC,
    FETCH_CHANNEL_UPDATE_DATES: ASYNC,
    FETCH_CHANNEL_LAST_MESSAGE: ASYNC,
    FETCH_MESSAGES: {
        FIRST_PAGE: ASYNC,
        NEXT_PAGE: ASYNC,
        HAS_PREV_PAGE: ASYNC
    },
    SUBSCRIBE_MESSAGES: {
        UPDATE: SIMPLE,
        REMOVE: SIMPLE
    },
    FETCH_NOTIFICATIONS: ASYNC,
    FETCH_CLASS: ASYNC,
    FETCH_CLASSES: ASYNC,
    FETCH_SURVEYS: ASYNC,
    FETCH_SURVEY: ASYNC,
    RESET_SURVEY_QUESTIONS: SIMPLE,
    FETCH_CLASS_SEARCH_RESULTS: ASYNC,
    GET_SCHEDULED_NOTIFICATIONS: ASYNC
};
