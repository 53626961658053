const { context } = require('../app-context');

module.exports = async (store) => {

    const {
        actions
    } = context;

    await store.dispatch(actions.dataFetching.fetchAppEntities({
        entities: [
            'schools',
            'categories',
            'roleGroups'
        ]
    }));
};
