const React = require('react');
const { default: Styled } = require('styled-components');
const T = require('prop-types');
const Debounce = require('lodash/debounce');

const { default: MuiBadge } = require('@mui/material/Badge');
const { default: Button } = require('@mui/material/Button');
const { default: FAB } = require('@mui/material/Fab');
const { default: AddIcon } = require('@mui/icons-material/Add');
const { default: ChatIcon } = require('@mui/icons-material/Chat');
const { default: TextField } = require('@mui/material/TextField');
const { default: InputAdornment } = require('@mui/material/InputAdornment');
const { default:List } = require('@mui/material/List');
const { default:ListItem } = require('@mui/material/ListItem');
const { default:ListItemText } = require('@mui/material/ListItemText');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const FullScreenDialog = require('containers/FullScreenDialog');
const ClassListItem = require('../ClassListItem');
const { default: Classes } = require('./styles.scss');
const MuiColors = require('@mui/material/colors');
const { NavLink: Link } = require('react-router-dom');
const UnreadMessagesBadge = require('../../../../../routes/app/components/UnreadMessagesBadge');
const Loader = require('components/Loader');

const internals = {};

module.exports = class ClassSearchDialog extends React.Component {

    static propTypes = {
        onRequestClose: T.func,
        onRequestSearch: T.func,
        onClickAddClass: T.func,
        fetchClasses: T.func,
        classes: T.arrayOf(T.shape({ id: T.any.isRequired })),
        searchResults: T.arrayOf(T.shape({ id: T.any.isRequired })),
        rolePermissions : T.shape({
            id:T.number,
            roleId:T.number,
            schoolId:T.number,
            canSeeGroupStatus:T.bool

        }),
        showJoin: T.func,
        push: T.func
    }

    constructor(props) {

        super(props);

        this.state = {
            term: '',
            wide: null,
            maybeShowNotFound: false
        };

        // Add a mobile breakpoint ("media query listener") for use with inline styles
        this.mql = window.matchMedia('screen and (min-width: 600px)');

        this.handleMediaQuery = this._handleMediaQuery.bind(this);
        this.updateSearch = this._updateSearch.bind(this);
        this.onClickAddClass = this._onClickAddClass.bind(this);
        this.requestSearch = Debounce(props.onRequestSearch, 200);
        this.debounceMaybeShowNotFound = Debounce(this._debounceMaybeShowNotFound.bind(this), 1400);
    }

    componentDidMount() {

        this.props.fetchClasses();
    }

    UNSAFE_componentWillMount() {

        this.handleMediaQuery(this.mql);

        this.mql.addListener(this.handleMediaQuery);
    }

    componentWillUnmount() {

        this.mql.removeListener(this.handleMediaQuery);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        // Replace debounced search request
        if (this.props.onRequestSearch !== nextProps.onRequestSearch) {
            this.requestSearch.cancel();
            this.requestSearch = Debounce(nextProps.onRequestSearch, 200);
            this.requestSearch({ term: this.state.term, showAll: false });
        }
    }

    _handleMediaQuery(mql) {

        this.setState({ wide: !!mql.matches });
    }

    _updateSearch(event) {

        const term = event.target.value;

        this.setState({
            term,
            maybeShowNotFound: false
        });

        this.requestSearch({ term, showAll: false });

        this.debounceMaybeShowNotFound();
    }

    _debounceMaybeShowNotFound() {

        this.setState({ maybeShowNotFound: true });
    }

    _onClickAddClass(class_) {

        this.props.onClickAddClass(class_);
        this.props.onRequestClose();
    }

    render() {

        const {
            searchResults,
            classes,
            rolePermissions,
            showJoin,
            push
        } = this.props;

        const { wide, term, maybeShowNotFound } = this.state;

        const {
            styles,
            Badge,
            StyledChatButton,
            StyledTextField,
            ListWrapper
        } = internals;

        const { canSeeGroupStatus } = rolePermissions;

        const showNoGroupsFound = maybeShowNotFound
            && searchResults
            && !searchResults.length
            && !!term;

        const middleActionButton = (class_) => {

            return wide ? <Button
                color={'secondary'}
                variant={'contained'}
                style={styles.joinButton}
                className={Classes.joinButton}
                label='Join'
                aria-label={`Join group ${class_.name}`}
                data-focus-outline='radius:40,padding:3,zIndex:9999'
            >
                Join
            </Button> : <FAB
                color={'secondary'}
                mini
                className={Classes.joinFab}
                style={{ ...styles.joinFab }}
                aria-label={`Join group ${class_.name}`}
                data-focus-outline='radius:40,padding:3,zIndex:9999'
            >
                <AddIcon style={{ color:'#ffffff' }} />
            </FAB>;
        };

        const returnChatButton = (class_) => {

            return wide ? <StyledChatButton
                component={Link}
                to={class_.isAnnouncement ? `/app/classes/announcement/chat/${class_.id}` : `/app/classes/chat/${class_.sid}`}
                color={'secondary'}
                variant={'contained'}
                style={styles.joinButton}
                className={Classes.joinButton}
                startIcon={(class_.channel && class_.channel.numUnreads && class_.channel.numUnreads > 0) ? <Badge badgeContent={class_.channel.numUnreads} /> : null}
            >
                <span>Chat</span>
            </StyledChatButton> : <FAB
                component={Link}
                to={class_.isAnnouncement ? `/app/classes/announcement/chat/${class_.id}` : `/app/classes/chat/${class_.sid}`}
                mini
                className={Classes.joinFab}
                style={{
                    ...styles.joinFab,
                    backgroundColor: '#1D0050',
                    color: '#ffffff'
                }}
            >
                <UnreadMessagesBadge
                    mini
                    numUnreads={class_.channel && class_.channel.numUnreads}
                    className={Classes.miniUnreadMessagesBadge}
                />
                <ChatIcon style={{ color:'#ffffff' }} />
            </FAB>;
        };

        return <FullScreenDialog
            {...this.props}
            className={Classes.wrapper}
            title={<span className={Classes.title}>Join Groups</span>}
            classes={{ root: Classes.fullScreenDialogRoot }}
            fixedToTop={
                <div>
                    <StyledTextField
                        id='my-classes-search'
                        fullWidth
                        variant='outlined'
                        placeholder='Type to search groups...'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        value={term}
                        onChange={this.updateSearch}
                    />
                </div>
            }
        >
            <ListWrapper role='list'>
                {showNoGroupsFound &&
                    (
                        <h3 style={{ marginTop: 56 }}>No groups found.</h3>
                    )
                }
                {(!term && classes && !classes.length) &&
                    (
                        <h3 style={{ marginTop: 56 }}>
                            {'You’ve already joined all the available groups!'}
                        </h3>
                    )
                }
                <div>
                    {!term && classes &&
                        <div role='list'>
                            <List>
                                {classes.map((group) => {

                                    return (
                                        <ClassListItem
                                            key={group.id}
                                            displayDetails
                                            showPrivate={canSeeGroupStatus}
                                            aria-label={`Group ${group.name}`}
                                            announcementDescInTitle
                                            titleFontSize={17}
                                            group={group}
                                            titleClass={Classes.titleClass}
                                            subtitleClass={Classes.subtitleClass}
                                            action={showJoin(group.id) ? this.onClickAddClass : () => null}
                                            middleActionButton={showJoin(group.id) ? middleActionButton(group) : returnChatButton(group)}
                                            link={`/app/classes/${group.id}`}
                                            push={push}
                                        />
                                    );
                                })}
                            </List>
                        </div>
                    }
                </div>
                {!showNoGroupsFound && !!term && (!searchResults || !searchResults.length) && <div>
                    <Loader />
                </div>}
                <div>
                    {!!term && searchResults &&
                        <div>
                            <div role='status' className='visually-hidden'>
                                {searchResults.length} results returned.
                            </div>
                            <div role='list'>
                                <List>
                                    {searchResults.map((group) => {

                                        return (
                                            <ClassListItem
                                                displayDetails
                                                showPrivate={canSeeGroupStatus}
                                                key={group.id}
                                                titleFontSize={17}
                                                group={group}
                                                link={`/app/classes/${group.id}`}
                                                titleClass={Classes.titleClass}
                                                subtitleClass={Classes.subtitleClass}
                                                action={showJoin(group.id) ? this.onClickAddClass : () => null}
                                                middleActionButton={showJoin(group.id) ? middleActionButton(group) : returnChatButton(group)}
                                            />
                                        );
                                    })}
                                </List>
                            </div>
                        </div>
                    }
                </div>
            </ListWrapper>
        </FullScreenDialog>;
    }
};

internals.Badge = Styled(MuiBadge)`
    margin: 0 9px;
    .MuiBadge-badge {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
`;

internals.StyledChatButton = Styled(Button)`
  && {
    display: flex;
  }
`;

internals.StyledTextField = Styled(TextField)`
  && {
    margin-top: 0.5rem;

    padding: 20px 50px;

    .MuiInputBase-root {
        height: 100%;
    }

    .MuiInput-input {
      text-align: center;
    }
  }
`;

internals.styles = {
    classLink: {
        textDecoration: 'none',
        display: 'inline-block',
        width: '100%',
        color:'inherit'
    },
    search: {
        textAlign: 'center'
    },
    searchHint: {
        width: '100%',
        textAlign: 'center'
    },
    searchHintContent: {
        height: 18,
        width: 18,
        color: MuiColors.grey['400'],
        verticalAlign: 'text-bottom'
    },
    joinButton: {
        height: 36,
        width: 'auto',
        margin: 0
    },
    joinFab: {
        height: '40px',
        width: '40px',
        minWidth: '40px',
        margin: 0,
        borderRadius:'50%',
        display: 'flex'
    }
};

internals.ListWrapper = Styled.div`
    ul,
    li,
    h3 {
        margin-top: 0;
    }

    h3 {
        width: 100%;
        text-align: center;
    }

    margin-top: -20px;
`;
