const T = require('prop-types');
const SearchResult = require('containers/SearchResult');
const { default: Styled } = require('styled-components');
const { default: ImageList } = require('@mui/material/ImageList');
const { useMediaQuery } = require('@mui/material');

const { default: Classes } = require('./styles.scss');
const { default: styled } = require('styled-components');

const internals = {};

const SearchResultGrid = ({ results, showConnectionStatus, showDistance }) => {

    const {
        ResultWrapperGridContainer,
        StyledImageList
    } = internals;

    const mql = useMediaQuery('screen and (min-width: 600px), screen and (max-width: 415px)');
    const mqlMobile = useMediaQuery('(max-width: 600px)');

    return (
        <ResultWrapperGridContainer>
            <StyledImageList
                length={results.length}
                classes={{
                    root: results.length > 1 && !mqlMobile && Classes.center
                }}
                rowHeight={200}
                cols={mqlMobile ? 1 : 2}
                className={Classes.imageList}
            >
                {results.map((result) => (

                    <SearchResult
                        key={result.id}
                        result={result}
                        numOfResults={results.length}
                        useIconButton={mql}
                        showConnectionStatus={showConnectionStatus}
                        showDistance={showDistance}
                    />
                ))}
            </StyledImageList>
        </ResultWrapperGridContainer>
    );
};

SearchResultGrid.propTypes = {
    results: T.array,
    showConnectionStatus: T.bool,
    showDistance: T.bool
};

module.exports = SearchResultGrid;

internals.ResultWrapperGridContainer = Styled.div`
    overflow: hidden;
    padding: 0.5em 2em;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 960px) {
        justify-content: center;
    }

    @media screen and (max-width: 600px) {
        padding: 8px 22px;

        li {
            margin: 0 12px;
            margin-bottom: 12px;
        }
    }
`;

internals.StyledImageList = styled(ImageList)(({ theme }) => ({
    overflow: 'hidden',
    [theme.breakpoints.between(theme.breakpoints.values.xs, theme.breakpoints.values.md)]: {
        justifyContent: 'center',
        width: '100%'
    }
}));
