const React = require('react');
const { useState, useEffect, useRef } = require('react');
const T = require('prop-types');
const Moment = require('moment');
const { useFieldArray, Controller } = require('react-hook-form');
const { ErrorMessage } = require('@hookform/error-message');
const { default: Grid } = require('@mui/material/Grid');
const { default: TextField } = require('@mui/material/TextField');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: Button } = require('@mui/material/Button');
const { default: Typography } = require('@mui/material/Typography');
const { default: DeleteIcon } = require('@mui/icons-material/Delete');
const { default: AddIcon } = require('@mui/icons-material/Add');
const { default: styled } = require('styled-components');
const { useTheme } = require('@mui/material/styles');
const _capitalize = require('lodash/capitalize');
const { default: Paper } = require('@mui/material/Paper');
const { default: Classes } = require('./styles.scss');
const { default: Chip } = require('@mui/material/Chip');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: SelectField } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: Autocomplete } = require('@mui/material/Autocomplete');
const { default: Collapse } = require('@mui/material/Collapse');
const { default: ExpandLess } = require('@mui/icons-material/ExpandLess');
const { default: ExpandMore } = require('@mui/icons-material/ExpandMore');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: useMediaQuery } = require('@mui/material/useMediaQuery');
const { default: ListSubheader } = require('@mui/material/ListSubheader');
const { VariableSizeList } = require('react-window');
const { default: UploadedFileIcon } = require('@mui/icons-material/Attachment');
const { default: FAB } = require('@mui/material/Fab');
const { default: UploadIcon } = require('@mui/icons-material/Publish');
const CamelCase = require('lodash/camelCase');
const { default: Modal } = require('@mui/material/Modal');
const { default: Box } = require('@mui/material/Box');
const SurveyUsersFilterDialog = require('containers/SurveyUsersFilterDialog');


const StyledFormControl = styled(FormControl)`
    width: 100%;
    margin: 0.2em 0em;
`;

const StyledChip = styled(Chip)`
    && {
        height: 40px;
        padding: 5px;

        &:focus {
            outline: auto 2px -webkit-focus-ring-color;
        }
    }

    .MuiChip-avatar {
        height: 36px !important;
        width: 36px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;

        & svg {
            width: 100%;
            height: auto;
        }
    }
`;

const UserChip = (props) => {

    const {
        user
    } = props;

    const imgUrl = (user && user.croppedPicture) ? user.croppedPicture : null;

    return (
        <StyledChip
            avatar={imgUrl && <Avatar alt={`${user.firstName} img`} src={imgUrl} srcSet={imgUrl} />}
            label={`${user.firstName} ${user.lastName}`}
            {...props}
            tabIndex={0}
            aria-label={`User ${user.firstName} ${user.lastName}`}
        />
    );
};

UserChip.propTypes = {
    user: T.object
};

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {

    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING
        }
    });
};

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef((props, ref) => {

    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {

    const ref = React.useRef(null);
    React.useEffect(() => {

        if (ref.current !== null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
};

// Adapter for react-window
// eslint-disable-next-line react/display-name
const ListboxComponent = React.forwardRef((props, ref) => {

    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {

        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {

        if (itemCount > 8) {
            return 8 * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: T.node
};

const AnswersFields = ({ questionIndex, control, errors, watch, isEdit }) => {

    const { fields, remove, append } = useFieldArray({
        control,
        keyName: 'answerId',
        name: `questions[${questionIndex}].answers`
    });

    const questionAnswers = watch(`questions[${questionIndex}].answers`);

    //TODO add manually set if is active
    //ASSIGN USERS
    return (
        <div style={{ marginLeft: 20 }}>
            <Grid container={true} spacing={2} >
                <Grid item xs={12}>
                    {fields.map((item, index) => {

                        return (
                            <Grid key={`answers:${questionIndex}:${index}`} container={true} spacing={1} justifyContent={'space-between'}>
                                <Grid item xs={9} key={item.id} >
                                    {isEdit ? <Controller
                                        name={`questions[${questionIndex}].answers[${index}].id`}
                                        defaultValue={questionAnswers && questionAnswers[index] ? questionAnswers[index].id : ''}
                                        control={control}
                                        render={(field) => {

                                            return <input type="hidden" {...field} />;
                                        }}
                                    /> : null}
                                    <Controller
                                        name={`questions[${questionIndex}].answers[${index}].text`}
                                        /* eslint-disable-next-line react/prop-types */
                                        defaultValue={questionAnswers && questionAnswers[index] ? questionAnswers[index].text : ''}
                                        control={control}
                                        render={(field, state) => {

                                            return <TextField
                                                {...field}
                                                error={state.invalid}
                                                fullWidth={true}
                                                label={`Answer option No.${index + 1}`}
                                                placeholder={'Enter answer text'}
                                            />;
                                        }}
                                        rules={{
                                            required: 'Answer text is required.'
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`questions[${questionIndex}].answers[${index}].text`}
                                        render={({ message }) => (

                                            <Typography sx={{
                                                color: 'error.main'
                                            }} >{message}</Typography>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs sx={{
                                    textAlign: 'center'
                                }}>
                                    <IconButton onClick={() => remove(index)} size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <ErrorMessage
                        errors={errors}
                        name={`questions[${questionIndex}].answers`}
                        render={({ message }) => (

                            <Typography sx={{
                                color: 'error.main'
                            }}>{message}</Typography>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {

                            append({
                                id: '',
                                text: ''
                            });
                        }}
                        //className={classes.button}
                        startIcon={<AddIcon />}
                    >
                        Add answer option
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

AnswersFields.propTypes = {
    questionIndex: T.number,
    control: T.object,
    isEdit: T.bool,
    errors: T.any,
    watch: T.any
};

const InviteModal = ({
    control,
    setValue,
    searchResults,
    reset,
    watch,
    activeSearchFilter,
    interestsActiveFilter_survey,
    onClickResetSearchFilters,
    badges,
    departments,
    officesList,
    yearsHiredList,
    isCompany,
    isCommunity,
    isSchoolOnline,
    schoolRoles,
    schoolRoleGroups,
    isEdit,
    errors = [],
    majors,
    open,
    onClose,
    onSubmit
}) => {

    const selectedRoleVal = watch('selectedRole');
    const selectedUsersVal = watch('selectedUsers');
    const startDateVal = watch('startDate');

    const isStartDayToday = Moment().isSame(startDateVal, 'day');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [csv, setCsv] = useState(null);
    const [selectedUsersDropdownOpen, setSelectedUsersDropdownOpen] = useState(true);

    const fabInputRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            selectedRole: String(selectedRoleVal),
            selectedUsers: selectedUsersVal
        };
        onSubmit(formData);
        reset({
            selectedRole: '',
            selectedUsers: []
        });
        onClose();
    };

    useEffect(() => {

        if (selectedRoleVal === 'search') {

            setFiltersOpen(true);
        }

    }, [selectedRoleVal]);

    useEffect(() => {

        setValue('startImmediately', isStartDayToday);
    }, [isStartDayToday]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchResults?.length) {
            setValue('selectedUsers', searchResults);
        }
    }, [searchResults, setValue]);

    const renderActiveFilters = () => {

        const isFilteringResults = activeSearchFilter && !(Object.keys(activeSearchFilter).length === 0) && !activeSearchFilter.allByMatches;

        let interestTitle = null;
        if (interestsActiveFilter_survey.length) {
            if (interestsActiveFilter_survey.length === 1) {
                interestTitle = interestsActiveFilter_survey[0].name;
            }
            else {
                interestTitle = 'Multiple Interests Chosen';
            }
        }

        let selectedBadge = null;

        if (activeSearchFilter && activeSearchFilter.badge) {

            const badgeItem = badges.find(({ name }) => {

                return name === activeSearchFilter.badge;
            });
            if (badgeItem) {
                selectedBadge = badgeItem.label;
            }
        }

        let roleText = null;
        if (activeSearchFilter && activeSearchFilter.contextFilter) {

            const activeRoleGroup = schoolRoleGroups.find((roleGroup) => roleGroup.name === activeSearchFilter.contextFilter) || null;

            roleText = activeRoleGroup ? activeRoleGroup.label : '';
        }

        let timeStatusText = null;
        if (activeSearchFilter && activeSearchFilter.fullTimeStatus) {

            timeStatusText = _capitalize(activeSearchFilter.fullTimeStatus);
        }

        let isTransferText = null;
        if (activeSearchFilter && activeSearchFilter.isTransfer) {

            isTransferText = 'No';
            if (activeSearchFilter.isTransfer === 'true') {
                isTransferText = 'Yes';
            }
        }

        let isOnlineText = null;
        if (activeSearchFilter && activeSearchFilter.isOnline) {

            isOnlineText = 'No';
            if (activeSearchFilter.isOnline === 'true') {
                isOnlineText = 'Yes';
            }
        }

        let openSocialText = null;
        if (activeSearchFilter && activeSearchFilter.openSocial) {

            openSocialText = 'No';
            if (activeSearchFilter.openSocial === 'true') {
                openSocialText = 'Yes';
            }
        }

        let veteranText = null;
        if (activeSearchFilter && activeSearchFilter.isVeteran) {

            veteranText = 'No';
            if (activeSearchFilter.isVeteran === 'true') {
                veteranText = 'Yes';
            }
        }

        let workRemoteText = null;
        if (activeSearchFilter && activeSearchFilter.workRemote) {

            workRemoteText = 'No';
            if (activeSearchFilter.workRemote === 'true') {
                workRemoteText = 'Yes';
            }
        }

        let yearHiredText = null;
        if (activeSearchFilter && activeSearchFilter.yearHiredId) {

            const selectedYear = yearsHiredList.find((item) => {

                return item.id === activeSearchFilter.yearHiredId;
            });

            if (selectedYear) {
                yearHiredText = selectedYear.year;
            }
        }

        let officeText = null;
        if (activeSearchFilter && activeSearchFilter.officeId) {

            const selectedOffice = officesList.find((item) => {

                return item.id === activeSearchFilter.officeId;
            });

            if (selectedOffice) {
                officeText = selectedOffice.name;
            }
        }

        let departmentText = null;
        if (activeSearchFilter && activeSearchFilter.departmentId) {

            const selectedDepartment = departments.find((item) => {

                return item.id === activeSearchFilter.departmentId;
            });

            if (selectedDepartment) {
                departmentText = selectedDepartment.name;
            }
        }

        if (isFilteringResults || selectedRoleVal === 'search') {
            let firstMajor = activeSearchFilter.major ? activeSearchFilter.major[0] : null;

            if (firstMajor) {
                firstMajor = majors.find(({ id }) => id === firstMajor)?.name;
            }

            const activeFilters = [
                {
                    label: 'User Type',
                    value: roleText
                }, {
                    label: 'Name',
                    value: activeSearchFilter.name ? activeSearchFilter.name : null
                }, {
                    label: 'Age',
                    value: activeSearchFilter.age ? activeSearchFilter.age : null
                }, {
                    label: 'Career',
                    value: activeSearchFilter.career ? activeSearchFilter.career : null
                }, {
                    label: 'Profession',
                    value: activeSearchFilter.profession ? activeSearchFilter.profession : null
                }, {
                    label: 'Bio',
                    value: activeSearchFilter.bio ? activeSearchFilter.bio : null
                }, {
                    label: 'Department',
                    value: departmentText
                }, {
                    label: isCompany ? 'Affiliated Office' : 'Campus',
                    value: officeText
                }, {
                    label: 'Year Hired',
                    value: yearHiredText
                }, {
                    label: 'Work Remote',
                    value: workRemoteText
                }, {
                    label: 'Title',
                    value: activeSearchFilter.title ? activeSearchFilter.title : null
                }, {
                    label: 'Badge',
                    value: selectedBadge
                }, {
                    label: 'Radius',
                    value: activeSearchFilter.radius ? activeSearchFilter.radius : null
                }, {
                    label: 'Location',
                    value: activeSearchFilter.locationType ? (activeSearchFilter.locationType === 'housing' ? 'Where I Live' : ((isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : 'My Hometown')) : null
                }, {
                    label: 'Transfer Student',
                    value: isTransferText
                }, {
                    label: 'Online Student',
                    value: isOnlineText
                }, {
                    label: 'Open to Social',
                    value: openSocialText
                }, {
                    label: 'Veteran',
                    value: veteranText
                }, {
                    label: 'Full / Part-time',
                    value: timeStatusText
                }, {
                    label: 'Incoming Class',
                    value: activeSearchFilter.incomingClass ? activeSearchFilter.incomingClass : null
                }, {
                    label: 'Graduating Class',
                    value: activeSearchFilter.graduatingClass ? activeSearchFilter.graduatingClass : null
                }, {
                    label: 'Major',
                    value: firstMajor || null
                }, {
                    label: 'Student Name',
                    value: activeSearchFilter.studentName ? activeSearchFilter.studentName : null
                }, {
                    label: 'Interests',
                    value: interestTitle
                }
            ];

            return (
                <Paper className={`${Classes.filterHeader}`}>
                    <h5 className={Classes.filterTitle}>Active Search Filters:</h5>
                    <div className={Classes.filtersHolder}>
                        {activeFilters.map((filter, index) => {

                            if (filter.value !== undefined && filter.value !== null && filter.value !== false) {
                                return <Chip key={index} className={Classes.activeFilter} color={'primary'} label={`${filter.label} : ${filter.value}`} />;
                            }

                        })}
                    </div>
                    <div className={Classes.activeFilterBtnCont}>
                        <Button
                            variant='contained'
                            color='primary'
                            size="small"
                            onClick={() => {

                                setFiltersOpen(true);

                            }}
                            className={Classes.activeFilterBtn}
                            startIcon={<SearchIcon style={{ color: '#ffffff' }} />}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='contained'
                            color='secondary'
                            size="small"
                            onClick={() => {
                                onClickResetSearchFilters();
                                reset({
                                    selectedRole: ''
                                });
                            }}
                            className={Classes.activeFilterBtn}
                            startIcon={<CancelIcon style={{ color: '#ffffff' }} />}
                        >
                            Cancel
                        </Button>
                    </div>
                </Paper>
            );
        }

        return null;
    };

    const selectCsvFile = async (ev) => {

        const newCsv = ev.target.files[0];

        if (newCsv !== null) {

            let userIds;
            const readFile = (csvFile) => {

                return new Promise((resolve, reject) => {

                    const fr = new FileReader();
                    fr.onload = (event) => {

                        const text = event.target.result;
                        csvFileToArray(text);
                        resolve(userIds);
                    };

                    fr.onerror = reject;

                    fr.readAsText(csvFile);
                });
            };

            const csvFileToArray = (string) => {
                string = string.replace(/(\r\n|\r)/gm, '\n');
                const rows = string.split('\n')
                    .map((row) => row.trim())
                    .filter((row) => row.length > 0);

                const [firstRow] = rows;
                const isHeader = isNaN(firstRow) || firstRow.match(/[a-zA-Z]/);

                const dataRows = isHeader ? rows.slice(1) : rows;

                const array = dataRows
                    .map((row) => ({
                        id: row
                    }))
                    .filter((item) => item.id.length > 0);

                userIds = array;
            };

            try {
                setCsv(newCsv);
                userIds = await readFile(newCsv);
                setValue('selectedUsers', userIds);
            }
            catch (e) {
                return e;
            }
        }
    };

    // TODO add select field to select which users to assign survey

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="invite-modal-title"
                aria-describedby="invite-modal-description"
            >
                <Box style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '90vw',
                    width: 600,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    backgroundColor: '#fff',
                    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
                    padding: '32px',
                    borderRadius: '4px'
                }}>

                    <form onSubmit={handleSubmit}>
                        {!isEdit ? <Grid container={true} spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="selectedRole"
                                    control={control}
                                    render={(field, state) => {

                                        return <StyledFormControl  >
                                            <InputLabel id="user-type-select-input-label">*Selected Role</InputLabel>
                                            <SelectField
                                                // SelectField has width: 256px set as inline style
                                                {...field}
                                                style={{ width: '100%' }}
                                                maxheight={220}
                                            >
                                                <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                                                <MenuItem key='search-selection' value='search'>Search-based</MenuItem>
                                                {schoolRoles.map((schoolRole) => <MenuItem key={schoolRole.id} value={schoolRole.id}>{`Role: ${schoolRole.label}`}</MenuItem>)}
                                                <MenuItem key='csv-selection' value='csv'>CSV-based</MenuItem>
                                            </SelectField>
                                        </StyledFormControl>;
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="selectedRole"
                                    render={({ message }) => (

                                        <Typography sx={{
                                            color: 'error.main'
                                        }}>{message}</Typography>
                                    )}
                                />
                            </Grid>
                        </Grid> : null}
                        {selectedRoleVal && selectedRoleVal === 'search' && selectedUsersVal && <Grid container={true} spacing={3}>
                            <Grid item xs={12}>
                                {renderActiveFilters()}
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="selectedUsers"
                                    control={control}
                                    render={(field, state) => {

                                        return (
                                            <Autocomplete
                                                className="fullWidth"
                                                multiple
                                                classes={{
                                                    endAdornment: Classes.autocompleteEndAdornment,
                                                    inputRoot: Classes.autocompleteInputRoot
                                                }}
                                                value={selectedUsersVal}
                                                onChange={(event, value, reason, details) => {

                                                    field.onChange(value);

                                                }}
                                                options={searchResults}
                                                getOptionLabel={(option) => `${option.firstName.toString()} ${option.lastName.toString()}`}
                                                isOptionEqualToValue={(option, value) => {

                                                    return option.id === value.id;
                                                }}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                disableListWrap
                                                ListboxComponent={ListboxComponent}
                                                /*renderOption={(option) => <Typography className={Classes.interestOption} noWrap>{option.firstName}</Typography>}*/
                                                renderTags={(tagValue, getTagProps) => {

                                                    return <div style={{ background: '#ffffff' }}>
                                                        <Collapse timeout="auto" in={selectedUsersDropdownOpen} unmountOnExit>
                                                            {tagValue.map((option, index) => {

                                                                return <UserChip key={index} {...getTagProps({ index })} user={option} />;
                                                            })}
                                                        </Collapse>
                                                    </div>;
                                                }}
                                                renderInput={
                                                    (args) => {

                                                        return (<TextField
                                                            {...args}
                                                            variant="standard"
                                                            label={<div className={Classes.us} onClick={() => setSelectedUsersDropdownOpen(!selectedUsersDropdownOpen)} >{`Selected Users (${selectedUsersVal.length})`} {selectedUsersDropdownOpen ? <ExpandLess /> : <ExpandMore />}</div>}
                                                            id={'selected_users_input'}
                                                            placeholder={('Start typing to search Users...')}
                                                            disableUnderline={true}
                                                        />);

                                                    }
                                                }
                                            />
                                        );
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="selectedUsers"
                                    render={({ message }) => (

                                        <Typography sx={{
                                            color: 'error.main'
                                        }}>{message}</Typography>
                                    )}
                                />
                            </Grid>
                        </Grid>}
                        {selectedRoleVal && selectedRoleVal === 'csv' && <React.Fragment>
                            <Grid item container xs={12} direction={'column'} justifyContent={'center'} alignItems={'center'} >
                                <Grid item xs={12}>
                                    <p >
                                        {`Upload a CSV file containing numeric user IDs of users to assign to survey. Make sure the file contains only one column, with no header row.`}
                                    </p>
                                </Grid>
                                <Grid item container direction={'column'} xs={12} justifyContent={'center'} alignItems={'center'} spacing={3}>
                                    {csv ?
                                        <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} >
                                            <Grid item xs={'auto'} >
                                                <UploadedFileIcon className={Classes.uploadedFileIcon} />
                                            </Grid>
                                            <Grid item xs={'auto'} >
                                                {csv.name}
                                            </Grid>
                                            <Controller
                                                name="selectedUsers"
                                                control={control}
                                                render={(field) => {

                                                    return <input type="hidden" {...field} />;
                                                }}
                                            />
                                        </Grid> :
                                        <Grid item xs={12}>
                                            <label id={'container-file-upload-label'} className={Classes.uploadLabel}>
                                                CSV file required
                                                <input
                                                    type='file'
                                                    accept='text/csv'
                                                    aria-labelledby={'container-file-upload-label'}
                                                    onChange={selectCsvFile}
                                                    style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                                                />
                                            </label>
                                        </Grid>
                                    }
                                    <Grid item xs={12} >
                                        <FAB onClick={() => {

                                            fabInputRef.current.click();
                                        }} color="primary" aria-label='upload file' id={'fab-file-upload-label'} className={Classes.fab} >
                                            <UploadIcon style={{ fill: '#ffffff' }} />
                                            <input
                                                type='file'
                                                accept='text/csv'
                                                aria-labelledby={'fab-file-upload-label'}
                                                onChange={selectCsvFile}
                                                ref={fabInputRef}
                                                style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                                            />
                                        </FAB>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>}
                        <Grid container spacing={2} sx={{ mt: 3 }}>
                            <Grid item xs={6}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Invite Users
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            <SurveyUsersFilterDialog
                open={filtersOpen}
                onRequestClose={() => setFiltersOpen(false)}
            />
        </React.Fragment>

    );
};

InviteModal.propTypes = {
    values: T.object,
    control: T.object,
    isEdit: T.bool,
    setValue: T.func,
    reset: T.func,
    onSubmit: T.func,
    onClickResetSearchFilters: T.func,
    activeSearchFilter: T.object,
    interestsActiveFilter_survey: T.array,
    departments: T.array,
    open: T.func,
    onClose: T.func,
    officesList: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    yearsHiredList: T.arrayOf(
        T.shape({
            id: T.number,
            year: T.number
        })
    ),
    isCompany: T.bool,
    isCommunity: T.bool,
    isSchoolOnline: T.bool,
    badges: T.array,
    searchResults: T.array,
    schoolRoles: T.array,
    schoolRoleGroups: T.array,
    errors: T.any,
    trigger: T.any,
    watch: T.any,
    majors: T.arrayOf(T.shape({
        id: T.number,
        name: T.string
    }))
};

module.exports = InviteModal;
