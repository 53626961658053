const React = require('react');
const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { useState, useEffect, useMemo } = require('react');
const CenteredLayout = require('components/CenteredLayout');
const Loader = require('components/Loader');
const GetQueryParams = require('utils/get-query-params');
const { useAppSelector } = require('utils/redux');
const { useDispatch } = require('react-redux');
const { context } = require('app-context');
const Snackbar = require('../../../../containers/Snackbar');
const { useAuth0 } = require('hooks/useAuth0');
const AlertDialog = require('containers/AlertDialog');

const selectors = context.selectors.all;

module.exports = function SSOLogin(props) {

    const { redirectUri } = props;

    const queryParams = useMemo(() => GetQueryParams(), []);
    const dispatch = useDispatch();
    const signupContext = useAppSelector(selectors.getSignupContext);

    const [auth0Code, setAuth0Code] = useState(queryParams.code);
    const [auth0State, setAuth0State] = useState(queryParams.state);

    const email = queryParams.email || signupContext.email;
    const sso = queryParams.sso || signupContext.sso;

    const {
        client: auth0,
        logout,
        login: loginAuth0
    } = useAuth0({
        redirectUri,
        email,
        sso,
        cordovaCallback: ({ code, state }) => {

            // Will be either /login-sso or /signup-sso
            const lastPathPart = redirectUri.split('/')[3];

            // auth0.handleRedirectCallback() needs to grab these from query params
            dispatch(
                Push(`/${lastPathPart}?code=${code}&state=${state}`)
            );

            setAuth0Code(code);
            setAuth0State(state);
        }
    });

    useEffect(() => {

        const login = async () => {

            await logout();
            loginAuth0();
        };

        const handleRedirectCallback = async () => {

            try {
                await auth0.handleRedirectCallback();

                const claims = await auth0.getIdTokenClaims();

                // Will redirect to /signup/user-info on success
                const res = await dispatch(context.actions.auth.loginAuthZero({
                    token: claims.__raw
                }));

                // Err
                if (!res) {
                    dispatch(context.actions.app.openAlertDialog());
                }
            }
            catch (err) {
                dispatch(context.actions.app.openAlertDialog());
            }
        };

        if (email && sso && !auth0State && !auth0Code) {
            login();
        }
        else if (email && sso && auth0State && auth0Code) {
            handleRedirectCallback();
        }
    }, [email, sso, auth0State, auth0Code]); // eslint-disable-line react-hooks/exhaustive-deps

    const onConfirm = () => {

        setAuth0Code(null);
        setAuth0State(null);
    };

    return <CenteredLayout>
        <div style={{ height: '20vh' }}>
            <Loader />
        </div>
        <Snackbar />
        <AlertDialog
            title='Error logging in'
            message={`Please select email "${email}"`}
            confirmLabel='Ok'
            declineLabel='Go back'
            confirmationCallback={onConfirm}
            closeCallback={onConfirm}
            cancelCallback={() => {

                // Wait a tick for the dialog to dismiss
                setTimeout(() => {

                    dispatch(
                        Push('/login')
                    );
                }, 0);
            }}
        />
    </CenteredLayout>;
};

module.exports.propTypes = {
    redirectUri: T.string
};
