const React = require('react');
const T = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const Moment = require('moment');

const { default: linkifyHtml } = require('linkify-html');

const { default: Avatar } = require('@mui/material/Avatar');
const { default: MuiListItem } = require('@mui/material/ListItem');
const { default: MuiListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default: MuiListItemText } = require('@mui/material/ListItemText');
const { default: FlatButton } = require('@mui/material/Button');
const { default: PersonAddIcon } = require('@mui/icons-material/PersonAdd');
const { default: GroupIcon } = require('@mui/icons-material/Group');
const { default: MessageIcon } = require('@mui/icons-material/Message');
const { default: AnnouncementIcon } = require('@mui/icons-material/Announcement');
const { default: AssignmentIcon } = require('@mui/icons-material/Assignment');
const { default: RedoIcon } = require('@mui/icons-material/Redo');
const { default: Styled } = require('styled-components');
const { getSizedImageUrl } = require('utils/image');
const Emoji = require('components/Emoji');

const ItemBody = require('../Chat/ItemBody');

const { formatSimilarityText } = require('utils/makeUsersSimilarityText');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const StyledSurveyListItemText = Styled(MuiListItemText)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.between(theme.breakpoints.values.mobileL, theme.breakpoints.values.sm)]: {
        flexBasis: '80%'
    },
    [theme.breakpoints.between(theme.breakpoints.values.mobileM, theme.breakpoints.values.mobileL)]: {
        flexBasis: '75%'
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileM)]: {
        flexBasis: '70%'
    }
}));

const internals = {};

module.exports = class NotificationsDialog extends React.Component {

    static propTypes = {
        onRequestIgnore: T.func,
        onRequestAccept: T.func,
        onDismissNotification: T.func,
        onAcceptTransfer: T.func,
        showNotification: T.func,
        divider: T.bool,
        currentUserRolesInteractions: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool
        })),
        notification: T.shape({
            id: T.any.isRequired,
            channelType: T.string,
            channelSid: T.string,
            emojiSymbol: T.string,
            info: T.shape({
                text: T.string,
                similarityText: T.string,
                surveyId: T.number,
                surveyName: T.string,
                responses: T.arrayOf(T.shape({
                    value: T.string,
                    label: T.string
                }))
            }),
            class: T.shape({
                id: T.string,
                name: T.string,
                emojiSymbol: T.string
            }),
            createdAt: T.string, // A date
            sender: T.shape({
                id: T.any.isRequired,
                croppedPicture: T.string,
                firstName: T.string,
                lastName: T.string,
                roleId: T.number
            }),
            groupDetails: T.shape({
                class: T.shape({
                    id: T.string,
                    name: T.string,
                    emojiSymbol: T.string
                })
            }),
            transferDetails: T.shape({
                class: T.shape({
                    id: T.string,
                    name: T.string,
                    emojiSymbol: T.string
                }),
                transfer: T.shape({
                    id: T.string,
                    name: T.string
                })
            }),
            type: T.oneOf([
                'peer',
                'transfer',
                'announcement',
                'question',
                'group-invite',
                'survey'
            ]).isRequired,
            status: T.oneOf(['new', 'accepted']),
            read: T.bool,
            marked: T.oneOf(['declined', 'accepted', null])
        }),
        onLeave: T.func,
        onClickJoinClass: T.func,
        onClickAnswerQuestion: T.func
    }

    onClickJoinClass = (id, name, isTransfer) => {

        const {
            onLeave,
            onClickJoinClass,
            onAcceptTransfer,
            onDismissNotification,
            notification } = this.props;

        return () => {

            onClickJoinClass({ id, name });
            onLeave();

            if (isTransfer) {
                onAcceptTransfer(notification);
            }

            onDismissNotification(notification);
            AnimatedFocusIndicator.onBlurHandler();
        };
    }

    onClickIgnoreClass = () => {

        const {
            onLeave,
            onDismissNotification,
            notification
        } = this.props;

        return () => {

            onLeave();
            onDismissNotification(notification);
            AnimatedFocusIndicator.onBlurHandler();
        };
    }

    onClickAnswerQuestion = (answer) => {

        const { onClickAnswerQuestion, notification: { id } } = this.props;

        return () => onClickAnswerQuestion({ notificationId: id, answer });
    }

    accept = () => {

        this.props.onRequestAccept(this.props.notification);
        AnimatedFocusIndicator.onBlurHandler();
    }

    ignore = () => {

        this.props.onRequestIgnore(this.props.notification);
        AnimatedFocusIndicator.onBlurHandler();
    }

    render() {

        const {
            onLeave,
            notification: {
                createdAt,
                sender,
                type,
                status,
                marked,
                transferDetails,
                groupDetails,
                channelType,
                channelSid,
                emojiSymbol: notificationEmoji,
                info,
                class: class_
            },
            currentUserRolesInteractions,
            showNotification,
            divider,
            // eslint-disable-next-line no-unused-vars
            onDismissNotification,
            ...rest
        } = this.props;

        const {
            contentMap,
            ListItem,
            ListItemText,
            ListMainContentRow,
            ListItemAvatar,
            IconContainer,
            ActionButtonsContainer,
            ProfileRouterLink,
            ProfileLink,
            IgnoreButton,
            AcceptButton,
            RouterLinkNoUnderline,
            QuestionRoot,
            ResponseButton
        } = internals;

        const { text, icon } = (contentMap[status] && contentMap[status][type]) || { text: '', icon: null };

        let canViewSenderProfile = false;

        if (sender) {
            const { roleId: senderRoleId } = sender;

            const senderRoleInteractions = currentUserRolesInteractions.find((roleInteractions) => {

                return roleInteractions.id === senderRoleId;
            });

            canViewSenderProfile = senderRoleInteractions.canViewProfile;
        }

        let ClassIcon = () => {

            return <GroupIcon style={{ color: 'white' }} />;
        };

        if (groupDetails && groupDetails.class && groupDetails.class.emojiSymbol) {
            // eslint-disable-next-line react/display-name
            ClassIcon = () => {

                return <Emoji style={{ padding: 0 }} symbol={groupDetails.class.emojiSymbol} />;
            };
        }

        let CustomAnnouncementIcon = () => {

            return <AnnouncementIcon style={{ color: 'white' }} />;
        };

        if (notificationEmoji && notificationEmoji.length) {
            // eslint-disable-next-line react/display-name
            CustomAnnouncementIcon = () => {

                return <Emoji style={{ padding: '0.25rem' }} symbol={notificationEmoji} />;
            };
        }

        // marked is null by default
        const notificationText = marked ? text[marked] : (text.default ? text.default : text);
        const options = {
            defaultProtocol: 'https',
            format: () => 'link',
            target: '_blank',
            rel: 'noopener noreferrer'
        };

        const processedText = linkifyHtml(
            info.text || '',
            options
        );

        // Peer request
        if (type === 'peer') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            {canViewSenderProfile ?
                                <Link
                                    aria-label={`Link to ${sender.firstName} ${sender.lastName}'s profile`}
                                    onClick={onLeave}
                                    to={`/app/profile/${sender.id}`}
                                    data-focus-outline='radius:40,padding:2'
                                    style={{ display: 'inline-block' }}
                                >
                                    <Avatar src={getSizedImageUrl(sender.croppedPicture, 100)} alt={sender.firstName + ' ' + sender.lastName} />
                                </Link> :
                                <a
                                    onClick={() => {

                                        showNotification('Sorry, you cannot see this user\'s profile');
                                        onLeave();
                                    }}
                                    style={{ display: 'inline-block' }}
                                    data-focus-outline='radius:40'
                                >
                                    <Avatar src={getSizedImageUrl(sender.croppedPicture, 100)} alt={sender.firstName + ' ' + sender.lastName} />
                                </a>
                            }
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>
                                    <b>{canViewSenderProfile
                                        ? (
                                            <ProfileRouterLink
                                                aria-label={`Link to ${sender.firstName} ${sender.lastName}'s profile`}
                                                onClick={onLeave}
                                                to={`/app/profile/${sender.id}`}
                                                data-focus-outline='radius:5,padding:2'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </ProfileRouterLink>
                                        )
                                        : (
                                            <ProfileLink
                                                onClick={() => {

                                                    showNotification('Sorry, you cannot see this user\'s profile');
                                                    onLeave();
                                                }}
                                                data-focus-outline='radius:5,padding:2'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </ProfileLink>
                                        )
                                    }
                                    </b> {notificationText} {(info && info.similarityText) ? <span dangerouslySetInnerHTML={{ __html: formatSimilarityText(info.similarityText) }} /> : ''}
                                </span>
                            }
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <IconContainer>
                            {icon}
                        </IconContainer>
                    </ListMainContentRow>
                    {!marked && status === 'new' && (
                        <ActionButtonsContainer>
                            <IgnoreButton
                                variant='outlined'
                                color='secondary'
                                onClick={this.ignore}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                Ignore
                            </IgnoreButton>
                            <AcceptButton
                                color={'primary'}
                                variant='contained'
                                onClick={this.accept}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                Accept
                            </AcceptButton>
                        </ActionButtonsContainer>
                    )}
                </ListItem>
            );
        }

        // Announcement
        if (type === 'announcement') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Avatar><CustomAnnouncementIcon /></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={(/<\/?[a-z][\s\S]*>/i).test(info.text) ? <div dangerouslySetInnerHTML={{
                                __html: processedText
                            }} /> : <ItemBody style={{ overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>{info.text}</ItemBody>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                    </ListMainContentRow>
                </ListItem>
            );
        }

        // Question
        if (type === 'question') {
            return (
                <QuestionRoot
                    {...rest}
                    $showDivider={divider}
                >
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Avatar>?</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<ItemBody style={{ overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>{info.text}</ItemBody>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <ActionButtonsContainer>
                            {info.responses.map((response) =>

                                <ResponseButton
                                    key={response.value}
                                    color={'primary'}
                                    onClick={this.onClickAnswerQuestion(response.value)}
                                >
                                    {response.label}
                                </ResponseButton>
                            )}
                        </ActionButtonsContainer>
                    </ListMainContentRow>
                </QuestionRoot>
            );
        }

        // Survey
        if (type === 'survey') {
            return (
                <QuestionRoot
                    {...rest}
                    $showDivider={divider}
                >
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Avatar><AssignmentIcon /></Avatar>
                        </ListItemAvatar>
                        <StyledSurveyListItemText
                            primary={<span >A new survey, <b><ProfileRouterLink
                                aria-label='Link to survey page'
                                onClick={onLeave}
                                to={`/app/surveys/${info.surveyId}/start`}
                                data-focus-outline='radius:6,padding:2'
                            >
                                {info.surveyName}
                            </ProfileRouterLink></b>, is now available! Please tell us how you feel!</span>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                    </ListMainContentRow>
                </QuestionRoot>
            );
        }

        // Group Invite
        if (type === 'group-invite') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <RouterLinkNoUnderline aria-label='Link to group page' onClick={onLeave} to={`/app/classes/${groupDetails.class.id}`}><Avatar><ClassIcon /></Avatar></RouterLinkNoUnderline>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>
                                    Meet others in <b><ProfileRouterLink aria-label='Link to group page' onClick={onLeave} to={`/app/classes/${groupDetails.class.id}`}>{groupDetails.class.name}</ProfileRouterLink></b>! {info.text}
                                </span>
                            }
                            secondary={Moment(createdAt).fromNow()}
                        />
                    </ListMainContentRow>
                    <ActionButtonsContainer>
                        <IgnoreButton
                            variant='outlined'
                            color='secondary'
                            onClick={this.onClickIgnoreClass()}
                            data-focus-outline='radius:40,padding:-1'
                        >
                            Ignore
                        </IgnoreButton>
                        <AcceptButton
                            component={Link}
                            color={'primary'}
                            variant='contained'
                            aria-label='Link to join'
                            to={`/app/classes/${groupDetails.class.id}`}
                            onClick={this.onClickJoinClass(groupDetails.class.id, groupDetails.class.name, false)}
                            data-focus-outline='radius:40,padding:-1'
                        >
                            Accept
                        </AcceptButton>
                    </ActionButtonsContainer>
                </ListItem>
            );
        }

        // Transfer
        if (type === 'transfer' && transferDetails) {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <RouterLinkNoUnderline aria-label='Link to group page' onClick={onLeave} to={`/app/classes/${transferDetails.class.id}`}><Avatar><RedoIcon style={{ color: 'white' }} /></Avatar></RouterLinkNoUnderline>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>We see you transferred from <b>{transferDetails.transfer.name}</b>!  Join similar peers in <b>
                                    <ProfileRouterLink aria-label='Link to group page' onClick={onLeave} to={`/app/classes/${transferDetails.class.id}`}>{transferDetails.class.name}</ProfileRouterLink>
                                </b>.
                                </span>
                            }
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <IconContainer>
                            {icon}
                        </IconContainer>
                    </ListMainContentRow>
                    <ActionButtonsContainer>
                        <AcceptButton
                            component={Link}
                            variant='contained'
                            aria-label='Link to join group'
                            to={`/app/classes/${transferDetails.class.id}`}
                            color='primary'
                            onClick={this.onClickJoinClass(transferDetails.class.id, transferDetails.class.name, true)}
                            data-focus-outline='radius:40,padding:-1'
                        >
                            Join
                        </AcceptButton>
                    </ActionButtonsContainer>
                </ListItem>
            );
        }

        if (type === 'mention') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <RouterLinkNoUnderline
                                aria-label='Link to chat page'
                                onClick={onLeave}
                                to={(channelType === 'class') ? `/app/classes/chat/${channelSid}` : `/app/messaging/${channelSid}`}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                <Avatar>M</Avatar>
                            </RouterLinkNoUnderline>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>You were mentioned by{' '}
                                    <b>
                                        {canViewSenderProfile ?
                                            <ProfileRouterLink
                                                aria-label='Link to user profile'
                                                onClick={onLeave}
                                                to={`/app/profile/${sender.id}`}
                                                data-focus-outline='radius:10'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </ProfileRouterLink> :
                                            <ProfileLink
                                                onClick={() => {

                                                    showNotification('Sorry, you cannot see this user\'s profile');
                                                    onLeave();
                                                }}
                                                data-focus-outline='radius:10,padding:2'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </ProfileLink>
                                        }
                                    </b>
                                    {' '}in{' '}
                                    <b>
                                        <ProfileRouterLink
                                            aria-label='Link to chat page'
                                            onClick={onLeave}
                                            to={(channelType === 'class') ? `/app/classes/chat/${channelSid}` : `/app/messaging/${channelSid}`}
                                            data-focus-outline='radius:10,padding:2'
                                        >
                                            {(channelType === 'class') && (class_ ? class_.name : 'a class chat')}
                                            {(channelType === 'private') && 'a private chat'}
                                            {(channelType !== 'private' && channelType !== 'class') && 'a chat'}
                                        </ProfileRouterLink>
                                    </b>!
                                </span>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <IconContainer>
                            {icon}
                        </IconContainer>
                    </ListMainContentRow>
                </ListItem>
            );
        }

        return null;
    }
};

internals.contentMap = {
    none: {
        transfer: {
            text: { default: null },
            icon: <GroupIcon />
        }
    },
    new: { // used here as 'unanswered'
        peer: {
            text: {
                default: 'would like to connect with you!',
                accepted: 'is connected with you!'
            },
            icon: <PersonAddIcon />
        },
        mention: {
            text: { default: null },
            icon: <MessageIcon />
        },
        survey: {
            text: { default: null },
            icon: <AssignmentIcon />
        }
    },
    accepted: {
        peer: {
            text: { default: 'is connected with you!' },
            icon: <PersonAddIcon />
        }
    }
};

const listItemStyles = `
    display: flex;
    flex-flow: column nowrap;

    border-bottom: ${({ theme, $showDivider }) => $showDivider ? `1px solid ${theme.palette.list.divider}` : 'none'};

    // Targeting announcement generated HTML text
    > div > div div {
        > p {
            margin: 10px 0;
        }

        > p:first-of-type {
            margin-top: 0;
        }

        > p:last-of-type {
            margin-bottom: 0;
        }
    }

    // Targeting the secondary text, we use as the timestamp
    > div > div > p {
        margin-top: 5px;
    }
`;

internals.ListItem = Styled(MuiListItem)`
    ${listItemStyles}
`;

internals.QuestionRoot = Styled(MuiListItem)`
    flex-wrap: wrap;
    ${listItemStyles}
`;

internals.ListMainContentRow = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
`;

internals.ListItemAvatar = Styled(MuiListItemAvatar)`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-right: 5px;
`;

internals.ListItemText = Styled(MuiListItemText)`
    display: flex;
    flex-flow: column nowrap;
`;

internals.IconContainer = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
`;

internals.ActionButtonsContainer = Styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const actionButtonStyles = `
    width: 170px;
    line-height: 28px !important;

    @media screen and (max-width: 600px) {
        width: 120px;
    }

    &.selectedIgnored {
        color: white !important;
        background-color: ${({ theme }) => theme.palette.secondary.main} !important;
        border-color: ${({ theme }) => theme.palette.secondary.main};
    }

    &.selectedAccepted {
        color: white !important;
        background-color: ${({ theme }) => theme.palette.primary.main} !important;
        border-color: ${({ theme }) => theme.palette.primary.main};
    }
`;

internals.IgnoreButton = Styled(FlatButton)`
    ${actionButtonStyles}

    &:active {
        color: white !important;
        background-color: ${({ theme }) => theme.palette.secondary.main} !important;
        border-color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

internals.AcceptButton = Styled(FlatButton)`
    ${actionButtonStyles}

    &:active {
        color: white !important;
        background-color: ${({ theme }) => theme.palette.primary.main} !important;
        border-color: ${({ theme }) => theme.palette.primary.main};
    }
`;

const profileLinkStyles = `
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
`;

internals.ProfileRouterLink = Styled(Link)`
    ${profileLinkStyles}
`;

internals.ProfileLink = Styled.a`
    ${profileLinkStyles}
`;

internals.RouterLinkNoUnderline = Styled(Link)`
    text-decoration: none;
`;

internals.ResponseButton = Styled(FlatButton)`
    display: block;
    min-width: auto !important;
    border: 1px solid !important; // Color should be same as text
    margin: 0 5px !important;
    line-height: 32px !important;
`;
