const ToPairs = require('lodash/toPairs');
const First = require('lodash/first');
const Map = require('lodash/map');
const IsEqual = require('lodash/isEqual');
const OmitBy = require('lodash/omitBy');
const Uniq = require('lodash/uniq');
const Pick = require('lodash/pick');
const Min = require('lodash/min');
const Capitalize = require('lodash/capitalize');
const DifferenceWith = require('lodash/differenceWith');
const IsUndefined = require('lodash/isUndefined');
const { combineReducers } = require('redux');
const { setOptimistic, unsetOptimistic } = require('../utils/redux-helpers/reducer-helpers');
const ReduceReducers = require('reduce-reducers');
const Deeply = require('../utils/deeply');
const { DATA_FETCHING: Types, AUTH, NOTIFICATIONS, APP } = require('../action-types');
const { transform } = require('../utils/transform');
const SelectFirstEmojiFromString = require('../utils/selectFirstEmojiFromString');
const { denormalize, schema: S } = require('normalizr');
const GetQueryParams = require('../utils/get-query-params');
const { getOriginalImageUrl } = require('utils/image');

const {
    USER_ROLE_IDS,
    USER_ROLE_GROUPS_IDS,
    USERS_SORT_TYPE,
    ALL_GROUPS_SORT_TYPE,
    MESSAGES_SORT_TYPE
} = require('../utils/constants');
const Moment = require('moment');
const { safeWarning } = require('assertions-simplified');

// Define supported user permissions

const PERMISSIONS = {
    SUPERUSER: 'superuser',
    STAFF: 'staff',
    STUDENT: 'student',
    PARENT: 'parent'
};

const internals = {};

const makeEntities = () => {

    const specificTypes = {
        [Types.SUBSCRIBE_MESSAGES.UPDATE]: true
    };

    const isSpecific = (action) => !!specificTypes[action.type];
    const isFetching = (action) => action.type.startsWith('DATA_FETCHING/') || action.type.startsWith('CLASSES/');
    const isSuccess = (action) => action.type.endsWith('/SUCCESS');

    const merge = ({ entities, newEntities }) =>

        ToPairs(newEntities)
            .reduce((deeply, [type, items]) => {

                // Shallow merge each record individually, allows for partial results
                for (const id in items) {
                    deeply = deeply.assign([type, id], items[id]);
                }

                return deeply;
            }, Deeply(entities))
            .value();

    const entitiesReducer = (state = {}, action) => {

        if (!action || !action.payload) {
            return state;
        }

        return ((isFetching(action) && isSuccess(action)) || isSpecific(action))
            ? merge({ entities: state, newEntities: action.payload.result ? action.payload.result.entities : [] })
            : state;
    };

    const notifications = (state = {}, action) => {

        switch (action.type) {
            case NOTIFICATIONS.MARK_READ.REQUEST: {

                const { ids } = action.payload;

                const setRead = setOptimistic(state, 'read', true);

                return ids.reduce(setRead, Deeply(state)).value();
            }

            case NOTIFICATIONS.MARK_READ.FAILURE: {

                const { ids } = action.payload.request;

                const unsetRead = unsetOptimistic(state, 'read');

                return ids.reduce(unsetRead, Deeply(state)).value();
            }

            case NOTIFICATIONS.ACCEPT.REQUEST: {

                const { id } = action.payload;

                const setMarked = setOptimistic(state, 'marked', 'accepted');

                return setMarked(Deeply(state), id).value();
            }

            case NOTIFICATIONS.ACCEPT.FAILURE: {

                const { id } = action.payload.request;

                const unsetMarked = unsetOptimistic(state, 'marked');

                return unsetMarked(Deeply(state), id).value();
            }

            case NOTIFICATIONS.DECLINE.REQUEST: {

                const { id } = action.payload;

                const setMarked = setOptimistic(state, 'marked', 'declined');

                return setMarked(Deeply(state), id).value();
            }

            case NOTIFICATIONS.DECLINE.FAILURE: {

                const { id } = action.payload.request;

                const unsetMarked = unsetOptimistic(state, 'marked');

                return unsetMarked(Deeply(state), id).value();
            }

            case NOTIFICATIONS.CLEAR_MENTIONS.SUCCESS: {

                const { ids: removedIds } = action.payload.result;

                return removedIds.reduce((s, id) => s.del(id), Deeply(state)).value();
            }

            default:
                return state;
        }
    };

    const messages = (state = null, action) => {

        switch (action.type) {
            case Types.SUBSCRIBE_MESSAGES.REMOVE: {

                const { sid } = action.payload;

                return Deeply(state).del(sid).value();
            }

            default:
                return state;
        }
    };

    const surveyQuestions = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_SURVEY.REQUEST: {

                return {};
            }

            default:
                return state;
        }
    };

    const questionAnswers = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_SURVEY.REQUEST: {

                return {};
            }

            default:
                return state;
        }
    };

    return ReduceReducers(
        entitiesReducer,
        fixCombineReducers({
            notifications,
            messages,
            surveyQuestions,
            questionAnswers
        }),
        fixCombineReducers({
            classes: wipeOnContextSwitch(),
            surveys: wipeOnContextSwitch(),
            users: wipeOnContextSwitch(),
            userExtensions: wipeOnContextSwitch(),
            surveyQuestions: wipeOnContextSwitch(),
            questionAnswers: wipeOnContextSwitch(),
            classExtensions: wipeOnContextSwitch(),
            channels: wipeOnContextSwitch(),
            messages: wipeOnContextSwitch(),
            notifications: wipeOnContextSwitch()
        }),
        fixCombineReducers({
            classes: wipeOnLogout(),
            surveys: wipeOnLogout(),
            users: wipeOnLogout(),
            userExtensions: wipeOnLogout(),
            surveyQuestions: wipeOnLogout(),
            questionAnswers: wipeOnLogout(),
            classExtensions: wipeOnLogout(),
            channels: wipeOnLogout(),
            messages: wipeOnLogout(),
            notifications: wipeOnLogout()
        })
    );
};

const makeIndex = () => {

    const schools = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.schools) {
                    return action.payload.result.result.schools;
                }

                return state;
            case Types.FETCH_SCHOOLS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const allClasses = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_CLASSES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const interests = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.interests) {
                    return action.payload.result.result.interests;
                }

                return state;
            case Types.FETCH_INTERESTS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const roleGroups = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.roleGroups) {
                    return action.payload.result.result.roleGroups;
                }

                return state;
            case Types.FETCH_ROLE_GROUPS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const roles = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.roleGroups) {
                    return action.payload.result.result.roleGroups;
                }

                return state;
            case Types.FETCH_ROLES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const surveys = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_SURVEYS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const categories = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.categories) {
                    return action.payload.result.result.categories;
                }

                return state;
            case Types.FETCH_CATEGORIES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const localConversations = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_GROUP_CONVERSATION.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const localMessages = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_GROUP_CONVERSATION_MESSAGES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const badgeTypes = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.badgeTypes) {
                    return action.payload.result.result.badgeTypes;
                }

                return state;
            case Types.FETCH_BADGE_TYPES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const conversationStarters = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.conversationStarters) {
                    return action.payload.result.result.conversationStarters;
                }

                return state;
            case Types.FETCH_CONVERSATION_STARTERS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const offices = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.offices) {
                    return action.payload.result.result.offices;
                }

                return state;
            case Types.FETCH_OFFICES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const yearsHired = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.result.yearsHired) {
                    return action.payload.result.result.yearsHired;
                }

                return state;
            case Types.FETCH_YEARS_HIRED.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const preferences = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_APP_ENTITIES.SUCCESS:
                if (action.payload.result.preferences) {
                    return action.payload.result.preferences;
                }

                return state;
            case Types.FETCH_USER_PREFERENCES.SUCCESS:
                return action.payload.result;
            default:
                return state;
        }
    };

    const users = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_USERS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const userSearchResults = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE.REQUEST: // Reset results when search begins
                return state || null;
            case Types.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE.SUCCESS:
                return action.payload.result.result;
            case Types.FETCH_USER_SEARCH_RESULTS.NEXT_PAGE.SUCCESS:
                return state.concat(action.payload.result.result);
            case Types.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE.FAILURE: // Simulate empty result– an error message should show
                return [];
            default:
                return state;
        }
    };

    const notifyUsersSearchResults = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_USER_SEARCH_NOTIFY_RESULTS.REQUEST: // Reset results when search begins
                return state || null;
            case Types.FETCH_USER_SEARCH_NOTIFY_RESULTS.SUCCESS:
                return action.payload.result.result;
            case Types.FETCH_USER_SEARCH_NOTIFY_RESULTS.FAILURE: // Simulate empty result– an error message should show
                return [];
            default:
                return state;
        }
    };

    const surveyUsersSearchResults = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_USER_SEARCH_SURVEY_RESULTS.REQUEST: // Reset results when search begins
                return state || null;
            case Types.FETCH_USER_SEARCH_SURVEY_RESULTS.SUCCESS:
                return action.payload.result.result;
            case Types.FETCH_USER_SEARCH_SURVEY_RESULTS.FAILURE: // Simulate empty result– an error message should show
                return [];
            default:
                return state;
        }
    };

    const userSearchMetaInitialState = {
        page: null,
        criteria: null,
        loading: false,
        loadingNextPage: false,
        moreResults: false,
        viewAll: false
    };

    const userSearchMeta = (state = userSearchMetaInitialState, action) => {

        switch (action.type) {
            case Types.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE.REQUEST:
                return {
                    ...state,
                    loading: true
                };
            case Types.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE.SUCCESS:
                return {
                    ...state,
                    viewAll: action.payload.request && !!action.payload.request.viewAll,
                    page: 0,
                    loading: false,
                    criteria: action.payload.result.criteria || null,
                    moreResults: action.payload.result.result.length === 20
                };
            case Types.FETCH_USER_SEARCH_RESULTS.NEXT_PAGE.REQUEST:
                return {
                    ...state,
                    loadingNextPage: true
                };
            case Types.FETCH_USER_SEARCH_RESULTS.NEXT_PAGE.SUCCESS:
                return {
                    ...state,
                    page: state.page + 1,
                    loadingNextPage: false,
                    moreResults: action.payload.result.result.length === 20
                };
            default:
                return state;
        }
    };

    const notifyUserSearchMetaInitialState = {
        page: null,
        criteria: null,
        moreResults: false,
        viewAll: false
    };

    const notifyUserSearchMeta = (state = notifyUserSearchMetaInitialState, action) => {

        switch (action.type) {
            case Types.FETCH_USER_SEARCH_NOTIFY_RESULTS.REQUEST:
                return userSearchMetaInitialState;
            case Types.FETCH_USER_SEARCH_NOTIFY_RESULTS.SUCCESS:
                return {
                    ...state,
                    viewAll: action.payload.request && !!action.payload.request.viewAll,
                    page: 0,
                    criteria: action.payload.result.criteria || null,
                    moreResults: false
                };
            default:
                return state;
        }
    };

    const surveyUserSearchMetaInitialState = {
        page: null,
        criteria: null,
        moreResults: false,
        viewAll: false
    };

    const surveyUserSearchMeta = (state = surveyUserSearchMetaInitialState, action) => {

        switch (action.type) {
            case Types.FETCH_USER_SEARCH_SURVEY_RESULTS.REQUEST:
                return userSearchMetaInitialState;
            case Types.FETCH_USER_SEARCH_SURVEY_RESULTS.SUCCESS:
                return {
                    ...state,
                    viewAll: action.payload.request && !!action.payload.request.viewAll,
                    page: 0,
                    criteria: action.payload.result.criteria || null,
                    moreResults: false
                };
            default:
                return state;
        }
    };

    const getClassUserSearchInitialState = () => ({ searchResults: [], criteria: null, moreResults: false, page: 0 });
    const classUserSearchResults = (state = {}, action) => {

        if (!action.payload) {
            return state;
        }

        let classId;

        if (!action.payload.request) {
            // On '.REQUEST' actions, the classId is on the payload
            classId = action.payload.classId;
        }
        else {
            // Otherwise, the classId is on payload.request
            classId = action.payload.request.classId;
        }

        if (!classId) {
            return state;
        }

        switch (action.type) {
            case Types.FETCH_CLASS_USER_SEARCH_RESULTS.FIRST_PAGE.REQUEST: // Reset results when search begins
                // We don't want to save previous group searches, so we omit ...state,
                return {
                    [classId]: getClassUserSearchInitialState()
                };
            case Types.FETCH_CLASS_USER_SEARCH_RESULTS.FIRST_PAGE.SUCCESS:
                // We don't want to save previous group searches, so we omit ...state,
                return {
                    [classId]: {
                        searchResults: action.payload.result.result,
                        criteria: action.payload.result.criteria || null,
                        moreResults: action.payload.result.result.length === 20,
                        page: 0
                    }
                };
            case Types.FETCH_CLASS_USER_SEARCH_RESULTS.NEXT_PAGE.SUCCESS:
                return {
                    ...state,
                    [classId]: {
                        searchResults: state[classId].searchResults.concat(action.payload.result.result),
                        criteria: {
                            ...state[classId].criteria,
                            ...action.payload.result.criteria
                        },
                        page: state[classId].page + 1,
                        moreResults: action.payload.result.result.length === 20
                    }
                };
            case Types.FETCH_CLASS_USER_SEARCH_RESULTS.FIRST_PAGE.FAILURE: // Simulate empty result– an error message should show
                return {
                    ...state,
                    [classId]: {
                        searchResults: [],
                        criteria: null,
                        moreResults: false,
                        page: null
                    }
                };
            default:
                return state;
        }
    };

    const classSearchResults = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_CLASS_SEARCH_RESULTS.REQUEST: // Reset results when search begins
                return null;
            case Types.FETCH_CLASS_SEARCH_RESULTS.SUCCESS:
                return action.payload.result.result;
            case Types.FETCH_CLASS_SEARCH_RESULTS.FAILURE: // Simulate empty result– an error message should show
                return [];
            default:
                return state;
        }
    };

    const channels = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_CHANNELS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const unreadMessageCounts = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_UNREAD_MESSAGE_COUNTS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const messageCounts = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_ALL_MESSAGE_COUNTS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const unreadLocalMessageCounts = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_UNREAD_LOCAL_MESSAGE_COUNTS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const localMessageCounts = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_ALL_LOCAL_MESSAGE_COUNTS.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const localConversationUpdateDates = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_LOCAL_CONVERSATIONS_UPDATE_DATES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const channelUpdateDates = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_CHANNEL_UPDATE_DATES.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const channelLastMessages = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_CHANNEL_LAST_MESSAGE.SUCCESS:
                return action.payload.result.result;
            default:
                return state;
        }
    };

    const messages = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_MESSAGES.FIRST_PAGE.SUCCESS: {
                return action.payload.result.result;
            }

            case Types.FETCH_MESSAGES.NEXT_PAGE.SUCCESS: {

                state = state || [];

                const { result } = action.payload.result;
                const list = Uniq([...result, ...state]);

                return (list.length === state.length) ? state : list; // Only update if there are new items
            }

            case Types.SUBSCRIBE_MESSAGES.REMOVE: {

                const { sid } = action.payload;

                return Deeply(state).del(state.indexOf(sid)).value();
            }

            default:
                return state;
        }
    };

    const notifications = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_NOTIFICATIONS.SUCCESS: {

                return action.payload.result.result;
            }

            case NOTIFICATIONS.CLEAR_MENTIONS.SUCCESS: {

                state = state || [];
                const { ids: removedIds } = action.payload.result;

                return removedIds.reduce((s, id) => Deeply(s).del(s.indexOf(id)).value(), state);
            }

            default:
                return state;
        }
    };

    const currentUser = (state = null, action) => {

        switch (action.type) {
            case Types.FETCH_CURRENT_USER.SUCCESS:
                return action.payload.result.result.currentUserDetails;
            case AUTH.LOGOUT.SUCCESS:
            case AUTH.CLOSE_ACCOUNT.SUCCESS:
            case AUTH.ACCOUNT_CLOSED_CLEAR:
                return null;
            default:
                return state;
        }
    };

    const scheduledNotifications = (state = [], action) => {
        switch (action.type) {
            case Types.GET_SCHEDULED_NOTIFICATIONS.SUCCESS:
                return action.payload?.result || [];
            default:
                return state;
        }
    };

    return ReduceReducers(
        combineReducers({
            schools,
            allClasses,
            interests,
            roleGroups,
            roles,
            surveys,
            categories,
            localConversations,
            localMessages,
            unreadLocalMessageCounts,
            localMessageCounts,
            badgeTypes,
            conversationStarters,
            yearsHired,
            offices,
            preferences,
            users,
            userSearchResults,
            notifyUsersSearchResults,
            surveyUsersSearchResults,
            userSearchMeta,
            notifyUserSearchMeta,
            surveyUserSearchMeta,
            classSearchResults,
            classUserSearchResults,
            channels,
            unreadMessageCounts,
            messageCounts,
            channelUpdateDates,
            localConversationUpdateDates,
            channelLastMessages,
            messages,
            notifications,
            currentUser,
            scheduledNotifications
        }),
        fixCombineReducers({
            users: wipeOnContextSwitch(),
            allClasses: wipeOnContextSwitch(),
            preferences: wipeOnContextSwitch(),
            yearsHired: wipeOnContextSwitch(),
            offices: wipeOnContextSwitch(),
            userSearchResults: wipeOnContextSwitch(),
            notifyUsersSearchResults: wipeOnContextSwitch(),
            surveyUsersSearchResults: wipeOnContextSwitch(),
            userSearchMeta: wipeOnContextSwitch(userSearchMetaInitialState),
            notifyUserSearchMeta: wipeOnContextSwitch(notifyUserSearchMetaInitialState),
            surveyUserSearchMeta: wipeOnContextSwitch(surveyUserSearchMetaInitialState),
            classSearchResults: wipeOnContextSwitch(),
            roles: wipeOnContextSwitch(),
            surveys: wipeOnContextSwitch(),
            channels: wipeOnContextSwitch(),
            unreadMessageCounts: wipeOnContextSwitch(),
            unreadLocalMessageCounts: wipeOnContextSwitch(),
            messageCounts: wipeOnContextSwitch(),
            localMessageCounts: wipeOnContextSwitch(),
            localConversationUpdateDates: wipeOnContextSwitch(),
            channelUpdateDates: wipeOnContextSwitch(),
            channelLastMessages: wipeOnContextSwitch(),
            messages: wipeOnContextSwitch(),
            notifications: wipeOnContextSwitch(),
            currentUser: wipeOnContextSwitch()
        }),
        fixCombineReducers({
            users: wipeOnLogout(),
            allClasses: wipeOnLogout(),
            roles: wipeOnLogout(),
            surveys: wipeOnLogout(),
            yearsHired: wipeOnLogout(),
            offices: wipeOnLogout(),
            preferences: wipeOnLogout(),
            userSearchResults: wipeOnContextSwitch(),
            notifyUsersSearchResults: wipeOnLogout(),
            surveyUsersSearchResults: wipeOnLogout(),
            userSearchMeta: wipeOnLogout(userSearchMetaInitialState),
            notifyUserSearchMeta: wipeOnLogout(notifyUserSearchMetaInitialState),
            surveyUserSearchMeta: wipeOnLogout(surveyUserSearchMetaInitialState),
            classSearchResults: wipeOnLogout(),
            channels: wipeOnLogout(),
            unreadMessageCounts: wipeOnLogout(),
            messageCounts: wipeOnLogout(),
            unreadLocalMessageCounts: wipeOnLogout(),
            localMessageCounts: wipeOnLogout(),
            localConversationUpdateDates: wipeOnLogout(),
            channelUpdateDates: wipeOnLogout(),
            channelLastMessages: wipeOnLogout(),
            messages: wipeOnLogout(),
            notifications: wipeOnLogout(),
            currentUser: wipeOnLogout()
        })
    );
};

// This is necessary because combineReducers() strips keys it doesn't know about
const fixCombineReducers = (reducersHash) => {

    const combined = combineReducers(reducersHash);
    const keys = Object.keys(reducersHash);

    return (state, action) => {

        const nextPartialState = combined(Pick(state, keys), action);
        const noChanges = keys.every((key) => state[key] === nextPartialState[key]);

        return noChanges ? state : { ...state, ...nextPartialState };
    };
};

const wipeOnContextSwitch = (initial = null) => (state = initial, action) => { // eslint-disable-line

    switch (action.type) {
        case APP.SET_APP_CONTEXT:
            // Note this section will be removed in the context updates
            // eslint-disable-next-line
            const { context } = require('../app-context');

            // Note this section will be removed in the context updates
            // eslint-disable-next-line
            const redux = context.redux.hooks;

            if (redux.getState().app.appContext === null) {
                return state;
            }

            return initial;
        default:
            return state;
    }
};

const wipeOnLogout = (initial = null) => (state = initial, action) => { // eslint-disable-line

    switch (action.type) {
        case AUTH.LOGOUT.SUCCESS:
        case AUTH.CLOSE_ACCOUNT.SUCCESS:
        case AUTH.ACCOUNT_CLOSED_CLEAR:
            return initial;
        default:
            return state;
    }
};

const reducer = combineReducers({ entities: makeEntities(), index: makeIndex() });

const calc = (state) => {

    const makeGetEntity = (type) => (id) => state.entities[type] && state.entities[type][id]; // eslint-disable-line hapi/no-arrowception

    const school = makeGetEntity('schools');
    const schoolExtension = makeGetEntity('schoolExtensions');
    const class_ = makeGetEntity('classes');
    const survey = makeGetEntity('surveys');
    const surveyQuestion = makeGetEntity('surveyQuestions');
    const questionAnswer = makeGetEntity('questionAnswers');
    const interest = makeGetEntity('interests');
    const roleGroup = makeGetEntity('roleGroups');
    const role = makeGetEntity('roles');
    const badgeType = makeGetEntity('badgeTypes');
    const conversationStarter = makeGetEntity('conversationStarters');
    const yearHired = makeGetEntity('yearsHired');
    const office = makeGetEntity('offices');
    const category = makeGetEntity('categories');
    const housing = makeGetEntity('housing');
    const user = makeGetEntity('users');
    const userSimilarity = makeGetEntity('userSimilarities');
    const userExtension = makeGetEntity('userExtensions');
    const classExtension = makeGetEntity('classExtensions');
    const channel = makeGetEntity('channels');
    const message = makeGetEntity('messages');
    const notification = makeGetEntity('notifications');
    const transfer = makeGetEntity('transfers');
    const transferExtension = makeGetEntity('transferExtensions');
    const major = makeGetEntity('majors');
    const department = makeGetEntity('departments');
    const localConversation = makeGetEntity('localConversations');
    const localMessage = makeGetEntity('localMessages');


    const schools = () => state.index.schools && state.index.schools.map(school);
    const allClasses = () => state.index.allClasses && state.index.allClasses.map(class_);
    const surveys = () => state.index.surveys && state.index.surveys.map(survey);
    const classes = () => state.entities.classes && Object.keys(state.entities.classes);
    const interests = () => state.index.interests && state.index.interests.map(interest);
    const roleGroups = () => state.index.roleGroups && state.index.roleGroups.map(roleGroup);
    const roles = () => state.index.roles && state.index.roles.map(role);
    const badgeTypes = () => state.index.badgeTypes && state.index.badgeTypes.map(badgeType);
    const conversationStarters = () => state.index.conversationStarters && state.index.conversationStarters.map(conversationStarter);
    const offices = () => state.index.offices && state.index.offices.map(office);
    const yearsHired = () => state.index.yearsHired && state.index.yearsHired.map(yearHired);
    const categories = () => state.index.categories && state.index.categories.map(category);
    const localConversations = () => state.index.localConversations && state.index.localConversations.map(localConversation);
    const localMessages = () => state.index.localMessages && state.index.localMessages.map(localMessage);
    const users = () => state.index.users && state.index.users.map(user);
    const userSearchResults = () => state.index.userSearchResults && state.index.userSearchResults.map(({ user: id }) => user(id));
    const notifyUsersSearchResults = () => state.index.notifyUsersSearchResults && state.index.notifyUsersSearchResults.map((userId) => user(userId));
    const surveyUsersSearchResults = () => state.index.surveyUsersSearchResults && state.index.surveyUsersSearchResults.map((userId) => user(userId));
    const classUserSearchResults = (classId) => state.index.classUserSearchResults[classId] && state.index.classUserSearchResults[classId].searchResults.map(({ user: id }) => user(id));
    const userExtensions = () => state.index.userExtensions && state.index.userExtensions.map(userExtension);
    const channels = () => state.index.channels && state.index.channels.map(channel);
    const surveyQuestions = () => state.entities.surveyQuestions && Object.keys(state.entities.surveyQuestions).map(surveyQuestion);
    const questionAnswers = () => state.entities.questionAnswers && Object.keys(state.entities.questionAnswers).map(questionAnswer);
    const messages = () => state.index.messages && state.index.messages.map(message);
    const notifications = () => state.index.notifications && state.index.notifications.map(notification);
    const classSearchResults = () => state.index.classSearchResults && state.index.classSearchResults.map(class_);
    const unreadMessageCounts = () => state.index.unreadMessageCounts;
    const messageCounts = () => state.index.messageCounts;
    const unreadLocalMessageCounts = () => state.index.unreadLocalMessageCounts;
    const localMessageCounts = () => state.index.localMessageCounts;
    const preferences = () => state.index.preferences;
    const userSearchMeta = () => state.index.userSearchMeta;
    const notifyUserSearchMeta = () => state.index.notifyUserSearchMeta;
    const surveyUserSearchMeta = () => state.index.surveyUserSearchMeta;
    const scheduledNotifications = () => state.index.scheduledNotifications;

    const currentUser = {
        details: () => state.index.currentUser,
        user: () => currentUser.details() && user(currentUser.details().id),
        userExtension: () => currentUser.details() && userExtension(currentUser.details().id),

        peers: () => currentUser.userExtension() && currentUser.userExtension().peers.map(user),
        classes: () => currentUser.userExtension() && currentUser.userExtension().classes.map(class_)
    };

    const peerStatus = (u) => {

        if (!currentUser.userExtension() || filter.user.isCurrentUser(u)) {
            return undefined;
        }

        const peersIn = currentUser.details().peersIn;
        const peersOut = currentUser.details().peersOut;
        const peersDeclined = currentUser.details().peersDeclined;
        const peersDeclinedBy = currentUser.details().peersDeclinedBy;

        if (peersDeclined.includes(u.id)) {
            // you declined peering
            return 'declinedByMe';
        }

        // TODO need to determine if they declined here
        if (currentUser.userExtension().peers.includes(u.id)) {
            return 'accepted';
        }

        if (peersOut.includes(u.id) && !peersDeclinedBy.includes(u.id)) {
            // waiting on their answer
            return 'waiting';
        }

        if (peersDeclinedBy.includes(u.id)) {
            // waiting on their answer
            return 'declinedByThem';
        }

        if (peersIn.includes(u.id)) {
            // waiting on your answer
            return 'pending';
        }

        // NOTE, if the other user declined you,
        // code will reach this point and peerStatus will
        // return undefined. The only way I know to show
        // if a user declined you is to read from the
        // `declinedBy` list from the API.

        return undefined;
    };

    const numUnreads = (c) => {

        if (!c || !state.index.unreadMessageCounts || !Object.prototype.hasOwnProperty.call(state.index.unreadMessageCounts, c.sid)) {
            return null;
        }

        return state.index.unreadMessageCounts[c.sid];
    };

    const numOfMessages = (c) => {

        if (!c || !state.index.messageCounts || !Object.prototype.hasOwnProperty.call(state.index.messageCounts, c.sid)) {
            return null;
        }

        return c && state.index.messageCounts && state.index.messageCounts[c.sid];
    };

    const numUnreadsLocal = (conversation) => {

        if (!conversation || !state.index.unreadLocalMessageCounts || !Object.prototype.hasOwnProperty.call(state.index.unreadLocalMessageCounts, conversation.id)) {
            return null;
        }

        return state.index.unreadLocalMessageCounts[conversation.id];
    };

    const numOfMessagesLocal = (conversation) => {

        if (!conversation || !state.index.localMessageCounts || !Object.prototype.hasOwnProperty.call(state.index.localMessageCounts, conversation.id)) {
            return null;
        }

        return conversation && state.index.localMessageCounts && state.index.localMessageCounts[conversation.id];
    };

    const channelUpdateDate = (c) => c && state.index.channelUpdateDates && state.index.channelUpdateDates[c.sid] || null;
    const localConversationUpdateDate = (c) => c && state.index.localConversationUpdateDates && state.index.localConversationUpdateDates[c.id] || null;
    const channelLastMessage = (c) => c && state.index.channelLastMessages && state.index.channelLastMessages[c.sid] || null;

    const filter = {
        filterOutEmptyOrUndefined: (item) => !!item,
        channel: {
            withLoadedUsers: (c) => c.userIds && c.userIds.every(user),
            isDM: (c) => {

                if (c.type !== 'dm') {
                    return false;
                }

                const u = First(transform({
                    source: c.userIds && c.userIds.map(user),
                    filter: filter.user.isNotCurrentUser
                }));

                return !!u && (peerStatus(u) === 'accepted');
            },
            isClass: (c) => c.type === 'class',
            isClassOrGroup: (c) => c.type === 'class' || c.type === 'group',
            isLoggedInUserClass: (cSid) => !!currentUser.userExtension().classes.map(class_).find((c) => c.sid === cSid)
        },
        conversation: {
            withLoadedUsers: (c) => c.userIds && c.userIds.every(user),
            isLoggedInUserClass: (cId) => !!currentUser.userExtension().classes.map(class_).find((c) => c.conversationId === parseInt(cId))
        },
        user: {
            isCurrentUser: (u) => currentUser.user() && u && currentUser.user().id === u.id,
            isNotCurrentUser: (u) => currentUser.user() && u && !filter.user.isCurrentUser(u)
        },
        message: {
            isInChannel: (channelSid) => {

                return (m) => m.channel === channelSid;
            },
            isInLocalConversation: (classId) => {

                return (m) => {

                    return parseInt(m.classId) === parseInt(classId);
                };
            }
        },
        surveyQuestion: {
            isInSurvey: (surveyId) => {

                return (m) => m.surveyId === surveyId;
            }
        },
        questionAnswer: {
            isInQuestion: (questionId) => {

                return (m) => m.questionId === questionId;
            }
        },
        notification: {
            isUnread: (n) => !n.read,
            mentionInChannel: (channelSid) => (n) => (n.type === 'mention') && (n.channelSid === channelSid), // eslint-disable-line
            notStaleMention: (n) => {

                const { type, channelSid } = n;

                if (type !== 'mention') {
                    return true;
                }

                return (numUnreads(channel(channelSid)) !== 0);
            },
            notDeclined: (n) => n.marked !== 'declined',
            notStaleTransfer: (n) => {

                if (n.type !== 'transfer') {
                    return true;
                }

                if (n.marked === 'accepted') {
                    return false;
                }

                const tx = n.transfer && transferExtension(n.transfer.extension);
                const ux = currentUser.userExtension();

                if (tx && ux && ux.classes) {
                    return !ux.classes.some((cId) => cId === tx.class);
                }

                return true;
            }
        },
        class: {
            hasSid: (sid) => (c) => c.sid === sid // eslint-disable-line hapi/no-arrowception
        },
        school: {
            hasSlug: (slug) => (s) => s.slug === slug // eslint-disable-line hapi/no-arrowception
        },
        housing: {
            onCampus: (h) => h.onCampus === true,
            offCampus: (h) => h.onCampus === false
        },
        badge: {
            forUserRole: (b) => {

                const u = currentUser.user();

                if (u) {
                    //CHECK WITH BRIAN TO SEE IF HE WANTS THIS   return b.roleId === u.roleId || b.roleId === null;
                    return b.roleId === u.roleId;
                }

                const { context } = require('../app-context');

                const redux = context.redux.hooks;
                const signupContext = context.selectors.all.getSignupDetailsForStep3(redux.getState());
                const roleId = signupContext && signupContext.role && signupContext.role.id;
                return b.roleId === roleId;
            }
        }
    };

    const sort = {
        users: {
            instructorsFirst: (a, b) => {

                return a.type === 'instructor' ? -1 : 1;
            }
        },
        classes: {
            transferFirst: (t) => {

                return (a, b) => {

                    // Maintain groups on top
                    return (t.classId === a.id) || (a.type === 'group') ? -1 : 1;
                };
            }
        },

        categories: {
            specialFirst: (a, b) => {

                // Maintain groups on top
                const aSpecial = a && a.schoolId !== null;
                const bSpecial = b && b.schoolId !== null;


                return (Number(bSpecial) - Number(aSpecial)) || (a.name.localeCompare(b.name));
            }

        },
        notification: {
            byCreatedAt: (a, b) => {

                return (new Date(a.createdAt) < new Date(b.createdAt)) ? 1 : -1; // Newest first
            }
        },
        message: {
            byTimestamp: (a, b) => {

                return (new Date(a.timestamp) < new Date(b.timestamp)) ? -1 : 1; // Newest last
            }
        },
        badge: {
            alphabetically: (a, b) => {

                return a.label.localeCompare(b.label);
            }
        },
        conversationStarter: {
            alphabetically: (a, b) => {

                return a.starterText.localeCompare(b.starterText);
            }
        },
        yearHired: {
            byYearAsc: (a, b) => {

                return a.year > b.year ? 1 : -1;
            },
            byYearDesc: (a, b) => {

                return a.year > b.year ? -1 : 1;
            }
        },
        office: {
            alphabetically: (a, b) => {

                return a.name.localeCompare(b.name);
            }
        },
        housing: {
            alphabetically: (a, b) => {

                return a.name.localeCompare(b.name);
            }
        },
        surveyQuestion: {
            byOrdinal: (a, b) => {

                return a.ordinal > b.ordinal ? 1 : -1; // Newest first
            }
        },
        questionAnswer: {
            byOrdinal: (a, b) => {

                return a.ordinal > b.ordinal ? 1 : -1; // Newest first
            }
        }
    };

    const nonNull = (x) => x !== null;

    const format = {
        user: {
            basic: (u) => {

                const userRole = u && u.roleId && role(u.roleId);

                //TODO popraviti ovisnost unutar aplikaacije o u.permissions
                return {
                    id: u.id || null,
                    firstName: u.firstName || '',
                    lastName: u.lastName || '',
                    croppedPicture: u.croppedPicture || '',
                    major: u.major && First(u.major),
                    majors: u.majors,
                    title: u.title || '',
                    department: department(u.department) && format.department.name(department(u.department)),
                    office: office(u.office) && format.office.name(office(u.office)),
                    yearHired: yearHired(u.yearHired) && format.yearHired.year(yearHired(u.yearHired)),
                    isMe: filter.user.isCurrentUser(u),
                    peerStatus: peerStatus(u) || null,
                    enabled: u.enabled,
                    type: u.type,
                    permissions: u.permissions || null,
                    roleId: u.roleId || null,
                    role: userRole || null,
                    studentName: u.studentName || ''
                };
            },
            basicWithPassionInterests: (u) => {

                const userRole = u && u.roleId && role(u.roleId);

                const ux = userExtension(u.id);

                //TODO popraviti ovisnost unutar aplikaacije o u.permissions
                return {
                    id: u.id || null,
                    firstName: u.firstName || '',
                    lastName: u.lastName || '',
                    croppedPicture: u.croppedPicture || '',
                    major: u.major && First(u.major),
                    majors: u.majors,
                    title: u.title || '',
                    department: department(u.department) && format.department.name(department(u.department)),
                    office: office(u.office) && format.office.name(office(u.office)),
                    yearHired: yearHired(u.yearHired) && format.yearHired.year(yearHired(u.yearHired)),
                    isMe: filter.user.isCurrentUser(u),
                    peerStatus: peerStatus(u) || null,
                    enabled: u.enabled,
                    type: u.type,
                    permissions: u.permissions || null,
                    roleId: u.roleId || null,
                    role: userRole || null,
                    studentName: u.studentName || '',
                    interests: ux?.interests?.map(interest) || u?.interests?.map(interest) || [],
                    passionInterests: ux?.passionInterests?.map(interest) || u?.passionInterests?.map(interest) || [],
                    matchBasics: u.matchBasics,
                    matchDetails: u.matchDetails
                };
            },
            searchResult: (u) => ({
                ...format.user.basicWithPassionInterests(u),
                similarities: userSimilarity(u.id) && format.userSimilarity.basic(userSimilarity(u.id)),
                isClass: false
            }),
            classSearchResult: (u) => ({
                ...format.user.basicWithPassionInterests(u),
                similarities: userSimilarity(u.id) && format.userSimilarity.basic(userSimilarity(u.id)),
                isClass: true
            }),
            signupDetails: (u) => {

                const ux = userExtension(u.id);
                const h = (ux && ux.housing && ux.housing.schema === 'ref') && housing(ux.housing.id);
                const { sms } = (preferences() || {});

                return {
                    type: u.type || null,
                    picFile: null,
                    croppedPicture: u.croppedPicture || '',
                    picture: u.picture || '',
                    firstName: u.firstName || null,
                    lastName: u.lastName || null,
                    profileEmail: u.profileEmail || null,
                    birthdate: (u.birthdate && new Date(u.birthdate)) || null,
                    majorId: u.majors && First(u.majors) || null,
                    departmentId: u.department || null,
                    officeId: u.office || null,
                    yearHiredId: u.yearHired || null,
                    workRemote: u.workRemote,
                    career: u.career || null,
                    profession: u.profession || null,
                    bio: u.bio || '',
                    studentName: u.studentName || '',
                    studentBio: u.studentBio || '',
                    interests: (!!interests && (ux && ux.interests)) ? ux.interests.map(interest) : [],
                    passionInterests: (!!interests && (ux && ux.passionInterests)) ? ux.passionInterests.map(interest) : [],
                    hometown: {
                        id: u.hometown && u.hometown.id || null,
                        placeId: u.hometown && u.hometown.placeId || null,
                        name: u.hometown && u.hometown.name || null,
                        onCampus: u.hometown && u.hometown.onCampus || false
                    },
                    housing: h ? format.housing.details(h) : ux && {
                        id: ux && ux.housing && ux.housing.id || null,
                        placeId: ux && ux.housing && ux.housing.placeId || null,
                        name: ux && ux.housing && ux.housing.name || null,
                        onCampus: ux && ux.housing && !!ux.housing.onCampus || false
                    },
                    transfer: ux && ux.transfer ? {
                        id: ux.transfer.id,
                        name: ux.transfer.id ? null : ux.transfer.name
                    } : {
                        id: null,
                        name: null
                    },
                    veteran: u.veteran,
                    openToSocial: u.openToSocial,
                    fullTime: u.fullTime,
                    gender: u.gender || null,
                    incomingClass: u.incomingClass || null,
                    graduatingClass: u.graduatingClass || null,
                    onlineStudent: u.onlineStudent || null,
                    parent: u.parent || null,
                    title: u.title || '',
                    enabled: u.enabled,
                    preferences: {
                        phone: u.phone || '',
                        phoneCountry: u.phoneCountry || 'us',
                        sms: !!(sms && sms === 'on')
                    }
                };
            },
            userProfileDiff: (newSignupDetailsWithPrefs) => {

                const {
                    preferences: {
                        phone,
                        phoneCountry,
                        // profileEmail,
                        sms
                    } = {},
                    ...signupDetails
                } = newSignupDetailsWithPrefs;

                const newSignupDetails = {
                    phone: phone || '',
                    phoneCountry: phoneCountry || '',
                    sms: sms ? 'on' : 'off',
                    ...signupDetails
                };

                const u = currentUser.user();
                const currentSignupDetails = format.user.signupDetails(u);

                const diff = (newValue, oldValue) => {

                    return !IsEqual(newValue, oldValue) ? newValue : undefined;
                };

                const stripNulls = (obj) => {

                    return Object.keys(obj).reduce((collector, key) => {

                        if (obj[key] === null) {
                            return collector;
                        }

                        return { ...collector, [key]: obj[key] };
                    }, {});
                };

                // Must send one of name or id
                const diffTransfers = (newT, oldT) => {

                    let diffT = diff(newT, oldT);

                    if (!diffT) {
                        return diffT;
                    }

                    diffT = stripNulls(diffT);

                    if (Object.keys(diffT).length === 0) {
                        if (oldT.name !== null) {
                            return { name: null };
                        }

                        return { id: null };
                    }

                    return diffT;
                };

                // FormData() casts everything to a string via String(myVal), so null becomes 'null'
                // This will fix it by making null emptystring

                const fixedNewSignupDetails = Object.keys(newSignupDetails).reduce((collector, key) => {

                    const item = collector[key];
                    if (item === null) {
                        collector[key] = '';
                    }

                    return collector;
                }, newSignupDetails);

                const fixedCurrentSignupDetails = Object.keys(currentSignupDetails).reduce((collector, key) => {

                    const item = collector[key];
                    if (item === null) {
                        collector[key] = '';
                    }

                    return collector;
                }, currentSignupDetails);

                const majorDiff = diff(fixedNewSignupDetails.majorId, fixedCurrentSignupDetails.majorId);

                return OmitBy({
                    type: diff(fixedNewSignupDetails.type, fixedCurrentSignupDetails.type),
                    firstName: diff(fixedNewSignupDetails.firstName, fixedCurrentSignupDetails.firstName),
                    lastName: diff(fixedNewSignupDetails.lastName, fixedCurrentSignupDetails.lastName),
                    profileEmail: diff(fixedNewSignupDetails.profileEmail, fixedCurrentSignupDetails.profileEmail),
                    birthdate: diff(fixedNewSignupDetails.birthdate, fixedCurrentSignupDetails.birthdate),
                    major: (majorDiff === undefined) ? undefined : (majorDiff ? [majorDiff] : []), // Handling issue where diff above can turn-up ''
                    department: diff(fixedNewSignupDetails.departmentId, fixedCurrentSignupDetails.departmentId),
                    workRemote: diff(fixedNewSignupDetails.workRemote, fixedCurrentSignupDetails.workRemote),
                    officeId: diff(fixedNewSignupDetails.officeId, fixedCurrentSignupDetails.officeId),
                    yearHiredId: diff(fixedNewSignupDetails.yearHiredId, fixedCurrentSignupDetails.yearHiredId),
                    career: diff(fixedNewSignupDetails.career, fixedCurrentSignupDetails.career),
                    profession: diff(fixedNewSignupDetails.profession, fixedCurrentSignupDetails.profession),
                    bio: diff(fixedNewSignupDetails.bio, fixedCurrentSignupDetails.bio),
                    interests: diff(fixedNewSignupDetails.interests, fixedCurrentSignupDetails.interests) && fixedNewSignupDetails.interests && JSON.stringify(Map(fixedNewSignupDetails.interests, 'id')),
                    passionInterests: fixedNewSignupDetails.passionInterests && JSON.stringify(Map(fixedNewSignupDetails.passionInterests, 'id')),
                    transfer: diffTransfers(fixedNewSignupDetails.transfer, fixedCurrentSignupDetails.transfer),
                    veteran: diff(fixedNewSignupDetails.veteran, fixedCurrentSignupDetails.veteran),
                    openToSocial: diff(fixedNewSignupDetails.openToSocial, fixedCurrentSignupDetails.openToSocial),
                    fullTime: diff(fixedNewSignupDetails.fullTime, fixedCurrentSignupDetails.fullTime),
                    gender: diff(fixedNewSignupDetails.gender, fixedCurrentSignupDetails.gender),
                    ethnicity: diff(fixedNewSignupDetails.ethnicity, fixedCurrentSignupDetails.ethnicity),
                    hometown: diff(fixedNewSignupDetails.hometown, fixedCurrentSignupDetails.hometown),
                    housing: diff(fixedNewSignupDetails.housing, fixedCurrentSignupDetails.housing),
                    incomingClass: diff(fixedNewSignupDetails.incomingClass, fixedCurrentSignupDetails.incomingClass),
                    graduatingClass: diff(fixedNewSignupDetails.graduatingClass, fixedCurrentSignupDetails.graduatingClass),
                    onlineStudent: diff(fixedNewSignupDetails.onlineStudent, fixedCurrentSignupDetails.onlineStudent),
                    parent: diff(fixedNewSignupDetails.parent, fixedCurrentSignupDetails.parent),
                    title: diff(fixedNewSignupDetails.title, fixedCurrentSignupDetails.title),
                    studentName: diff(fixedNewSignupDetails.studentName, fixedCurrentSignupDetails.studentName),
                    studentBio: diff(fixedNewSignupDetails.studentBio, fixedCurrentSignupDetails.studentBio),
                    phone: diff(fixedNewSignupDetails.phone, fixedCurrentSignupDetails.phone),
                    phoneCountry: diff(fixedNewSignupDetails.phoneCountry, fixedCurrentSignupDetails.phoneCountry),
                    sms: diff(fixedNewSignupDetails.sms, fixedCurrentSignupDetails.sms)
                }, IsUndefined);
            },
            userProfilePictureDiff: (newSignupDetails) => {
                // FormData() casts everything to a string via String(myVal), so null becomes 'null'
                // This will fix it by making null emptystring

                const fixedNewSignupDetails = Object.keys(newSignupDetails).reduce((collector, key) => {

                    const item = collector[key];

                    if (item === null || item === undefined) {
                        collector[key] = '';
                    }

                    return collector;
                }, newSignupDetails);

                return OmitBy({
                    // eslint-disable-next-line no-extra-boolean-cast
                    picture: fixedNewSignupDetails.picture !== '' ? fixedNewSignupDetails.picture : undefined,
                    // eslint-disable-next-line no-extra-boolean-cast
                    cropRotation: fixedNewSignupDetails.cropRotation !== '' ? fixedNewSignupDetails.cropRotation : undefined,
                    // eslint-disable-next-line no-extra-boolean-cast
                    cropScale: fixedNewSignupDetails.cropScale.length !== '' ? fixedNewSignupDetails.cropScale : undefined,
                    // eslint-disable-next-line no-extra-boolean-cast
                    cropPosition: fixedNewSignupDetails.cropPosition !== '' ? fixedNewSignupDetails.cropPosition : undefined
                }, IsUndefined);
            },
            fullDetails: (u) => {

                const ux = userExtension(u.id);
                const t = (ux && ux.transfer && ux.transfer.schema === 'ref') && transfer(ux.transfer.id);
                const h = (ux && ux.housing && ux.housing.schema === 'ref') && housing(ux.housing.id);

                const userRole = u && u.roleId && role(u.roleId);

                const lastActiveDate = u.lastActiveAt && Moment(u.lastActiveAt).calendar(null, {
                    lastDay: '[Yesterday]',
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    lastWeek: 'dddd',
                    nextWeek: 'dddd',
                    sameElse: 'MMMM D, YYYY'
                });

                const userClasses = ux && ux.classes && ux.classes.map(class_).map(format.class.mini) || [];

                return {
                    user: {
                        id: u.id,
                        croppedPicture: u.croppedPicture || '',
                        firstName: u.firstName || '',
                        lastName: u.lastName || '',
                        profileEmail: u.profileEmail || null,
                        birthdate: (u.birthdate && new Date(u.birthdate)) || null,
                        age: (u.age && u.age) || null,
                        major: u.major && First(u.major) || null,
                        majors: (u.majors || []).map(major),
                        department: department(u.department) && format.department.name(department(u.department)),
                        office: office(u.office) && format.office.name(office(u.office)),
                        yearHired: yearHired(u.yearHired) && format.yearHired.year(yearHired(u.yearHired)),
                        career: u.career || null,
                        profession: u.profession || null,
                        bio: u.bio || null,
                        studentName: u.studentName || '',
                        studentBio: u.studentBio || '',
                        veteran: u.veteran,
                        openToSocial: u.openToSocial,
                        workRemote: u.workRemote,
                        fullTime: u.fullTime,
                        gender: u.gender || null,
                        type: u.type || null,
                        lastActive: lastActiveDate || null,
                        incomingClass: u.incomingClass || null,
                        graduatingClass: u.graduatingClass || null,
                        onlineStudent: u.onlineStudent || null,
                        role: userRole || null,
                        parent: u.parent || null,
                        title: u.title || '',
                        enabled: u.enabled,
                        hometown: u.hometown || null,
                        permissions: u.permissions || null
                    },
                    school: ux && ux.school && school(ux.school) && school(ux.school).name || null,
                    interests: ux && ux.interests && ux.interests.map(interest) || [],
                    passionInterests: ux && ux.passionInterests && ux.passionInterests.map(interest) || [],
                    classes: userClasses,
                    peers: ux && ux.peers && ux.peers.map(user).map(format.user.basic) || [],
                    peerStatus: peerStatus(u),
                    similarities: userSimilarity(u.id) && format.userSimilarity.basic(userSimilarity(u.id)),
                    hometown: {
                        id: u.hometown && u.hometown.id || null,
                        placeId: u.hometown && u.hometown.placeId || null,
                        name: u.hometown && u.hometown.name || null,
                        onCampus: u.hometown && u.hometown.onCampus || false
                    },
                    housing: h ? format.housing.details(h) : ux && {
                        id: ux && ux.housing && ux.housing.id || null,
                        placeId: ux && ux.housing && ux.housing.placeId || null,
                        name: ux && ux.housing && ux.housing.name || null,
                        onCampus: ux && ux.housing && !!ux.housing.onCampus || false
                    },
                    transfer: t ? format.transfer.basic(t) : ux && ux.transfer && {
                        name: ux.transfer.name
                    },
                    isMe: filter.user.isCurrentUser(u)
                };
            },
            fullName: (u) => [u.firstName, u.lastName].filter((x) => !!x).join(' '),
            firstName: (u) => Capitalize(u.firstName)
        },
        userSimilarity: {
            basic: (us) => ({
                distance: (typeof us.distance !== 'undefined') ? us.distance.toLocaleString('en-US') : null,
                veteran: us.veteran,
                online: us.online,
                partTime: us.partTime,
                transfer: us.transfer,
                passionInterests: us.passionInterests.map(interest).filter(nonNull),
                interests: us.interests.map(interest).filter((item) => {

                    return !us.passionInterests.map(interest).some((passionInterest) => passionInterest.id === item.id) && item !== null;
                }),
                majors: us.majors.map(major).filter(nonNull),
                classes: us.classes.map(class_).filter(nonNull).map(format.class.basic),
                parent: us.parent
            })
        },
        channel: {
            basic: (c) => ({    // Used for DMs
                sid: c.sid,
                dateUpdated: channelUpdateDate(c),
                lastMessage: channelLastMessage(c),
                numUnreads: numUnreads(c) || 0,
                numOfMessages: numOfMessages(c) || 0,
                attributes: {
                    user: First(transform({
                        source: c.userIds && c.userIds.map(user),
                        filter: filter.user.isNotCurrentUser,
                        map: format.user.basic
                    }))
                }
            }),
            forClass: (c) => ({    // Used for class chat
                sid: c.sid,
                dateUpdated: channelUpdateDate(c),
                numUnreads: numUnreads(c) || 0,
                numOfMessages: numOfMessages(c) || 0
            })
        },
        localConversation: {
            forClass: (c) => ({    // Used for class chat
                sid: c.id,
                dateUpdated: localConversationUpdateDate(c),
                numUnreads: numUnreadsLocal(c) || 0,
                numOfMessages: numOfMessagesLocal(c) || 0
            })
        },
        message: {
            basic: (m) => ({
                sid: m.sid,
                id: m.id,
                channelSid: m.channel,
                conversationId: m.conversation,
                classId: m.classId || null,
                index: m.index,
                body: m.body,
                timestamp: m.timestamp,
                moderationStatus: m.moderationStatus,
                isPinned: m.isPinned,
                isEdited: m.isEdited,
                user: transform({
                    source: user(m.author),
                    map: format.user.basic
                })
            })
        },
        class: {
            basic: (c) => {

                if (!c) {
                    return null;
                }

                const firstEmoji = c.emojiSymbol ? SelectFirstEmojiFromString(c.emojiSymbol) : '';

                const classRoleGroupsIds = c.roles?.map((usedRole) => role(usedRole)).map((newRole) => newRole.roleGroupId) || [];

                const usedRoleGroups = classRoleGroupsIds.map((id) => roleGroup(id)) || [];

                return {
                    id: c.id,
                    name: c.name,
                    emojiSymbol: firstEmoji,
                    sid: c.sid,
                    misc: c.misc,
                    semester: c.semester,
                    isAnnouncement: c.isAnnouncement,
                    facilitator: c.professor,
                    shortDescription: c.department,
                    number: c.number,
                    additionalDescription: c.schedule,
                    campus: c.campus,
                    classLocation: c.location,
                    channel: transform({
                        source: c.sid && channel(c.sid),
                        map: format.channel.forClass
                    }),
                    conversation: transform({
                        source: c.conversationId && localConversation(c.conversationId),
                        map: format.localConversation.forClass
                    }),
                    type: c.type,
                    roles: c.roles || [],
                    roleGroups: usedRoleGroups,
                    private: c.private,
                    userPermissions: c.userPermissions,
                    notificationLevel: c.notificationLevel
                };
            },
            mini: (c) => {

                const firstEmoji = c && c.emojiSymbol ? SelectFirstEmojiFromString(c.emojiSymbol) : '';

                return {
                    id: c.id,
                    name: c.name,
                    emojiSymbol: firstEmoji,
                    sid: c.sid,
                    isAnnouncement: c.isAnnouncement,
                    private: c.private,
                    type: c.type,
                    notificationLevel: c.notificationLevel
                };
            },
            id: (c) => c.id,
            name: (c) => c.name,
            details: (c) => {

                const extraDetailProps = {
                    pinnedInfo: c.pinnedInfo
                };

                return {
                    class: Object.assign({},
                        format.class.basic(c),
                        extraDetailProps
                    ),
                    users: transform({
                        source: c.users || [],
                        sort: sort.users.instructorsFirst,
                        map: format.user.classSearchResult
                    })
                };
            },
            detailsWithUserSearchResults: (c) => {

                const extraDetailProps = {
                    pinnedInfo: c.pinnedInfo
                };

                return {
                    class: Object.assign({},
                        format.class.basic(c),
                        extraDetailProps
                    ),
                    users: transform({
                        source: classUserSearchResults(c.id) || [],
                        sort: sort.users.instructorsFirst,
                        map: format.user.classSearchResult
                    })
                };
            }
        },
        survey: {
            basic: (s) => {

                let surveyStatus = 'active';

                if (s.isActive === false && Moment(s.startDate).isAfter(Moment())) {
                    surveyStatus = 'upcoming';
                }

                if (s.isActive === false && !Moment(s.startDate).isAfter(Moment())) {
                    surveyStatus = 'finished';
                }

                return {
                    id: s.id,
                    title: s.title,
                    description: s.description,
                    startDate: s.startDate,
                    endDate: s.endDate,
                    isActive: s.isActive,
                    status: surveyStatus,
                    schoolId: s.schoolId,
                    invitedUsersCount: s.invitedUsersCount,
                    school: s.school ? s.school.name : null
                };
            },
            detailed: (s) => {
                const sQuestions = transform({
                    source: surveyQuestions(),
                    filter: filter.surveyQuestion.isInSurvey(s.id),
                    sort: sort.surveyQuestion.byOrdinal,
                    map: format.surveyQuestion.basic
                });

                if (sQuestions) {
                    sQuestions.forEach((question) => {

                        const qAnswers = transform({
                            source: questionAnswers(),
                            filter: filter.questionAnswer.isInQuestion(question.id),
                            sort: sort.questionAnswer.byOrdinal,
                            map: format.questionAnswer.basic
                        });
                        question.answers = qAnswers;
                    });
                }

                let surveyStatus = 'active';

                if (s.isActive === false && Moment(s.startDate).isAfter(Moment())) {
                    surveyStatus = 'upcoming';
                }

                if (s.isActive === false && !Moment(s.startDate).isAfter(Moment())) {
                    surveyStatus = 'finished';
                }

                return {
                    id: s.id,
                    title: s.title,
                    description: s.description,
                    startDate: s.startDate,
                    endDate: s.endDate,
                    isActive: s.isActive,
                    status: surveyStatus,
                    notifyUsers: s.notifyUsers,
                    manuallyStop: !s.endDate,
                    questions: sQuestions || [],
                    schoolId: s.schoolId
                };
            },
            withQuestionAnswers: (s) => {

                const sQuestions = transform({
                    source: surveyQuestions(),
                    filter: filter.surveyQuestion.isInSurvey(s.id),
                    sort: sort.surveyQuestion.byOrdinal,
                    map: format.surveyQuestion.withAnswers
                });

                if (sQuestions) {
                    sQuestions.forEach((question) => {

                        const qAnswers = transform({
                            source: questionAnswers(),
                            filter: filter.questionAnswer.isInQuestion(question.id),
                            sort: sort.questionAnswer.byOrdinal,
                            map: format.questionAnswer.basic
                        });
                        question.answers = qAnswers;
                    });
                }

                let surveyStatus = 'active';

                if (s.isActive === false && Moment(s.startDate).isAfter(Moment())) {
                    surveyStatus = 'upcoming';
                }

                if (s.isActive === false && !Moment(s.startDate).isAfter(Moment())) {
                    surveyStatus = 'finished';
                }

                return {
                    id: s.id,
                    title: s.title,
                    description: s.description,
                    startDate: s.startDate,
                    endDate: s.endDate,
                    isActive: s.isActive,
                    status: surveyStatus,
                    notifyUsers: s.notifyUsers,
                    manuallyStop: !s.endDate,
                    questions: sQuestions || []
                };
            }
        },

        surveyQuestion: {
            basic: ({ id, text, freeInput, isActive, ordinal, img_url, multipleAnswers }) => ({ id, text, freeInput, isActive, ordinal, img_url, multipleAnswers, answerType: freeInput ? 'freeInput' : multipleAnswers ? 'checkbox' : 'select' }),
            withAnswers: ({ id, text, freeInput, isActive, ordinal, img_url, multipleAnswers, freeInputAnswer, selectedAnswer, checkboxAnswers }) => ({
                id,
                text,
                freeInput,
                isActive,
                ordinal,
                img_url,
                multipleAnswers,
                answerType: freeInput ? 'freeInput' : multipleAnswers ? 'checkbox' : 'select',
                freeInputAnswer,
                selectedAnswer,
                checkboxAnswers
            })
        },
        questionAnswer: {
            basic: ({ id, text, isActive, ordinal, img_url }) => ({ id, text, isActive, ordinal, img_url })
        },
        transfer: {
            basic: ({ id, name, classId }) => ({ id, name, classId }),
            details: (t) => {

                const tx = transferExtension(t.id);

                return {
                    transfer: format.transfer.basic(t),
                    class: transform({
                        source: class_(tx.class),
                        map: format.class.basic
                    })
                };
            }
        },
        housing: {
            basic: ({ id, name }) => ({ id, name }),
            details: ({ id, name, onCampus, placeId }) => ({ id, name, onCampus, placeId })
        },
        major: {
            basic: ({ id, name }) => ({ id, name }),
            name: ({ name }) => name
        },
        department: {
            basic: ({ id, name, used, usedBy }) => ({ id, name, used, usedBy }),
            name: ({ name }) => name
        },
        yearHired: {
            basic: ({ id, year }) => ({ id, year }),
            year: ({ year }) => year
        },
        office: {
            basic: ({ id, name }) => ({ id, name }),
            name: ({ name }) => name
        },
        school: {
            id: ({ id }) => id,
            isCompany: ({ isCompany }) => isCompany,
            isCommunity: ({ isCommunity }) => isCommunity,
            isOnline: ({ isOnline }) => isOnline,
            name: ({ name }) => name,
            slug: ({ slug }) => slug,
            hasResourcesPage: ({ hasResourcesPage }) => !!hasResourcesPage,
            resourcesUrl: ({ resourcesUrl }) => resourcesUrl,
            svg: ({ svg }) => svg,
            sso: ({ sso }) => sso,
            customSignupErrMsg: ({ customSignupErrMsg }) => customSignupErrMsg
        },
        badge: ({ svg, icon, ...rest }) => ({
            bgSvg: svg !== null ? svg : '',
            icon: icon !== null ? icon : '',
            ...rest
        })
    };

    const schema = {
        Notification: new S.Entity('notifications'),
        BasicUser: new S.Entity('users', {}, { processStrategy: format.user.basic })
    };

    schema.Notification.define({
        sender: schema.BasicUser
    });

    return {
        school,
        schoolExtension,
        class: class_,
        survey,
        classExtension,
        category,
        interest,
        badgeType,
        conversationStarter,
        yearHired,
        office,
        preferences,
        user,
        userExtension,
        surveyQuestion,
        questionAnswer,
        channel,
        message,
        notification,
        schools,
        allClasses,
        surveys,
        classes,
        interests,
        roleGroups,
        roles,
        categories,
        badgeTypes,
        conversationStarters,
        yearsHired,
        offices,
        users,
        userExtensions,
        surveyQuestions,
        questionAnswers,
        channels,
        messages,
        notifications,
        housing,
        major,
        department,
        currentUser,
        userSearchResults,
        notifyUsersSearchResults,
        surveyUsersSearchResults,
        userSearchMeta,
        notifyUserSearchMeta,
        surveyUserSearchMeta,
        classSearchResults,
        numUnreads,
        numOfMessages,
        filter,
        format,
        sort,
        schema,
        unreadMessageCounts,
        messageCounts,
        unreadLocalMessageCounts,
        localMessageCounts,
        numUnreadsLocal,
        numOfMessagesLocal,
        transfer,
        transferExtension,
        roleGroup,
        role,
        localConversations,
        localMessages,
        localConversation,
        localMessage,
        scheduledNotifications
    };
};

const selectors = exports.selectors = {
    PERMISSIONS,
    isParentPermission: (state) => {

        const { currentUser } = calc(state);

        const u = currentUser.user();

        if (u?.role) {
            return USER_ROLE_IDS.PARENT.includes(u.role.id);
        }

        // For during signup
        const { context } = require('../app-context');

        const redux = context.redux.hooks;
        const roleId = Number(redux.getState().signup.context.roleId);

        return USER_ROLE_IDS.PARENT.includes(roleId);
    },
    isStudentRoleGroup: (state, userId) => {

        const userRoleGroup = userId && selectors.getUserRoleGroup(state, userId);

        return userRoleGroup ? userRoleGroup.id === USER_ROLE_GROUPS_IDS.STUDENT : false;
    },
    getSchools: (state) => calc(state).schools() || [],
    getSchoolById: (state, schoolId) => calc(state).school(schoolId),
    getSchoolExtensionById: (state, schoolId) => calc(state).schoolExtension(schoolId),
    // eslint-disable-next-line hapi/hapi-scope-start
    getAllBadges: (state) => transform({
        source: calc(state).badgeTypes(),
        sort: calc(state).sort.badge.alphabetically,
        map: calc(state).format.badge
    }),
    // eslint-disable-next-line hapi/hapi-scope-start
    getAllConversationStarters: (state) => transform({
        source: calc(state).conversationStarters(),
        sort: calc(state).sort.conversationStarter.alphabetically
    }),
    // eslint-disable-next-line hapi/hapi-scope-start
    getAllYearsHired: (state) => transform({
        source: calc(state).yearsHired(),
        sort: calc(state).sort.yearHired.byYearDesc,
        map: calc(state).format.yearHired.basic
    }),
    // eslint-disable-next-line hapi/hapi-scope-start
    getAllOffices: (state) => transform({
        source: calc(state).offices(),
        sort: calc(state).sort.office.alphabetically,
        map: calc(state).format.office.basic
    }),
    // eslint-disable-next-line hapi/hapi-scope-start
    getBadgesForCurrentUser: (state) => {
        const isParent = selectors.isParentPermission(state);
        if (isParent) {
            const item_order = ['Parent', 'Grandparent', 'Other Family', 'Guardian', 'Supporter'];
            return transform({
                source: calc(state).badgeTypes(),
                filter: (b) => calc(state).filter.badge.forUserRole(b),
                sort: (a, b) => item_order.indexOf(a.label) - item_order.indexOf(b.label),
                map: calc(state).format.badge
            });
        }

        return transform({
            source: calc(state).badgeTypes(),
            filter: (b) => calc(state).filter.badge.forUserRole(b),
            sort: calc(state).sort.badge.alphabetically,
            map: calc(state).format.badge
        });
    },
    hasCurrentUser: (state) => !!calc(state).currentUser.details(),
    getInterests: (state) => {

        const { interests, interest, schoolExtension, filter } = calc(state);

        const sx = schoolExtension(selectors.getCurrentSchoolId(state));

        const schoolIsCompany = !!sx && !!sx.isCompany ? sx.isCompany : false;
        const allInterests = []
            .concat(interests())
            .concat((!sx || !sx.interests) ? [] : sx.interests.map(interest));

        if (schoolIsCompany) {

            return allInterests.filter((interestItem) => {

                return interestItem && interestItem.hideForCompanies === false;
            });
        }

        return allInterests.filter(filter.filterOutEmptyOrUndefined);
    },
    getCategories: (state) => {

        const { categories, category, schoolExtension, filter } = calc(state);

        const sx = schoolExtension(selectors.getCurrentSchoolId(state));

        return []
            .concat(categories())
            .concat((!sx || !sx.categories) ? [] : sx.categories.map(category)).filter(filter.filterOutEmptyOrUndefined);
    },
    getSortedCategories: (state) => {

        const { categories, category, schoolExtension, filter, sort } = calc(state);

        const sx = schoolExtension(selectors.getCurrentSchoolId(state));

        return []
            .concat(categories())
            .concat((!sx || !sx.categories) ? [] : sx.categories.map(category)).filter(filter.filterOutEmptyOrUndefined).sort(sort.categories.specialFirst);
    },
    getRoleGroups: (state) => {

        const { roleGroups } = calc(state);

        const allRoleGroups = roleGroups();
        const schoolRoles = selectors.getSchoolRoles(state);

        if (!allRoleGroups) {
            return [];
        }

        const currentUserRoleInteractions = selectors.getCurrentUserRoleInteractions(state);

        const filteredInteractions = currentUserRoleInteractions.filter((roleInteraction) => {

            return roleInteraction.canViewProfile === true;
        });

        const visibleRoleGroupsIds = [...new Set(filteredInteractions.map((interaction) => {

            return interaction.roleGroupId;
        }))];

        const usedRoleGroups = allRoleGroups.filter((roleGroup) => {

            return visibleRoleGroupsIds.includes(roleGroup.id);
        }).map((usedRoleGroup) => {
            //find min value for sort order base on roles sort order of that role group
            const roleGroupRolesSortOrders = schoolRoles.filter((role) => {

                return usedRoleGroup.id === role.roleGroupId;
            }).map(({ sortOrder }) => sortOrder).filter((sortOrder) => !!sortOrder);

            const minValue = Min(roleGroupRolesSortOrders);

            return {
                ...usedRoleGroup,
                sortOrder: minValue
            };
        });

        return []
            .concat(usedRoleGroups);
    },
    getFieldsPlaceholders: (state, schoolId) => {

        const user = calc(state).currentUser.user();
        const role = user && user.roleId && selectors.getCurrentUserRole(state);

        if (schoolId === undefined && role) {

            const schoolRoles = selectors.getSchoolRoles(state);
            const schoolRole = schoolRoles.find((item) => {

                return item.id === role.id;
            });

            if (schoolRole) {
                return {
                    bio: schoolRole?.bioPlaceholder
                };
            }
        }
        else {
            const { context } = require('../app-context');

            const redux = context.redux.hooks;

            // First try to grab it from redux
            const roleId = Number(redux.getState().signup.context.roleId);

            const schoolRoles = selectors.getSchoolRoles_byId(state, schoolId);
            const schoolRole = schoolRoles.find((item) => {

                return item.id === roleId;
            });

            return {
                bio: schoolRole?.bioPlaceholder
            };
        }
    },
    getSchoolRoleWelcomeImgUrl: (state) => {

        const user = calc(state).currentUser.user();

        if (!user) {
            return '';
        }

        const role = user.roleId && selectors.getCurrentUserRole(state);

        if (!role) {
            safeWarning('User role not found but user exists!');
            return '';
        }

        const schoolRoles = selectors.getSchoolRoles(state);
        const schoolRole = schoolRoles.find((item) => {

            return item.id === role.id;
        });

        return schoolRole?.homeImageUrl;
    },
    getShouldUseProfanityFilter: (state, schoolId) => {

        const { currentUser, school } = calc(state);
        const user = currentUser.user();
        if (schoolId === undefined && user) {

            const currentUserSchool = school(user.schoolId);
            if (currentUserSchool) {

                return currentUserSchool.useProfanityFilter;
            }
        }
        else {
            const { context } = require('../app-context');

            const redux = context.redux.hooks;

            // First try to grab it from redux
            const _schoolId = Number(redux.getState().signup.context.schoolId);

            const currentUserSchool = school(_schoolId);
            if (currentUserSchool) {

                return currentUserSchool.useProfanityFilter;
            }
        }
    },
    getInterestsActiveFilter: (state) => {

        const { userSearchMeta, interest } = calc(state);
        const lastSearch = userSearchMeta();

        return (lastSearch && lastSearch.criteria && lastSearch.criteria.interests) ? lastSearch.criteria.interests.map(interest) : [];
    },
    getInterestsActiveFilter_notifySearch: (state) => {

        const { notifyUserSearchMeta, interest } = calc(state);
        const lastNotifySearch = notifyUserSearchMeta();

        return (lastNotifySearch && lastNotifySearch.criteria && lastNotifySearch.criteria.interests) ? lastNotifySearch.criteria.interests.map(interest) : [];
    },
    getInterestsActiveFilter_surveySearch: (state) => {

        const { surveyUserSearchMeta, interest } = calc(state);
        const lastNotifySearch = surveyUserSearchMeta();

        return (lastNotifySearch && lastNotifySearch.criteria && lastNotifySearch.criteria.interests) ? lastNotifySearch.criteria.interests.map(interest) : [];
    },
    getActiveFilterRoleGroup_byName: (state, name) => {

        const availableRoleGroups = selectors.getRoleGroups(state);

        return availableRoleGroups.find((roleGroup) => roleGroup.name === name) || null;
    },
    getCurrentSchoolId: (state) => {

        const { currentUser } = calc(state);

        const u = currentUser.user();

        let schoolId;

        if (u) {
            schoolId = u.schoolId;
        }
        else if (window.location.pathname.startsWith('/signup')) {
            // This isn't ideal, but we're forced to require the
            // context inside here because these selectors only receive
            // the slice of state that they're responsible for.
            const { context } = require('../app-context');

            const redux = context.redux.hooks;

            // First try to grab it from redux
            schoolId = Number(redux.getState().signup.context.schoolId);
            // Then try grabbing it from the url
            if (isNaN(schoolId)) {
                schoolId = Number(GetQueryParams().s);
            }
        }

        return schoolId;
    },
    getIsSSOSchool: (state) => {

        const { currentUser, school, format } = calc(state);

        const u = currentUser.user();

        return !!transform({
            source: school(u && u.schoolId),
            map: format.school.sso
        });
    },
    getAppContextOptions: (state) => {

        const { schoolExtension } = calc(state);

        const extension = schoolExtension(selectors.getCurrentSchoolId(state));

        return !extension ? [] : extension.appContextOptions;
    },
    getSchoolRoles: (state) => {

        const { schoolExtension } = calc(state);

        const extension = schoolExtension(selectors.getCurrentSchoolId(state));

        return !extension ? [] : extension.roles;
    },
    getFilteredSchoolRoles: (state) => {

        const { schoolExtension } = calc(state);
        const extension = schoolExtension(selectors.getCurrentSchoolId(state));

        const filteredRoles = !extension ? [] : extension.roles.filter((role) => {

            return !USER_ROLE_IDS.SUPERUSER.includes(role.id);
        });

        return filteredRoles;
    },
    getUsePasswordRules: (state) => {

        const { schoolExtension } = calc(state);

        const extension = schoolExtension(selectors.getCurrentSchoolId(state));

        return !extension ? false : extension.usePasswordRules;
    },
    getSchoolRoles_byId: (state, schoolId) => {

        const { schoolExtension } = calc(state);

        const extension = schoolExtension(schoolId);

        return !extension ? [] : extension.roles;
    },
    getSchoolSurveys: (state) => {

        const { surveys } = calc(state);

        return [].concat(transform({
            source: surveys(),
            map: calc(state).format.survey.basic
        }));
    },
    getRoles: (state) => {

        const { roles } = calc(state);


        const allRoles = roles();

        return []
            .concat(allRoles);
    },
    getCurrentSchool: (state) => {

        const { currentUser, school } = calc(state);

        const u = currentUser.user();

        return school(u && u.schoolId);
    },
    getCurrentSchoolName: (state) => {

        const { currentUser, school, format } = calc(state);

        const u = currentUser.user();

        return transform({
            source: school(u && u.schoolId),
            map: format.school.name
        });
    },
    getCurrentSchoolSlug: (state) => {

        const { currentUser, school, format } = calc(state);

        const u = currentUser.user();

        return transform({
            source: school(u && u.schoolId),
            map: format.school.slug
        });
    },
    getCurrentSchoolHasResources: (state) => {

        const { currentUser, school, format } = calc(state);

        const u = currentUser.user();

        return transform({
            source: school(u && u.schoolId),
            map: format.school.hasResourcesPage
        });
    },
    getCurrentSchoolResourcesUrl: (state) => {

        const { currentUser, school, format } = calc(state);

        const u = currentUser.user();

        return transform({
            source: school(u && u.schoolId),
            map: format.school.resourcesUrl
        });
    },
    getCurrentSchoolWelcomeImgSrc: (state) => {

        const { currentUser, school, format } = calc(state);

        const u = currentUser.user();

        const svg = transform({
            source: school(u && u.schoolId),
            map: format.school.svg
        });

        if (svg?.startsWith('<svg')) {
            const buff = Buffer.from(svg);
            const base64data = buff.toString('base64');
            return `data:image/svg+xml;base64,${base64data}`;
        }

        return getOriginalImageUrl(svg);

    },
    hasCurrentSchool: (state) => {

        const { schoolExtension } = calc(state);

        return !!(schoolExtension(selectors.getCurrentSchoolId(state)));
    },
    getSchoolExistsBySlug: (state, slug) => {

        const { schools, filter } = calc(state);

        return schools() && schools().some(filter.school.hasSlug(slug));
    },
    getSchoolIdbySlug: (state, slug) => {

        const { schools, filter, format } = calc(state);

        return First(transform({
            source: schools(),
            filter: filter.school.hasSlug(slug),
            map: (school) => format.school.id(school)
        }));
    },
    getSchoolLogobySlug: (state, slug) => {

        const { schools, filter } = calc(state);

        return First(transform({
            source: schools(),
            filter: filter.school.hasSlug(slug),
            map: ({ svg }) => {

                if (svg?.startsWith('<svg')) {
                    const buff = Buffer.from(svg);
                    const base64data = buff.toString('base64');
                    return `data:image/svg+xml;base64,${base64data}`;
                }

                return getOriginalImageUrl(svg);
            }
        }));
    },
    getSchoolIsCompanyBySlug: (state, slug) => {

        const { schools, filter, format } = calc(state);

        return First(transform({
            source: schools(),
            filter: filter.school.hasSlug(slug),
            map: format.school.isCompany
        }));
    },
    getSchoolIsCommunityBySlug: (state, slug) => {

        const { schools, filter, format } = calc(state);

        return First(transform({
            source: schools(),
            filter: filter.school.hasSlug(slug),
            map: format.school.isCommunity
        }));
    },
    getSchoolIsOnlineBySlug: (state, slug) => {

        const { schools, filter, format } = calc(state);

        return First(transform({
            source: schools(),
            filter: filter.school.hasSlug(slug),
            map: format.school.isOnline
        }));
    },
    getCurrentUser: (state) => calc(state).currentUser,
    getCurrentUserInterests: (state) => {

        const { currentUser, interest, filter } = calc(state);
        const ux = currentUser.userExtension();

        const userInterests = ux && ux.interests && ux.interests.map(interest) || [];

        return userInterests && userInterests.filter(filter.filterOutEmptyOrUndefined).map((interestItem) => interestItem) || [];
    },
    getCurrentUserSurveys: (state) => {

        const { currentUser, survey } = calc(state);
        const ux = currentUser.userExtension();

        const userSurveys = ux && ux.surveys && ux.surveys.map(survey) || [];

        return userSurveys && userSurveys.map((surveyItem) => surveyItem).sort((su1, su2) => {
            if (su1.solved !== su2.solved) {
                return su1.solved ? 1 : -1;
            }

            return new Date(su1.endDate) - new Date(su2.endDate);
        }) || [];
    },
    getCurrentUserSurveys_byId: (state, surveyId) => {

        const { currentUser, survey } = calc(state);
        const ux = currentUser.userExtension();

        const userSurveys = ux && ux.surveys && ux.surveys.map(survey) || [];

        return userSurveys && userSurveys.map((surveyItem) => surveyItem).find((surveyItem) => {

            return surveyItem.id === parseInt(surveyId);
        }) || null;
    },
    getCurrentUserInterestsIds: (state) => {

        const { filter } = calc(state);

        const userInterests = selectors.getCurrentUserInterests(state);

        return userInterests && userInterests.filter(filter.filterOutEmptyOrUndefined).map((interestItem) => interestItem.id) || [];
    },
    getCurrentUserPassionInterests: (state) => {

        const { currentUser, interest } = calc(state);
        const ux = currentUser.userExtension();

        const userPassionInterests = ux && ux.passionInterests && ux.passionInterests.map(interest) || [];

        return userPassionInterests && userPassionInterests.map((interestItem) => interestItem) || [];
    },
    getCurrentUserPassionInterestsIds: (state) => {

        const userPassionInterests = selectors.getCurrentUserPassionInterests(state);

        return userPassionInterests && userPassionInterests.map((interestItem) => interestItem.id) || [];
    },
    currentUserHasPassionInterests: (state) => {

        const userPassionInterests = selectors.getCurrentUserPassionInterests(state);

        return userPassionInterests && userPassionInterests.length > 0 || false;
    },
    getCurrentUserSchoolHomeText: (state) => {

        const { school, currentUser } = calc(state);
        const currUser = currentUser.user();
        if (currUser) {

            const currentUserSchool = school(currUser.schoolId);
            if (currentUserSchool) {

                return currentUserSchool.homeText ? currentUserSchool.homeText : '';
            }
        }
    },
    getSchoolIsCompany: (state) => {

        const { schoolExtension } = calc(state);

        const extension = schoolExtension(selectors.getCurrentSchoolId(state));

        return !extension ? false : extension.isCompany;
    },
    getSchoolIsCommunity: (state) => {

        const { schoolExtension } = calc(state);

        const extension = schoolExtension(selectors.getCurrentSchoolId(state));

        return !extension ? false : extension.isCommunity;
    },
    getSchoolIsOnline: (state) => {

        const { school } = calc(state);

        const extension = school(selectors.getCurrentSchoolId(state));

        return !extension ? false : extension.isOnline;
    },
    getCurrentUserObject: (state) => {

        const { currentUser } = calc(state);

        const u = currentUser.user();

        return u && u;
    },
    getCurrentUserRole: (state) => {

        const { currentUser, role } = calc(state);

        const u = currentUser.user();

        return u && u.roleId && role(u.roleId);
    },
    getCurrentUserRolePermissions: (state) => {

        const user = calc(state).currentUser.user();

        const role = user && user.roleId && selectors.getCurrentUserRole(state);

        return (role) ? role.permissions : {};
    },
    getCurrentUserRoleInteractions: (state) => {

        const user = calc(state).currentUser.user();

        const role = user && user.roleId && selectors.getCurrentUserRole(state);

        return (role) ? role.interactions : [];
    },
    getUserRole: (state, userId) => {

        const { user, role } = calc(state);
        const u = user(userId);

        return u && u.roleId && role(u.roleId);
    },
    getUserRoleGroup: (state, userId) => {

        const { roleGroup } = calc(state);

        const userRole = userId && selectors.getUserRole(state, userId);
        const roleGroupId = userRole && userRole.roleGroupId;

        return roleGroupId && roleGroup(roleGroupId);
    },
    getCurrentUserId: (state) => {

        const user = calc(state).currentUser.details();

        return user && user.id;
    },
    getUserRoleId: (state, userId) => {

        const { user } = calc(state);
        const u = user(userId);

        return u && u.roleId;
    },
    getAvailableRolesToView_forCurrentUser: (state) => {

        const roleInteractions = selectors.getCurrentUserRoleInteractions(state);

        return roleInteractions.filter((roleInteraction) => {

            return roleInteraction.canViewProfile;
        });
    },
    getPreferences__forUserPreferencesForm: (state) => {

        const { preferences, currentUser } = calc(state);
        const { phone, profileEmail, phoneCountry } = (currentUser.user() || {});
        const {
            sms,
            connectionsVisibility,
            groupVisibility,
            groupMsgNotifications,
            hideInterestPopup,
            digestInterval,
            pendingConnections,
            careerAlerts,
            messageNotifications
        } = (preferences() || {});

        return {
            phone: phone || '',
            phoneCountry: phoneCountry || 'us',
            sms: !!(sms && sms === 'on'),
            connectionsVisibility: connectionsVisibility || '',
            groupVisibility: groupVisibility || '',
            profileEmail: profileEmail || '',
            groupMsgNotifications: groupMsgNotifications || '',
            hideInterestPopup: hideInterestPopup || '',
            digestInterval: digestInterval || '',
            pendingConnections: pendingConnections || '',
            careerAlerts: careerAlerts || '',
            messageNotifications: messageNotifications || 'on'
        };
    },
    getSortGroupsByPreference: (state) => {

        const { preferences } = calc(state);
        const { sortGroupsBy } = (preferences() || {});
        return sortGroupsBy || ALL_GROUPS_SORT_TYPE.BY_DATE_UPDATED;
    },
    getSortMessagesByPreference: (state) => {

        const { preferences } = calc(state);
        const { sortMessagesBy } = (preferences() || {});
        return sortMessagesBy || MESSAGES_SORT_TYPE.BY_DATE_UPDATED;
    },
    getIsLoadingPreferences: (state) => calc(state).preferences() === null,

    getClassesCount_forCurrentUser: (state) =>

        (calc(state).currentUser.classes() || []).length,

    getIncomingClassList_bySchoolId: (state, schoolId) => {

        const { school } = calc(state);
        const userSchool = school(schoolId);

        return userSchool && userSchool.incomingClassList;
    },

    getIncomingClassList_forCurrentUser: (state) => {

        const { currentUser } = calc(state);
        const ux = currentUser.userExtension();

        const userSchoolId = ux && ux.school;

        return selectors.getIncomingClassList_bySchoolId(state, userSchoolId);
    },

    getGraduatingClassList_forCurrentUser: (state) => {

        const now = new Date();
        const thisYear = now.getFullYear();

        const { school } = calc(state);

        const userSchool = school(selectors.getCurrentSchoolId(state));

        let repeatCount = 6;

        if (typeof userSchool !== 'undefined' && userSchool.graduatingYearsCount !== null) {
            repeatCount = userSchool.graduatingYearsCount;
        }

        // Checkout this tricky line! We're using the `repeat` function on string
        // to make a string of (x) length.
        // Then we turn it into an array with split, and map over each item (which we ignore)
        // All to get a loop of (x) iterations!
        return '*'.repeat(repeatCount).split('').map((_, i) => ({ name: String(thisYear + i) }));
    },

    getGraduatingClassList_bySchoolId: (state, schoolId) => {

        const now = new Date();
        const thisYear = now.getFullYear();

        const { school } = calc(state);
        const userSchool = school(schoolId);

        let repeatCount = 6;

        if (typeof userSchool !== 'undefined' && userSchool.graduatingYearsCount !== null) {
            repeatCount = userSchool.graduatingYearsCount;
        }

        // Checkout this tricky line! We're using the `repeat` function on string
        // to make a string of (x) length.
        // Then we turn it into an array with split, and map over each item (which we ignore)
        // All to get a loop of (x) iterations!
        return '*'.repeat(repeatCount).split('').map((_, i) => ({ name: String(thisYear + i) }));
    },

    getConditionalDisplayFields_bySchoolId: (state, schoolId) => {

        const { school } = calc(state);

        const defaultDisplay = {
            incomingClass: false,
            graduatingClass: false,
            onlineStudent: true,
            housing: true,
            openToSocial: true,
            transfer: true,
            major: true,
            department: true,
            career: true,
            profession: true,
            type: true,
            parent: true,
            title: true
        };

        const schoolDisplay = (() => {

            const s = school(schoolId);

            if (!s || !s.slug) {
                return {};
            }

            if (typeof s.displayDefaults !== 'undefined' && s.displayDefaults !== null) {
                return s.displayDefaults;
            }

            switch (s.slug) {
                case 'general':
                case 'umaine':
                    return {
                        incomingClass: true,
                        graduatingClass: true
                    };
                case 'match':
                    return {
                        onlineStudent: false,
                        housing: false,
                        transfer: false
                    };
                case 'emcc':
                    return {
                        incomingClass: true
                    };
                case 'suu':
                    return {
                        incomingClass: true
                    };
                case 'lsu':
                    return {
                        incomingClass: true
                    };
                case 'app-state':
                    return {
                        incomingClass: true,
                        graduatingClass: true
                    };
                default:
                    return {};
            }
        })();


        //there is no need for userMask because of new permission system that's stored in the database

        return {
            ...defaultDisplay,
            ...schoolDisplay
        };
    },

    getConditionalDisplayFields_forCurrentUser: (state) => {

        const { currentUser } = calc(state);

        const ux = currentUser.userExtension();

        const userSchoolId = ux && ux.school;

        return selectors.getConditionalDisplayFields_bySchoolId(state, userSchoolId);
    },

    getConditionalDisplayFields_forUser: (state, userId) => {

        const { userExtension } = calc(state);

        const ux = userExtension(userId);

        const userSchoolId = ux && ux.school;


        return selectors.getConditionalDisplayFields_bySchoolId(state, userSchoolId);
    },

    getPeers_forNetworkPage: (state) =>

        transform({
            source: calc(state).currentUser.peers(),
            map: calc(state).format.user.basic
        }),

    getPeers_NoChannel_forDirectMessagesPage: (state) => {

        const loadedChannels = selectors.getDms_forDirectMessagesPage(state);
        const userPeers = calc(state).currentUser.peers();

        const usersWithChannel = loadedChannels && loadedChannels.map((channel) => channel.attributes && channel.attributes.user && channel.attributes.user);

        const usersNoChannel = DifferenceWith(userPeers, usersWithChannel,
            ({ id }, { id: channelUserId }) => id === channelUserId
        );

        return usersNoChannel ? transform({
            source: usersNoChannel,
            map: calc(state).format.user.basic
        }) : [];
    },

    userExists: (state, userId) => !!calc(state).user(userId),

    getUser_forDetails: (state, userId) =>

        transform({
            source: calc(state).user(userId),
            map: calc(state).format.user.fullDetails
        }),

    getDms_forDirectMessagesPage: (state) =>

        transform({
            source: calc(state).channels(),
            filter: (c) => calc(state).filter.channel.withLoadedUsers(c) && calc(state).filter.channel.isDM(c),
            map: calc(state).format.channel.basic
        }),

    getDmsIsLoading_forDirectMessagesPage: (state) => calc(state).channels() === null,
    getLastMessageIsLoading_forDirectMessagesPage: (state) => state.index.channelLastMessages === null,

    getSignupDetails_forProfileEditPage: (state) =>

        transform({
            source: calc(state).currentUser.user(),
            map: calc(state).format.user.signupDetails
        }),

    getUserProfileDiff_forUpdateProfile: (state, signupDetails) =>

        transform({
            source: signupDetails,
            map: calc(state).format.user.userProfileDiff
        }),
    getUserProfilePictureDiff_forUpdateProfile: (state, profilePictureDetails) =>

        transform({
            source: profilePictureDetails,
            map: calc(state).format.user.userProfilePictureDiff
        }),

    getName_forChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        if (!channel || !channel.userIds) {
            return '';
        }

        return First(transform({
            source: channel.userIds.map(calcState.user),
            filter: calcState.filter.user.isNotCurrentUser,
            map: calcState.format.user.fullName
        }));
    },

    getUserDetails_forChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        return First(transform({
            source: channel && channel.userIds.map(calcState.user),
            filter: calcState.filter.user.isNotCurrentUser,
            map: calcState.format.user.fullDetails
        }));
    },

    getInfoMessage_forEmptyUserChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        const name = First(transform({
            source: channel && channel.userIds.map(calcState.user),
            filter: calcState.filter.user.isNotCurrentUser,
            map: calcState.format.user.firstName
        }));

        return `Start your conversation with ${name} below`;
    },

    getInfoMessage_forEmptyGroupChat: (state, channelSid) => {

        return `Start a conversation with your group below`;
    },

    getClassName_ById: (state, classId) => {

        const calcState = calc(state);
        const selectedClass = calcState.class(classId);


        return transform({
            source: selectedClass,
            map: calcState.format.class.name
        });
    },

    getClassName_forChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        return transform({
            source: channel && channel.classId && calcState.class(channel.classId),
            map: calcState.format.class.name
        });
    },
    getClassId_forChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        return transform({
            source: channel && channel.classId && calcState.class(channel.classId),
            map: calcState.format.class.id
        });
    },
    getLocalMessages_forChat_byId: (state, classId) => {

        return transform({
            source: calc(state).localMessages(),
            filter: calc(state).filter.message.isInLocalConversation(classId),
            sort: calc(state).sort.message.byTimestamp,
            map: calc(state).format.message.basic
        });
    },

    getMessages_forChat: (state, channelSid) =>

        transform({
            source: calc(state).messages(),
            filter: calc(state).filter.message.isInChannel(channelSid),
            sort: calc(state).sort.message.byTimestamp,
            map: calc(state).format.message.basic
        }),

    getMessageDetails_forFlagging: (state, sid) => {

        const { message, format } = calc(state);

        const m = message(sid);

        if (!m) {
            return null;
        }

        const { user } = format.message.basic(m) || {};
        const chatName = selectors.getClassName_forChat(state, m.channel) || 'Private chat';

        return [
            `Channel: ${chatName} / ${m.channel}`,
            `User: ${user.firstName} ${user.lastName} / ${m.author}`,
            `Message SID: ${sid}`,
            `Message Index: ${m.index}`,
            `Message Body: ${m.body}`
        ].join('\n');
    },
    getLocalMessageDetails_forFlagging: (state, id, classId) => {

        const { localMessage, format } = calc(state);

        const m = localMessage(id);

        if (!m) {
            return null;
        }

        const { user } = format.message.basic(m) || {};
        const chatName = selectors.getClassName_ById(state, classId) || 'Private chat';

        return [
            `Conversation: ${chatName} / ${m.conversation}`,
            `User: ${user.firstName} ${user.lastName} / ${m.author}`,
            `Message ID: ${id}`,
            `Message Index: ${m.index}`,
            `Message Body: ${m.body}`
        ].join('\n');
    },

    numUnreadMessages: (state, channelSid) => !!calc(state).numUnreads(calc(state).channel(channelSid)),

    getNumOfMessages: (state, channelSid) => calc(state).numOfMessages(calc(state).channel(channelSid)),

    numUnreadLocalMessages: (state, classId) => {

        const calcState = calc(state);
        const localConversations = calcState.localConversations();

        if (localConversations) {

            const localConversation = localConversations.find((localConveration) => parseInt(localConveration.classId) === parseInt(classId));

            return !!calcState.numUnreadsLocal(localConversation);
        }

        return 0;

    },

    getNumOfLocalMessages: (state, classId) => {

        const calcState = calc(state);
        const localConversations = calcState.localConversations();

        if (localConversations) {

            const localConversation = localConversations.find((localConveration) => parseInt(localConveration.classId) === parseInt(classId));

            return calcState.numOfMessagesLocal(localConversation);
        }

        return 0;


    },
    getLocalConversationByClassId: (state, classId) => {

        const calcState = calc(state);
        const localConversations = calcState.localConversations();

        if (localConversations) {

            const localConversation = localConversations.find((localConversationItem) => parseInt(localConversationItem.classId) === parseInt(classId));

            return localConversation;
        }

        return null;
    },
    // TODO fix – this seems to always return empty array?
    getClassUsers_forChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        const details = transform({
            source: channel && channel.classId && calcState.class(channel.classId),
            map: calcState.format.class.details
        });

        return details && details.users;
    },
    getClassUsers_forChatByClassId: (state, classId) => {

        const calcState = calc(state);

        const details = transform({
            source: calcState.class(classId),
            map: calcState.format.class.details
        });

        return details?.users;
    },
    getGroup_forChat: (state, channelSid) => {

        const calcState = calc(state);
        const channel = calcState.channel(channelSid);

        const details = transform({
            source: channel && channel.classId && calcState.class(channel.classId),
            map: calcState.format.class.details
        });

        return details && details.class;
    },
    getGroup_forAnnouncementChat: (state, groupId) => {

        const calcState = calc(state);

        const details = transform({
            source: calcState.class(groupId),
            map: calcState.format.class.details
        });

        return details && details.class;
    },
    getClassSid_byId: (state, classId) => {

        const calcState = calc(state);

        const basic = transform({
            source: calcState.class(classId),
            map: calcState.format.class.basic
        });

        return basic?.sid;
    },

    getIsDM: (state, sid) => {

        const { channel, filter } = calc(state);

        return !!(channel(sid) && filter.channel.isDM(channel(sid)));
    },

    getIsGroupMsg: (state, sid) => {

        const { channel, filter } = calc(state);

        return !!(channel(sid) && filter.channel.isClassOrGroup(channel(sid)));
    },

    getDmUsers_forChat: (state, channelSid) => {

        const { channel, user, format } = calc(state);
        const c = channel(channelSid);

        return transform({
            source: c && c.userIds && c.userIds.map(user),
            map: format.user.basicWithPassionInterests
        });
    },
    getSurvey_forEdit: (state, surveyId) => {

        const { survey, format } = calc(state);

        const s = survey(surveyId);

        return transform({
            source: s,
            map: format.survey.detailed
        });
    },
    getSurvey_forSolve: (state, surveyId) => {

        const { survey, format } = calc(state);


        const s = survey(surveyId);

        return transform({
            source: s,
            map: format.survey.withQuestionAnswers
        });
    },
    numUnreadDMs: (state) => {

        const calcState = calc(state);
        const unreadMessageCounts = calcState.unreadMessageCounts() || {};

        return Object.keys(unreadMessageCounts)
            .filter((sid) => calcState.channel(sid) && calcState.filter.channel.isDM(calcState.channel(sid)))
            .reduce((count, sid) => count + (unreadMessageCounts[sid] || 0), 0);
    },
    numUnreadClassMessages: (state) => {

        const calcState = calc(state);
        const unreadMessageCounts = calcState.unreadMessageCounts() || {};
        const unreadLocalMessageCounts = calcState.unreadLocalMessageCounts() || {};

        // NOTE it's possible Twilio and our db could be outta sync!
        // To avoid showing a badge for unread messages from a group that
        // this user doesn't belong to in the db, we'll filter by
        // the classes fetched for the logged-in user.
        // I believe this is probably only a problem we'll see for dev users since our
        // local db and staging's db won't be in sync but we typically use staging's
        // Twilio environment
        const twilioCount = Object.keys(unreadMessageCounts)
            .filter((sid) => calcState.channel(sid) && calcState.filter.channel.isLoggedInUserClass(sid))
            .reduce((count, sid) => count + (unreadMessageCounts[sid] || 0), 0);

        const localCount = Object.keys(unreadLocalMessageCounts)
            .filter((id) => calcState.localConversation(id) && calcState.filter.conversation.isLoggedInUserClass(id))
            .reduce((count, id) => count + (unreadLocalMessageCounts[id] || 0), 0);

        return parseInt(twilioCount) + parseInt(localCount);
    },
    getNotifications_forDialog: (state) => {

        const { schema, sort, format, filter, classes, class: classById, transfer: transferById } = calc(state);
        const notifications = denormalize(state.index.notifications, [schema.Notification], state.entities);

        return transform({
            source: notifications,
            map: ({ class: classId, transfer, transferId, channelSid, channelType, type, info, emojiSymbol, ...others }) => {

                const firstEmoji = (emojiSymbol && emojiSymbol.length) ? SelectFirstEmojiFromString(emojiSymbol) : '';
                const classIds = (channelType === 'class') ? classes() : null;

                return {
                    ...others,
                    type,
                    info: info || { text: '' },
                    channelSid,
                    channelType,
                    emojiSymbol: firstEmoji,
                    transferDetails: (type !== 'transfer') ? null : transform({
                        source: transferById(transferId),
                        map: format.transfer.details
                    }),
                    groupDetails: (type !== 'group-invite') ? null : {
                        class: transform({
                            source: classById(classId),
                            map: format.class.basic
                        })
                    },
                    class: First(transform({    // Chat invite
                        source: classIds && classIds.map(classById),
                        filter: filter.class.hasSid(channelSid),
                        map: format.class.basic
                    }))
                };
            },
            filter: [
                filter.notification.notStaleMention,
                filter.notification.notDeclined,
                filter.notification.notStaleTransfer
            ],
            sort: sort.notification.byCreatedAt
        }) || [];
    },

    hasMentions: (state, channelSid) => {

        const { notifications, filter } = calc(state);

        return !!First(transform({
            source: notifications(),
            filter: filter.notification.mentionInChannel(channelSid)
        }));
    },

    getUnreadNotifications: (state) => {

        return transform({
            source: calc(state).notifications(),
            filter: [
                calc(state).filter.notification.isUnread,
                calc(state).filter.notification.notStaleMention,
                calc(state).filter.notification.notDeclined,
                calc(state).filter.notification.notStaleTransfer
            ]
        });
    },

    getUnreadNotificationsCount: (state) => {

        const unreadNotifications = selectors.getUnreadNotifications(state);

        return unreadNotifications && unreadNotifications.length || 0;
    },

    getIsInitLoading_forUserSearch: (state) => calc(state).userSearchMeta().criteria === null,
    getIsLoading_forUserSearch: (state) => calc(state).userSearchMeta().loading,
    getIsLoadingNextPage_forUserSearch: (state) => calc(state).userSearchMeta().loadingNextPage,

    getIsLoadingInterests: (state) => calc(state).interests() === null,
    getIsLoadingOffices: (state) => calc(state).offices() === null,
    getIsLoadingYearsHired: (state) => calc(state).yearsHired() === null,

    getIsLoadingSurveys: (state) => calc(state).surveys() === null,

    getUsers_forUserSearch: (state) => {

        const { userSearchResults, format, filter } = calc(state);

        const userResults = userSearchResults();
        return transform({
            source: userResults && userResults.length ? userResults : [],
            filter: filter.filterOutEmptyOrUndefined,
            map: format.user.searchResult
        });
    },

    getUsers_forNotifyUsersSearch: (state) => {

        const { notifyUsersSearchResults, format, filter } = calc(state);

        const userResults = notifyUsersSearchResults();

        return transform({
            source: userResults && userResults.length ? userResults : [],
            filter: filter.filterOutEmptyOrUndefined,
            map: format.user.basic
        });
    },
    getUsers_forSurveyUsersSearch: (state) => {

        const { surveyUsersSearchResults, format, filter } = calc(state);

        const userResults = surveyUsersSearchResults();

        return transform({
            source: userResults && userResults.length ? userResults : [],
            filter: filter.filterOutEmptyOrUndefined,
            map: format.user.basic
        });
    },

    getLastUserSearch: (state) => {

        return calc(state).userSearchMeta();
    },

    getClasses: (state) =>

        transform({
            source: calc(state).allClasses(),
            map: calc(state).format.class.basic
        }),

    getClasses_forMyClasses: (state) =>

        transform({
            source: calc(state).currentUser.classes(),
            map: calc(state).format.class.basic
        }),

    getClasses_forClassSearch: (state) =>

        transform({
            source: calc(state).classSearchResults(),
            map: calc(state).format.class.basic
        }),

    getGroup_forGroupDetail: (state, classId) =>

        transform({
            source: calc(state).class(classId),
            map: calc(state).format.class.details
        }),

    getGroup_forGroupDetail_withUserSearchResults: (state, classId) =>

        transform({
            source: calc(state).class(classId),
            map: calc(state).format.class.detailsWithUserSearchResults
        }),

    getCriteria_forClassSearch: (state, classId) => {

        if (state.index.classUserSearchResults && state.index.classUserSearchResults[classId]) {
            return state.index.classUserSearchResults[classId].criteria;
        }

        return null;
    },
    getNextPageCriteria_forClassSearch: (state, classId) => {

        if (state.index.classUserSearchResults && state.index.classUserSearchResults[classId]) {

            const loadedUsers = state.index.classUserSearchResults[classId].searchResults || [];
            const loadedUsersIds = loadedUsers.map((result) => result.user);

            return {
                ...state.index.classUserSearchResults[classId].criteria,
                classId,
                loadedUsersIds,
                page: state.index.classUserSearchResults[classId].page + 1
            };
        }

        return null;
    },

    getShowLoadMore_forClassSearch: (state, classId) => {

        if (state.index.classUserSearchResults && state.index.classUserSearchResults[classId]) {
            return state.index.classUserSearchResults[classId].moreResults;
        }

        return null;
    },

    getClassIdBySid: (state, sid) => {

        const calcState = calc(state);
        const classIds = calcState.classes();

        return First(transform({
            source: classIds && classIds.map((id) => calcState.class(id)),
            filter: calcState.filter.class.hasSid(sid),
            map: calcState.format.class.id
        })) || null;
    },
    getClassById: (state, sid) => {

        const calcState = calc(state);
        const classIds = calcState.classes();

        return First(transform({
            source: classIds && classIds.map((id) => calcState.class(id)),
            filter: calcState.filter.class.hasSid(sid),
            map: calcState.format.class.id
        })) || null;
    },

    currentUserIsInClass: (state, classId) => {

        const currentUser = calc(state).currentUser.userExtension();

        return currentUser ? (currentUser.classes.indexOf(Number(classId)) !== -1) : false;
    },

    getCanJoinClass: (state, classId) => {

        const currentUser = calc(state).currentUser.userExtension();

        return currentUser ? (currentUser.classes.indexOf(Number(classId)) === -1) : false;
    },

    classCouldRefresh: (state, classId) => !!calc(state).classExtension(classId),

    getBasicTransfers_bySchoolId: (state, schoolId) => {

        const { schoolExtension, transfer, format } = calc(state);

        const sx = schoolExtension(schoolId);

        return transform({
            source: sx && sx.transfers && sx.transfers.map(transfer),
            map: format.transfer.basic
        }) || [];
    },

    getBasicTransfers_forProfileEditPage: (state) => {

        const u = calc(state).currentUser.user();

        return selectors.getBasicTransfers_bySchoolId(state, u && u.schoolId);
    },

    getHousingList_bySchoolId: (state, schoolId) => {

        const { schoolExtension, housing, format } = calc(state);

        const sx = schoolExtension(schoolId);

        return transform({
            source: sx && sx.housing && sx.housing.map(housing),
            map: format.housing.details
        }) || [];
    },

    getHousingList_forCurrentUser: (state) => {

        const u = calc(state).currentUser.user();

        return selectors.getHousingList_bySchoolId(state, u && u.schoolId);
    },

    getOnCampusHousingList: (state, schoolId) =>

        transform({
            source: typeof schoolId !== 'undefined' ? selectors.getHousingList_bySchoolId(state, schoolId) : selectors.getHousingList_forCurrentUser(state),
            filter: calc(state).filter.housing.onCampus,
            sort: calc(state).sort.housing.alphabetically
        }),

    getOffCampusHousingList: (state, schoolId) =>

        transform({
            source: typeof schoolId !== 'undefined' ? selectors.getHousingList_bySchoolId(state, schoolId) : selectors.getHousingList_forCurrentUser(state),
            filter: calc(state).filter.housing.offCampus,
            sort: calc(state).sort.housing.alphabetically
        }) || [],

    getDefaultInterestId_bySchoolId: (state, schoolId) => {

        const school = selectors.getSchoolById(state, schoolId);
        if (!school || !school.defaultInterestId) {
            return null;
        }

        const interests = state.entities.interests;
        return interests ? interests[school.defaultInterestId] : null;
    },

    getSuppressedInterestsId: (state, schoolId = null) => {
        const school = schoolId ? selectors.getSchoolById(state, schoolId) : selectors.getCurrentSchool(state);
        if (!school || !school.suppressedInterestsId) {
            return null;
        }

        return school.suppressedInterestsId;
    },

    getJobAlertPreferences_bySchoolId: (state, schoolId) => {

        const school = selectors.getSchoolById(state, schoolId);
        if (!school) {
            return null;
        }

        return school.hideJobAlerts;
    },

    getDepartmentsList_bySchoolId: (state, schoolId) => {

        const { schoolExtension, department, format } = calc(state);

        const sx = schoolExtension(schoolId);

        return transform({
            source: sx && sx.departments && sx.departments.map(department),
            map: format.department.basic
        }) || [];
    },

    getDepartmentsList_forProfileEditPage: (state) => {

        const u = calc(state).currentUser.user();

        return selectors.getDepartmentsList_bySchoolId(state, u && u.schoolId);
    },

    getMajorsList_bySchoolId: (state, schoolId) => {

        const { schoolExtension, major, format } = calc(state);

        const sx = schoolExtension(schoolId);

        return transform({
            source: sx && sx.majors && sx.majors.map(major),
            map: format.major.basic
        }) || [];
    },

    getMajorsList_forCurrentUser: (state) => {

        const u = calc(state).currentUser.user();

        return selectors.getMajorsList_bySchoolId(state, u && u.schoolId);
    },

    getMajorNames_forSearchDialog: (state) => {

        const { currentUser, schoolExtension, major, format } = calc(state);

        const u = currentUser.user();
        const sx = schoolExtension(u && u.schoolId);

        return transform({
            source: sx && sx.majors && sx.majors.map(major),
            map: format.major.name
        }) || [];
    },

    getNextPageCriteria_forUserSearchResults: (state) => {

        const { page, criteria } = calc(state).userSearchMeta();
        const loadedUsers = selectors.getUsers_forUserSearch(state);
        if (page === null || criteria === null) {
            return null;
        }

        if (criteria && criteria.sortType && criteria.sortType === USERS_SORT_TYPE.RANDOM) {
            const loadedUsersIds = loadedUsers.map((user) => user.id);
            criteria.loadedUsersIds = loadedUsersIds;
        }

        return {
            ...criteria,
            page: page + 1
        };
    },

    getShowLoadMore_forUserSearch: (state) => calc(state).userSearchMeta().moreResults,

    getCanSearchHousing_forUserSearch: (state) => {

        const ux = calc(state).currentUser.userExtension();

        return !!(ux && ux.housing && (ux.housing.name || ux.housing.id));
    },

    getCanSearchHometown_forUserSearch: (state) => {

        const u = calc(state).currentUser.user();

        return !!(u && u.hometown && u.hometown.name);
    },
    getScheduledNotifications: (state) => {
        const sourceData = calc(state).scheduledNotifications();
        const transformedData = transform({
            source: sourceData,
            sort: (a, b) => new Date(b.scheduledTime) - new Date(a.scheduledTime),
            map: (notification) => ({
                id: notification.id,
                text: notification.text,
                roleId: notification.roleId,
                emojiSymbol: notification.emojiSymbol ? SelectFirstEmojiFromString(notification.emojiSymbol) : '',
                scheduledTime: notification.scheduledTime,
                usedCriteria: notification.usedCriteria
            })
        }) || [];
        return transformedData;
    }
};

module.exports = Object.assign(reducer, { selectors });

internals.maskWith = (mask, values) => {

    const maskApplied = {};

    Object.keys(mask).forEach((field) => {

        if (Object.prototype.hasOwnProperty.call(values, field)) {
            maskApplied[field] = mask[field] && values[field];
        }
        else if (!mask[field]) {
            maskApplied[field] = mask[field];
        }
    });

    return { ...values, ...maskApplied };
};
