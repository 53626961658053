const React = require('react');
const T = require('prop-types');
const Debounce = require('lodash/debounce');
const { default: Button } = require('@mui/material/Button');
const { default: Divider } = require('@mui/material/Divider');
const { default: FAB } = require('@mui/material/Fab');
const { default: AddIcon } = require('@mui/icons-material/Add');
const { default: RemoveIcon } = require('@mui/icons-material/Remove');
const { default: TextField } = require('@mui/material/TextField');
const { default: List } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: InputAdornment } = require('@mui/material/InputAdornment');
const FullScreenDialog = require('containers/FullScreenDialog');
const ClassListItem = require('../../../classes/components/ClassListItem');
const { gotoGroupLink } = require('utils/group');
const { default: Classes } = require('./styles.scss');
const MuiColors = require('@mui/material/colors');
const { default: Styled } = require('styled-components');

const internals = {};

module.exports = class ManageGroupsDialog extends React.Component {

    static propTypes = {
        onRequestSearch: T.func,
        fetchClasses: T.func,
        onClickAddToGroup: T.func,
        onClickRemoveFromGroup: T.func,
        myCurrentGroups: T.arrayOf(T.shape({ id: T.any.isRequired, name: T.string })) || [],
        currentGroups: T.arrayOf(T.shape({ id: T.any.isRequired, name: T.string })) || [],
        user: T.shape({ id: T.any.isRequired, firstName: T.string }),
        classesSearch: T.arrayOf(T.shape({ id: T.any.isRequired })),
        allClasses: T.arrayOf(T.shape({ id: T.any.isRequired, name:T.string })),
        push: T.func
    }

    constructor(props) {

        super(props);

        this.state = {
            term: '',
            wide: null
        };

        // Add a mobile breakpoint ("media query listener") for use with inline styles
        this.mql = window.matchMedia('screen and (min-width: 600px)');

        this.handleMediaQuery = this._handleMediaQuery.bind(this);
        this.updateSearch = this._updateSearch.bind(this);
        this.onClickAddToGroup = this._onClickAddToGroup.bind(this);
        this.onClickRemoveFromGroup = this._onClickRemoveFromGroup.bind(this);
        this.requestSearch = Debounce(props.onRequestSearch, 200);
    }

    componentDidMount() {

        const userId = this.props?.user?.id;

        this.props.fetchClasses({ managedUserId:userId });
    }

    UNSAFE_componentWillMount() {

        this.handleMediaQuery(this.mql);

        this.mql.addListener(this.handleMediaQuery);
    }

    componentWillUnmount() {

        this.mql.removeListener(this.handleMediaQuery);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        // Replace debounced search request
        if (this.props.onRequestSearch !== nextProps.onRequestSearch) {
            const userId = this.props?.user?.id;

            this.requestSearch.cancel();
            this.requestSearch = Debounce(nextProps.onRequestSearch, 200);
            this.requestSearch({ term: this.state.term, showAll: true, managedUserId: userId });
        }
    }

    _handleMediaQuery(mql) {

        this.setState({ wide: !!mql.matches });
    }

    _updateSearch(event) {

        const userId = this.props?.user?.id;

        const term = event.target.value;

        this.setState({ term });
        this.requestSearch({ term, showAll: true, managedUserId: userId });
    }

    _onClickAddToGroup(class_) {

        this.props.onClickAddToGroup(class_);
    }

    _onClickRemoveFromGroup(class_) {

        this.props.onClickRemoveFromGroup(class_);
    }

    render() {

        const {
            classesSearch,
            myCurrentGroups,
            push
        } = this.props;

        const {
            styles,
            StyledTextField,
            ListWrapper
        } = internals;

        const { wide, term } = this.state;

        const middleActionButton = wide ? <Button
            color={'secondary'}
            variant={'contained'}
            style={styles.joinButton}
            className={Classes.joinButton}
        >
            Add
        </Button> : <FAB
            color={'secondary'}
            size={'small'}
            style={styles.joinFab}
        >
            <AddIcon />
        </FAB>;

        const middleRemoveButton = wide ? <Button
            color={'secondary'}
            variant={'contained'}
            style={styles.joinButton}
            className={Classes.joinButton}
        >
            Remove
        </Button> : <FAB
            color={'secondary'}
            size={'small'}
            style={styles.joinFab}
        >
            <RemoveIcon />
        </FAB>;

        // eslint-disable-next-line
        const userCurrentGroups = !!this.props.currentGroups ? this.props.currentGroups.sort((a, b) => a.name.localeCompare(b.name)) : [];
        const searchedClassesSorted = classesSearch && classesSearch.length ? classesSearch.sort((a,b) => {

            const alreadyInGroupA =  !!this.props.currentGroups.find((userGroup) => userGroup.id === a.id);
            const alreadyInGroupB =  !!this.props.currentGroups.find((userGroup) => userGroup.id === b.id);

            return (Number(alreadyInGroupB) - Number(alreadyInGroupA )) || a.name.localeCompare(b.name);
        }) : [];

        return <FullScreenDialog
            {...this.props}
            className={Classes.wrapper}
            title={<span className={Classes.title}>{`Manage ${this.props.user.firstName}'s Groups`}</span>}
            classes={{ root: Classes.fullScreenDialogRoot }}
            fixedToTop={
                <div>
                    <StyledTextField
                        id='my-classes-search'
                        fullWidth
                        variant='outlined'
                        placeholder='Type to search groups...'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        value={term}
                        onChange={this.updateSearch}
                    />
                </div>
            }
        >
            <ListWrapper role='list'>
                <List>
                    {(!classesSearch || !classesSearch.length || !term) && <ListItem
                        className={Classes.center}
                    >
                        <div className={Classes.center} style={{ width: '100%' }}>
                            {(!term && !userCurrentGroups.length) && <h3>{`${this.props.user.firstName} isn't in any groups`}</h3>}
                            {!!userCurrentGroups.length && <h3>Joined Groups</h3>}
                            <div style={{ width: '100%' }}>
                                {(!term && !!userCurrentGroups.length) ? userCurrentGroups.map((group) => {

                                    return (
                                        <ClassListItem
                                            showPrivate
                                            key={group.id}
                                            link={gotoGroupLink(group, myCurrentGroups)}
                                            group={group}
                                            titleFontSize={17}
                                            action={this.onClickRemoveFromGroup}
                                            middleActionButton={middleRemoveButton}
                                            style={{ textAlign: 'left' }}
                                            push={push}
                                        />
                                    );
                                }) : null}
                            </div>
                            {!!userCurrentGroups.length && <h3 style={{ marginTop: '45px' }}>Unjoined Groups</h3>}
                            {(!term && !!this.props.allClasses.length) && <Divider style={{ marginTop: '10px', marginBottom: '24px' }} />}
                            {(!term && !!this.props.allClasses.length) ? this.props.allClasses.reduce((collector, group) => {

                                const alreadyInGroup = this.props.currentGroups.find((userGroup) => userGroup.id === group.id);

                                if (alreadyInGroup) {
                                    return collector;
                                }

                                return collector.concat(
                                    <ClassListItem
                                        key={group.id}
                                        link={`/app/classes/${group.id}`}
                                        showPrivate
                                        group={group}
                                        titleFontSize={17}
                                        action={this.onClickAddToGroup}
                                        middleActionButton={middleActionButton}
                                        style={{ textAlign: 'left' }}
                                        push={push}
                                    />
                                );
                            }, []) : null}

                            {!!term && classesSearch && !classesSearch.length && (
                                <h3 style={{ marginTop: 40 }}>No groups found.</h3>
                            )}
                        </div>
                    </ListItem>}

                    {!!term && classesSearch && classesSearch.length ? searchedClassesSorted.reduce((collector, group) => {

                        const alreadyInGroup = this.props.currentGroups.find((userGroup) => userGroup.id === group.id);

                        return collector.concat(
                            <ClassListItem
                                displayDetails
                                showPrivate
                                key={group.id}
                                link={`/app/classes/${group.id}`}
                                group={group}
                                titleFontSize={17}
                                action={alreadyInGroup ?  this.onClickRemoveFromGroup : this.onClickAddToGroup}
                                middleActionButton={alreadyInGroup ? middleRemoveButton : middleActionButton}
                                push={push}
                            />
                        );
                    }, []) : null}
                </List>
            </ListWrapper>
        </FullScreenDialog>;
    }
};

internals.styles = {
    classLink: {
        textDecoration: 'none',
        display: 'inline-block',
        width: '100%',
        color:'#000'
    },
    search: {
        textAlign: 'center'
    },
    searchHint: {
        width: '100%',
        textAlign: 'center'
    },
    searchHintContent: {
        height: 18,
        width: 18,
        color: MuiColors.grey['400'],
        verticalAlign: 'text-bottom'
    },
    joinButton: {
        height: 36,
        width: 'auto',
        margin: 0
    },
    joinFab: {
        height: 36,
        width: 36,
        minWidth: 36,
        margin: 0
    }
};

internals.StyledTextField = Styled(TextField)`
  && {
    margin-top: 0.5rem;

    padding: 20px 50px;

    .MuiInputBase-root {
        height: 100%;
    }

    .MuiInput-input {
      text-align: center;
    }
  }
`;

internals.ListWrapper = Styled.div`
    ul,
    li,
    h3 {
        margin-top: 0;
    }

    margin-top: -20px;
`;
