const Storage = require('../utils/storage');
const { context } = require('../app-context');

const actions = context.actions;
const selectors = context.selectors.all;
const api       = context.api.nearpeer;

const ONE_SEC_MS = 1000;

const internals = {};

module.exports = (store) => {

    // Keep around for now, think is used to manage messages to send to the server
    Storage.session.subscribeStorePathWithStore(store, 'chats', 'chats', (chats) => {

        return Object.keys(chats).reduce((collector, key) => {

            const val = chats[key];

            if (val !== '') {
                collector[key] = val;
            }

            return collector;
        }, {});
    });

    const startPollingFunction = (callback, pollTime) => {

        clearInterval(internals.interval);

        // Schedule to happen almost immediately– don't want to dispatch unconditionally in time with subscribe()
        setTimeout(callback);

        internals.interval = setInterval(callback, pollTime);

        return internals.interval;
    };

    const stopPollingFunction = (p) => {

        clearInterval(p);

        return null;
    };

    const sendMessageToServer = () => {

        const state = store.getState();

        const messagesToSendOnServer = selectors.getAllMessagesToSendOnServer(state);

        if (messagesToSendOnServer?.length) {
            api.communication.storeMessages({ messages: messagesToSendOnServer });
            store.dispatch(actions.communication.removeMessagesForSendingToServer({ messages: messagesToSendOnServer }));
        }
    };

    let storeMessagesPool = null;

    store.subscribe(() => {

        const state = store.getState();
        const isAuthenticated = selectors.getIsAuthenticated(state);
        const isVerified = selectors.getVerifiedStatus(state);
        const isInBackground = selectors.getIsAppInBackground(state);

        // When the user becomes authenticated, start polling for notifications and unreads
        if (isAuthenticated && isVerified && !storeMessagesPool && !isInBackground) {
            storeMessagesPool = startPollingFunction(sendMessageToServer, 2 * ONE_SEC_MS);
        }

        // TODO stop polling when app is in background
        // When the user becomes unauthenticated, stop polling for notifications
        if (!isAuthenticated && storeMessagesPool && isInBackground) {
            storeMessagesPool = stopPollingFunction(storeMessagesPool);
            // Send pending messages when user backgrounds the app.
            sendMessageToServer();
        }
    });
};
