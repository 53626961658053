const { SIMPLE, ASYNC } = require('../utils/redux-helpers');

module.exports = {
    SET_MAIN_APP_LOADING: SIMPLE,
    UPDATE_APP_CONTEXT_FILTER: SIMPLE,
    UPDATE_DISCOVER_SORT_TYPE: SIMPLE,
    UPDATE_SURVEYS_MANAGEMENT_FILTER: SIMPLE,
    UPDATE_CONNECTIONS_FILTER: SIMPLE,
    UPDATE_APP_REVIEW_STATUS: ASYNC,
    SET_USER_REVIEW_STATUS: SIMPLE,
    FETCH_USER_REVIEW_AVAILABLE_STATUS: ASYNC,
    CHECK_USER_IP: ASYNC,
    UPDATE_CLASSMATE_DISCOVER_SEARCH_FILTER: SIMPLE,
    UPDATE_NOTIFY_SEARCH_FILTER: SIMPLE,
    UPDATE_SURVEY_SEARCH_FILTER: SIMPLE,
    SET_FIREBASE_TOKEN: SIMPLE,
    SET_IN_BACKGROUND: SIMPLE,
    SET_INTERESTS_SEARCH_TEXT: SIMPLE,
    OPEN_NOTIFICATIONS: SIMPLE,
    CLOSE_NOTIFICATIONS: SIMPLE,
    OPEN_LOGOUT_DIALOG: SIMPLE,
    CLOSE_LOGOUT_DIALOG: SIMPLE,
    OPEN_ALERT_DIALOG: SIMPLE,
    SET_ALERT_DIALOG_MESSAGE: SIMPLE,
    CLOSE_ALERT_DIALOG: SIMPLE,
    INPUT_PAGE_SET_ERROR: SIMPLE,
    INPUT_PAGE_REMOVE_ERROR: SIMPLE,
    OPEN_MODAL: SIMPLE,
    CLOSE_MODAL: SIMPLE,
    SET_HISTORY_PATHNAME: SIMPLE,
    SET_HISTORY_PREVIOUS_PATHNAME: SIMPLE,
    REMEMBER_SCROLL: SIMPLE,
    RESTORE_SCROLL: SIMPLE,
    SHOULD_REFRESH_SEARCH: SIMPLE,
    SET_APP_LOAD_SUCCESS_REDIRECT_PATH: SIMPLE,
    SET_REMOTE_DEBUG_MODE_KEY: SIMPLE
};
