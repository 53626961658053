const { useEffect, useRef } = require('react');
const Loader = require('components/Loader');
const { useAppSelector } = require('utils/redux');
const { context } = require('app-context');

const { push: Push } = require('connected-react-router');

const {
    selectors: { all: selectors },
    actions
} = context;

const redux = context.redux.hooks;

module.exports = function AppLoader() {

    const isMainAppLoading = useAppSelector(selectors.getMainAppLoading);
    const appLoadSuccessRedirectPath = useAppSelector(selectors.getAppLoadSuccessRedirectPath);

    // useRef to track the previous value of isMainAppLoading
    const prevAppLoadingVal = useRef(isMainAppLoading);

    useEffect(() => {

        if (!isMainAppLoading) {
            // If visiting this route while the app is not loading,
            // redirect to the welcome page
            const targetRedirect = appLoadSuccessRedirectPath || '/app/welcome';
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: null }));
            redux.dispatch(Push(targetRedirect));
        }
    }, []);

    useEffect(() => {
        // When isMainAppLoading changes from true to false
        // and this app is loaded, we want to redirect.
        if (prevAppLoadingVal.current && !isMainAppLoading) {
            const targetRedirect = appLoadSuccessRedirectPath || '/app/welcome';

            // Reset appLoadSuccessRedirectPath after it gets used.
            redux.dispatch(actions.app.setAppLoadSuccessRedirectPath({ path: null }));
            redux.dispatch(Push(targetRedirect));
        }

        // Update the previous value after each render
        prevAppLoadingVal.current = isMainAppLoading;
    }, [isMainAppLoading, appLoadSuccessRedirectPath]);

    return <Loader />;
};
