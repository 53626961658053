const Configuration = require('../app-context/configuration');

const S3_PREFIX = 'https://s3.amazonaws.com/near-peer/';

const bustCacheSuffixParam = `?bustCache=${Math.random()}`;

module.exports = {

    getSizedImageUrl(path, size) {

        let bustCacheSuffix = '';

        if (process.env.USE_BUST_CACHE_QUERY_PARAM) {
            bustCacheSuffix = bustCacheSuffixParam;
        }

        return `${Configuration.nearpeerApi.baseUrl()}/image/${size}/${path.replace(S3_PREFIX, '') + bustCacheSuffix}`;
    },
    getOriginalImageUrl(path) {

        if (!path) {
            return null;
        }

        let bustCacheSuffix = '';

        if (process.env.USE_BUST_CACHE_QUERY_PARAM) {
            bustCacheSuffix = bustCacheSuffixParam;
        }

        return `${Configuration.nearpeerApi.baseUrl()}/image/original/${path.replace(S3_PREFIX, '') + bustCacheSuffix}`;
    }
};
