const { connect } = require('react-redux');
const { context } = require('app-context');
const Entry = require('../components/Entry');
const { aliases, allCustomSignups } = require('utils/customSignups');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state, { match: { params: { slug: matchSlug } } }) => {

        const slug = aliases[matchSlug] || matchSlug;

        return ({
            schools: null,
            signupDetails: {
                ...selectors.getSignupDetailsForStep1(state),
                schoolId: selectors.getSchoolIdbySlug(state, slug)
            },
            isCompany: selectors.getSchoolIsCompanyBySlug(state, slug),
            isCommunity: selectors.getSchoolIsCommunityBySlug(state, slug),
            showSchoolList: false,
            text: allCustomSignups[slug]?.mainText,
            secondaryText: allCustomSignups[slug]?.secondaryText,
            useWhiteLanding: allCustomSignups[slug]?.useWhiteLanding,
            useWideLogo: allCustomSignups[slug]?.useWideLogo,
            useExtraWideLogo: allCustomSignups[slug]?.useExtraWideLogo,
            logoSidePadding: allCustomSignups[slug]?.logoSidePadding,
            footer: allCustomSignups[slug]?.footer || null,
            logo: selectors.getSchoolLogobySlug(state, slug)
        });
    }, {
        onSubmit: actions.signup.step1.complete,
        onInvalidSubmit: actions.signup.step1.failure
    }
)(Entry);
