const Homepage = require('./containers/Homepage');
const { context } = require('app-context');

const selectors = context.selectors.all;
const redux = context.redux.hooks;

// Sync route definition
module.exports = {
    path: '/',
    component: Homepage,
    exact: true,
    onWillMount: () => {

        const state = redux.getState();
        const isAuthenticated = selectors.getIsAuthenticated(state);

        if (isAuthenticated) {
            return { replace: '/app/loader' };
        }
    }
};
